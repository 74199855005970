import { useState, useEffect, useRef, useCallback, MouseEvent, SyntheticEvent } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Box, Button, Fade, List, ListItemButton, Popover, Slider } from '@mui/material'
import { styled } from '@mui/system'
import {
  Fullscreen as FullscreenIcon,
  FullscreenExit as FullscreenExitIcon,
  VolumeDown,
  VolumeMute,
  VolumeOff,
  VolumeUp,
} from '@mui/icons-material'
import useResizeObserver from 'use-resize-observer'
import BouncingLoader from 'components/BouncingLoader'
import PositionDuration from 'components/PositionDuration'
import PlayPauseButton from 'components/PlayPauseButton'
import SubtitleOverlay from 'components/SubtitleOverlay'
import ProgressBar from './DeskProgBar'
import {
  setVolumeLevel,
  changePlaybackSpeed,
  signalContentEnded,
  setCurrentlyAt,
  storeMediaDuration,
  chooseSubtitle,
} from 'store/reducers/playback'
import {
  setHeadVideoId,
  setHeadVideoIndex,
  setFullScreenVideoElem,
  showAbortModal,
  toggleVideoLoader,
} from 'store/reducers/componentDisplay'
import AlertMsg from 'services/alertMsg'
import Tick from 'assets/icons/tick.svg'
import type { RootState } from 'store/index'
import screenfull from 'screenfull'
import { IMediaTrack } from 'store/storeTypes'
import defaultPoster from 'assets/images/video_poster.jpg'
import { usePreviewContext } from 'contexts/PreviewContext'

const DesktopVideosFrame = styled('div')({
  width: '100%',
  margin: 0,
  padding: 0,
  display: 'flex',
  flexDirection: 'row',
})
const HeadVideoContainer = styled('section')({
  height: '100%',
})
const HeadLoaderCont = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  alignItems: 'center',
})
const ControlsTop = styled('div')({
  width: '100%',
  height: '38%',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'flex-start',
})
const TimeBox = styled(Box)({
  width: '100%',
  height: '50px',
  textAlign: 'left',
  padding: 0,
  margin: 0,
})
const ControlsMiddle = styled('div')({
  width: '100%',
  height: '24%',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  alignItems: 'center',
})
const PlayPauseBox = styled(Box)({
  width: '50px',
  height: '20px',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
  position: 'relative',
  right: '5px',
})
const ControlsBottom = styled('div')({
  width: '100%',
  height: '38%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-end',
  alignItems: 'center',
})
const ProgressBox = styled(Box)({
  width: '100%',
  margin: 0,
  padding: 0,
})
const BottomButtons = styled(Box)({
  width: '100%',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
})
const LeftBB = styled('div')({
  width: '50%',
  height: '100%',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'flex-start',
  alignItems: 'center',
})
const RightBB = styled('div')({
  width: '50%',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'flex-end',
  alignItems: 'center',
})
const PopOverButton = styled(Button)({
  width: '33px',
  height: '25px',
  minWidth: '38px',
  fontSize: '12px',
  textAlign: 'center',
  marginRight: '5px',
  textTransform: 'lowercase',
})
const PopOverMenu = styled(Popover)({
  backgroundColor: 'transparent',
  fontSize: '12px',
})
const PlaybackSpeedList = styled(List)({
  '&.MuiList-root': {
    backgroundColor: '#333333',
  },
})
const PlaybackSpeedListButton = styled(ListItemButton)({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  alignItems: 'center',
  backgroundColor: '#333333',
  '&:hover': {
    backgroundColor: '#4d4d4d',
  },
})
const FullScreenButton = styled(Box)({
  width: '30px',
  height: '30px',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  alignItems: 'center',
  cursor: 'pointer',
})
const VolumeBox = styled(Box)({
  width: '112px',
  height: '30px',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
})
const VolumeBar = styled(Slider)({
  width: '82px',
  margin: 0,
  padding: 0,
  color: '#ffffff',
  cursor: 'pointer',
  '& .MuiSlider-thumb::after': {
    width: '15px',
    height: '15px',
  },
})
const SideVideosContainer = styled('section')({
  width: '34.5%',
  height: '100%',
  flexDirection: 'column',
  alignItems: 'flex-start',
  justifyContent: 'space-between',
  overflow: 'auto',
  scrollbarColor: '#595959 #ffffff',
  scrollbarWidth: 'thin',
  '&::-webkit-scrollbar': {
    width: '6px',
  },
  '&::-webkit-scrollbar-track': {
    backgroundColor: '#ffffff',
    borderRadius: '5px',
  },
  '&::-webkit-scrollbar-thumb': {
    backgroundColor: '#595959',
    borderRadius: '5px',
  },
  '&::-webkit-scrollbar-thumb:hover': {
    backgroundColor: '#666666',
  },
})
const VideoBox = styled(Box)({
  width: '100%',
  height: '100%',
  position: 'relative',
  zIndex: 100,
  margin: '0 0 3px 0',
  padding: 0,
  '&:last-child': {
    margin: 0,
  },
})
const Video = styled('video')({
  width: '100%',
  position: 'absolute',
  zIndex: 200,
  borderRadius: '10px',
})
const VideoSelectorOverlay = styled('div')({
  width: '100%',
  height: '100%',
  position: 'absolute',
  zIndex: 400,
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'flex-end',
  alignItems: 'flex-start',
  cursor: 'pointer',
  borderRadius: '10px',
})
const VideoControls = styled(Box)({
  width: '100%',
  height: '100%',
  position: 'absolute',
  zIndex: 500,
  display: 'flex',
  flexDirection: 'column',
  margin: 0,
  padding: '30px 25px 10px 25px',
  backgroundColor: 'transparent',
  borderRadius: '10px',
})
const TickMark = styled('img')({
  opacity: 0,
  transition: 'opacity 0.3s ease',
  margin: '15px 15px 0 0',
})

const SHORTCUTS = [
  'Enter',
  'Space',
  'KeyF',
  'KeyM',
  'ArrowUp',
  'ArrowDown',
  'ArrowRight',
  'ArrowLeft',
]

function PlaylistDeskVids() {
  const {
    VideoRefs,
    selectedVideoId,
    setSelectedVideoId,
    showCPanel,
    currentlyAtRef,
    isShakaInstalledOk,
    shakaInstances,
    subtitleRefs,
    displaySubs,
    seekTo,
    initOneTrack,
    pressPlayPause,
    plstPlaybackStateChange,
    continueWithSetSubs,
    updateCurrentTime,
  } = usePreviewContext()

  const [anchorElem, setAnchorElem] = useState<null | HTMLElement>(null)

  const mediaTracks: IMediaTrack[] = useSelector(
    (state: RootState) => state.learningObject.mediaTracks,
  )
  const headVideoId = useSelector((state: RootState) => state.componentDisplay.headVideoId)
  const headVideoIndex = useSelector((state: RootState) => state.componentDisplay.headVideoIndex)
  const isVidControlPanelOn = useSelector(
    (state: RootState) => state.componentDisplay.isVidControlPanelOn,
  )
  const isPlaying = useSelector((state: RootState) => state.playback.isPlaying)
  const isUserSeeking = useSelector((state: RootState) => state.playback.isUserSeeking)
  const isVideoLoading = useSelector((state: RootState) => state.componentDisplay.isVideoLoading)
  const subtitleLang = useSelector((state: RootState) => state.playback.subtitleLang)
  const blackouts = useSelector((state: RootState) => state.componentDisplay.blackouts)
  const hiddens = useSelector((state: RootState) => state.componentDisplay.hiddens)
  const justOneTrack = useSelector((state: RootState) => state.componentDisplay.justOneTrack)
  const pbSpeeds = useSelector((state: RootState) => state.playback.pbSpeeds)
  const playbackSpeed = useSelector((state: RootState) => state.playback.playbackSpeed)
  const volumeLevel = useSelector((state: RootState) => state.playback.volumeLevel)
  const fullScreenVideoElem = useSelector(
    (state: RootState) => state.componentDisplay.fullScreenVideoElem,
  )
  const hasContentEnded = useSelector((state: RootState) => state.playback.hasContentEnded)
  const browser = useSelector((state: RootState) => state.deviceInfo.browser)
  const isAbortModal = useSelector((state: RootState) => state.componentDisplay.isAbortModal)

  const dispatch = useDispatch()

  const headVideoContainerRef = useRef<HTMLDivElement>(null)
  const sideVideosContainerRef = useRef<HTMLDivElement>(null)

  const videoBoxRefs = useRef<HTMLDivElement[]>([])
  const volumeMem = useRef<number>(0)

  const { width: headVidContWidth } = useResizeObserver<HTMLDivElement>({
    ref: headVideoContainerRef,
  })

  const makeVideoBoxStyles = useCallback(
    (vidInd: number, videoId: string) => {
      if (videoId === headVideoId) {
        return {
          width: '100%',
          height: '100%',
        }
      } else {
        const noHiddens: number = hiddens.reduce(
          (hids: number, hid: boolean) => hids + (hid ? 1 : 0),
          0,
        )
        const fewerThanFour = mediaTracks.length < 4 || mediaTracks.length - noHiddens < 4
        const minHeight = fewerThanFour ? '49.5%' : '48%'
        return {
          minHeight,
          display: hiddens[vidInd] ? 'none' : 'inline-block',
        }
      }
    },
    [headVideoId, hiddens],
  )

  const makeVideoStyles = useCallback(
    (vidInd: number, videoId: string) => {
      const noHiddens: number = hiddens.reduce(
        (hids: number, hid: boolean) => hids + (hid ? 1 : 0),
        0,
      )
      const justTwo = mediaTracks.length - noHiddens < 3
      return {
        objectFit: videoId !== headVideoId && justTwo ? 'contain' : 'cover',
        height: videoId !== headVideoId && justTwo ? 'auto' : '100%',
      }
    },
    [headVideoId, hiddens],
  )

  const handleShakaError = (event: any): void => {
    AlertMsg.show({
      type: 'error',
      message: event?.detail?.code,
      context: 'Shaka Player error → ',
    })
  }

  const moveProgressWithKey = (keyboardEvent: number): void => {
    const toWhere = VideoRefs.current[headVideoIndex]!.currentTime + keyboardEvent
    seekTo(toWhere, isPlaying)
  }

  const openSpPicker = (event: MouseEvent<HTMLButtonElement>): void => {
    setAnchorElem(event.currentTarget)
  }
  const closeSpPicker = () => setAnchorElem(null)
  const isSpeedPopUp = !!anchorElem
  const popoverId = isSpeedPopUp ? 'playback-speed-selector-popover' : undefined

  const handleSpeedPick = (event: SyntheticEvent<HTMLDivElement>): void => {
    const pbSpeed = parseFloat((event.target as HTMLDivElement).textContent as string)
    dispatch(changePlaybackSpeed(pbSpeed))

    VideoRefs.current.forEach((video: HTMLVideoElement) => {
      video.playbackRate = pbSpeed
    })
  }

  const handleVolumeSlide = (event: Event | null, newLevel: number | number[]) => {
    const soundLevel = newLevel as number
    VideoRefs.current.forEach((video: HTMLVideoElement) => {
      video.volume = soundLevel / 100
    })

    dispatch(setVolumeLevel(soundLevel))
  }

  const handleVolumeKeyDown = (volumeDiff: number) => {
    const volume = VideoRefs.current[headVideoIndex].volume * 100
    const volumeUpdate = volume + volumeDiff
    if (volumeUpdate >= 0 && volumeUpdate <= 100) {
      handleVolumeSlide(null, volumeUpdate)
    } else if (volumeUpdate < 0) {
      handleVolumeSlide(null, 0)
    } else {
      handleVolumeSlide(null, 100)
    }
  }

  const muteUnmute = () => {
    if (VideoRefs.current[headVideoIndex].volume === 0) {
      handleVolumeSlide(null, volumeMem.current)
      volumeMem.current = 0
    } else {
      volumeMem.current = VideoRefs.current[headVideoIndex].volume * 100
      handleVolumeSlide(null, 0)
    }
  }

  const displayVolume = () => {
    if (volumeLevel < 1) {
      return <VolumeOff onPointerDown={muteUnmute} sx={{ cursor: 'pointer' }} />
    } else if (volumeLevel <= 34) {
      return (
        <VolumeMute
          onPointerDown={muteUnmute}
          sx={{ position: 'relative', right: '4px', cursor: 'pointer' }}
        />
      )
    } else if (volumeLevel <= 67) {
      return (
        <VolumeDown
          onPointerDown={muteUnmute}
          sx={{ position: 'relative', right: '2px', cursor: 'pointer' }}
        />
      )
    } else if (volumeLevel <= 100) {
      return <VolumeUp onPointerDown={muteUnmute} sx={{ cursor: 'pointer' }} />
    }
  }

  const toggleFullScreen = () => {
    if (screenfull.isEnabled && headVideoContainerRef.current) {
      if (screenfull.element === undefined) {
        screenfull
          .request(headVideoContainerRef.current)
          .then(() => dispatch(setFullScreenVideoElem(headVideoId)))
          .catch(() => {
            AlertMsg.show({
              type: 'warning',
              message: "Can't enter fullscreen mode!",
            })
          })
      } else {
        screenfull
          .exit()
          .then(() => dispatch(setFullScreenVideoElem('')))
          .catch(() => {
            AlertMsg.show({
              type: 'warning',
              message: "Can't exit fullscreen mode!",
            })
          })
      }
    }
  }

  useEffect(() => {
    let shakaRefs: any[] = []

    if (mediaTracks.length && isShakaInstalledOk.current && !shakaInstances.current.length) {
      shakaInstances.current = new Array(mediaTracks.length).fill(null)
      dispatch(toggleVideoLoader({ vidInd: -1, toLoad: true }))
      initOneTrack(mediaTracks, 0, VideoRefs.current[0])
        .then(() => {
          const sideHeadVideoBox = videoBoxRefs.current[0]
          const headCont = headVideoContainerRef.current
          const sideCont = sideVideosContainerRef.current
          if (sideHeadVideoBox && sideCont && headCont) {
            const headVideoBoxToMove = sideCont.removeChild(sideHeadVideoBox)
            headCont.appendChild(headVideoBoxToMove)
          }
          window.shakaPlayers = shakaInstances.current
          shakaRefs = shakaInstances.current
        })
        .catch((error: any) => {
          AlertMsg.show({ type: 'error', message: error })
          if (!isAbortModal.on) dispatch(showAbortModal('playabort'))
        })
        .finally(() => dispatch(toggleVideoLoader({ vidInd: -1, toLoad: false })))
    }

    return () => {
      shakaRefs.forEach((shaka) => shaka.removeEventListener('error', handleShakaError))
    }
  }, [mediaTracks])

  const handlePlaybackSettings = (newHeadVideoElem: any) => {
    setTimeout(() => {
      newHeadVideoElem.volume = volumeLevel / 100
      newHeadVideoElem.playbackRate = 1
      newHeadVideoElem.play()
    }, 1000)
  }

  useEffect(() => {
    if (selectedVideoId !== '') {
      const subtLangCopy = subtitleLang
      dispatch(chooseSubtitle('Off'))

      setTimeout(() => {
        const headCont = headVideoContainerRef.current
        const sideCont = sideVideosContainerRef.current
        const selectedHeadVideoBox = sideCont?.children?.namedItem(`VideoBox_${selectedVideoId}`)

        if (selectedHeadVideoBox && sideCont && headCont?.lastElementChild) {
          const exHeadVideoElem = VideoRefs.current[headVideoIndex]
          exHeadVideoElem.pause()
          const newHeadVidInd = VideoRefs.current.findIndex(
            (video: HTMLVideoElement) => video.id === selectedVideoId,
          )
          const newHeadVideoElem = VideoRefs.current[newHeadVidInd]
          const headVideoData = mediaTracks[newHeadVidInd]
          dispatch(setHeadVideoId(selectedVideoId))
          dispatch(setHeadVideoIndex(newHeadVidInd))
          dispatch(storeMediaDuration(headVideoData.duration / 1000))
          dispatch(setCurrentlyAt(newHeadVideoElem.currentTime))
          dispatch(changePlaybackSpeed(1))
          currentlyAtRef.current = newHeadVideoElem.currentTime
          if (shakaInstances.current[newHeadVidInd] === null) {
            dispatch(toggleVideoLoader({ vidInd: -1, toLoad: true }))
            const exHeadVideoBox: Element = headCont.removeChild(headCont.lastElementChild)
            initOneTrack(mediaTracks, newHeadVidInd, newHeadVideoElem)
              .then(() => {
                sideCont.insertBefore(
                  exHeadVideoBox,
                  videoBoxRefs.current[headVideoIndex + 1] || null,
                )
                const newHeadVideoBox = sideCont.removeChild(selectedHeadVideoBox)
                headCont.appendChild(newHeadVideoBox)
                if (isPlaying) handlePlaybackSettings(newHeadVideoElem)
                continueWithSetSubs(newHeadVidInd, subtLangCopy)
              })
              .catch((error: any) => {
                AlertMsg.show({ type: 'error', message: error })
                if (!isAbortModal.on) dispatch(showAbortModal('playabort'))
              })
              .finally(() => dispatch(toggleVideoLoader({ vidInd: -1, toLoad: false })))
          } else {
            const exHeadVideoBox = headCont.removeChild(headCont.lastElementChild)
            sideCont.insertBefore(exHeadVideoBox, videoBoxRefs.current[headVideoIndex + 1] || null)
            const newHeadVideoBox = sideCont.removeChild(selectedHeadVideoBox)
            headCont.appendChild(newHeadVideoBox)
            if (isPlaying) handlePlaybackSettings(newHeadVideoElem)
            continueWithSetSubs(newHeadVidInd, subtLangCopy)
          }
          newHeadVideoElem.volume = volumeLevel / 100
        }
        setSelectedVideoId('')
      }, 300)
    }
  }, [selectedVideoId])

  useEffect(() => {
    if (hasContentEnded) {
      const nextInPlaylistIndex = headVideoIndex + 1
      if (nextInPlaylistIndex < mediaTracks.length) {
        setSelectedVideoId(mediaTracks[nextInPlaylistIndex].id)
        VideoRefs.current[headVideoIndex].currentTime = 0
      }
    }
    dispatch(signalContentEnded(false))
  }, [hasContentEnded])

  useEffect(() => {
    const keyDownHandler = (event: any) => {
      const { code } = event
      if (
        SHORTCUTS.includes(code) &&
        event.target.tagName !== 'TEXTAREA' &&
        event.target.tagName !== 'INPUT'
      ) {
        event.preventDefault()
        if (!VideoRefs.current[headVideoIndex].paused) showCPanel(true, event)

        switch (code) {
          case 'Enter':
          case 'Space':
            pressPlayPause(VideoRefs?.current[headVideoIndex]?.paused)
            break
          case 'KeyF':
            toggleFullScreen()
            break
          case 'KeyM':
            muteUnmute()
            break
          case 'ArrowUp':
            handleVolumeKeyDown(10)
            break
          case 'ArrowDown':
            handleVolumeKeyDown(-10)
            break
          case 'ArrowRight':
            moveProgressWithKey(10)
            break
          case 'ArrowLeft':
            moveProgressWithKey(-10)
            break
          default:
            break
        }
      }
    }

    if (mediaTracks.length) document.addEventListener('keydown', keyDownHandler)

    return () => document.removeEventListener('keydown', keyDownHandler)
  }, [mediaTracks, headVideoIndex])

  useEffect(() => {
    const forceFsButtonChange = () => {
      if (!screenfull.isFullscreen && fullScreenVideoElem) {
        dispatch(setFullScreenVideoElem(''))
      }
    }

    if (screenfull.isEnabled) {
      screenfull.on('change', forceFsButtonChange)
    }
    return () => {
      screenfull.off('change', forceFsButtonChange)
    }
  }, [fullScreenVideoElem])

  useEffect(() => {
    displaySubs(subtitleLang)
  }, [subtitleLang])

  return (
    <DesktopVideosFrame
      sx={{
        justifyContent: justOneTrack ? 'center' : 'space-between',
        height: (headVidContWidth! * 9) / 16,
      }}
    >
      <HeadVideoContainer
        ref={headVideoContainerRef}
        sx={{ width: justOneTrack ? '80%' : '64.5%' }}
      >
        <HeadLoaderCont
          sx={{
            width: isVideoLoading[-1] ? '100%' : 0,
            height: isVideoLoading[-1] ? '100%' : 0,
          }}
        >
          {isVideoLoading[-1] && <BouncingLoader />}
        </HeadLoaderCont>
      </HeadVideoContainer>

      <SideVideosContainer
        ref={sideVideosContainerRef}
        sx={{ display: justOneTrack ? 'none' : 'flex' }}
      >
        {mediaTracks.map((video: any, vidInd: number) => {
          return (
            <VideoBox
              key={`VideoBox_${video.id}`}
              id={`VideoBox_${video.id}`}
              ref={(element: HTMLDivElement) => {
                videoBoxRefs.current[vidInd] = element
              }}
              onPointerMove={(event: any) => {
                if (
                  video.id === headVideoId &&
                  event?.nativeEvent?.pointerType === 'mouse' &&
                  !isUserSeeking &&
                  isPlaying
                ) {
                  showCPanel(true)
                }
              }}
              onTouchStart={() => {
                if (video.id === headVideoId && !isUserSeeking && isPlaying) showCPanel(true)
              }}
              sx={makeVideoBoxStyles(vidInd, video.id)}
            >
              <Video
                id={video.id}
                ref={(element: HTMLVideoElement) => {
                  VideoRefs.current[vidInd] = element
                }}
                crossOrigin="anonymous"
                preload="metadata"
                poster={video.poster || defaultPoster}
                playsInline
                muted={video.id !== headVideoId}
                onTimeUpdate={() => updateCurrentTime(vidInd)}
                onRateChange={() => plstPlaybackStateChange(vidInd, 'speed', true)}
                onWaiting={() => plstPlaybackStateChange(vidInd, 'waiting', true)}
                onSeeking={() => plstPlaybackStateChange(vidInd, 'seeking', true)}
                onSeeked={() => plstPlaybackStateChange(vidInd, 'seeked', false)}
                onPlay={() => plstPlaybackStateChange(vidInd, 'play', false)}
                onPlaying={() => plstPlaybackStateChange(vidInd, 'playing', false)}
                onPause={() => plstPlaybackStateChange(vidInd, 'pause', false)}
                onError={() => plstPlaybackStateChange(vidInd, 'error', false)}
                onAbort={() => plstPlaybackStateChange(vidInd, 'aborted', false)}
                onEnded={() => {
                  plstPlaybackStateChange(vidInd, 'ended', false)
                  dispatch(signalContentEnded(true))
                }}
                sx={makeVideoStyles(vidInd, video.id)}
              >
                {video.subtitles?.length > 1 &&
                  video.subtitles
                    .slice(1)
                    .map((subtitle: any) => (
                      <track
                        key={`${video.id}-textTrack-${subtitle.locale}`}
                        id={`${subtitle.locale}`}
                        kind="subtitles"
                        src={subtitle.url}
                        srcLang={subtitle.locale.slice(0, 2)}
                        label={subtitle.label}
                      />
                    ))}
              </Video>

              <SubtitleOverlay video={video} vidInd={vidInd} subtitleRefs={subtitleRefs} />

              {video.id === headVideoId && (
                <Fade in={isVidControlPanelOn} timeout={500}>
                  <VideoControls>
                    <ControlsTop>
                      <TimeBox>
                        <PositionDuration currentlyAtRef={currentlyAtRef} />
                      </TimeBox>
                    </ControlsTop>

                    <ControlsMiddle>
                      {isVideoLoading[vidInd] ? (
                        <BouncingLoader position="absolute" top={0} zIndex={101} />
                      ) : null}
                    </ControlsMiddle>

                    <ControlsBottom>
                      <ProgressBox>
                        <ProgressBar showCPanel={showCPanel} seekTo={seekTo} />
                      </ProgressBox>

                      <BottomButtons>
                        <LeftBB>
                          <PlayPauseBox>
                            <PlayPauseButton pressPlayPause={pressPlayPause} videoId={video.id} />
                          </PlayPauseBox>

                          <VolumeBox>
                            {displayVolume()}
                            <VolumeBar
                              aria-label="Volume"
                              value={volumeLevel}
                              onChange={handleVolumeSlide}
                              size="small"
                              valueLabelDisplay="auto"
                            />
                          </VolumeBox>
                        </LeftBB>

                        <RightBB>
                          {fullScreenVideoElem === '' && (
                            <>
                              <PopOverButton
                                aria-describedby={popoverId}
                                variant="outlined"
                                color="inherit"
                                onPointerDown={openSpPicker}
                              >
                                {`${playbackSpeed}x`}
                              </PopOverButton>
                              <PopOverMenu
                                id={popoverId}
                                open={isSpeedPopUp}
                                anchorEl={anchorElem}
                                onClose={closeSpPicker}
                                anchorOrigin={{
                                  vertical: 'top',
                                  horizontal: 'center',
                                }}
                                transformOrigin={{
                                  vertical: 'bottom',
                                  horizontal: 'center',
                                }}
                              >
                                <PlaybackSpeedList id="playback-speed-list">
                                  {pbSpeeds.map((pbSpeed: number, i: number) => (
                                    <PlaybackSpeedListButton
                                      key={'pbSpeed_' + i}
                                      onPointerDown={handleSpeedPick}
                                    >
                                      {`${pbSpeed}x`}
                                    </PlaybackSpeedListButton>
                                  ))}
                                </PlaybackSpeedList>
                              </PopOverMenu>
                            </>
                          )}

                          {browser?.name !== 'Safari' && (
                            <FullScreenButton onPointerDown={toggleFullScreen}>
                              {fullScreenVideoElem ? (
                                <FullscreenExitIcon fontSize="large" />
                              ) : (
                                <FullscreenIcon fontSize="large" />
                              )}
                            </FullScreenButton>
                          )}
                        </RightBB>
                      </BottomButtons>
                    </ControlsBottom>
                  </VideoControls>
                </Fade>
              )}

              {(!isVideoLoading[vidInd] || (isVideoLoading[vidInd] && blackouts[vidInd])) && (
                <VideoSelectorOverlay
                  onPointerDown={() => {
                    if (video.id !== headVideoId) setSelectedVideoId(video.id)
                  }}
                  sx={{
                    backgroundColor: blackouts[vidInd] ? 'rgba(0, 0, 0, 1)' : 'rgba(0, 0, 0, 0)',
                  }}
                >
                  <TickMark
                    src={Tick}
                    alt="Tick mark icon"
                    sx={{ opacity: `${selectedVideoId === video.id ? 1 : 0}` }}
                  />
                </VideoSelectorOverlay>
              )}
            </VideoBox>
          )
        })}
      </SideVideosContainer>
    </DesktopVideosFrame>
  )
}

export default PlaylistDeskVids
