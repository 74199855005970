import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import { INotesState, IBookmarkContent } from 'store/storeTypes'

const initialState: INotesState = {
  bookmarks: [],
  bookmarkBeingEdited: null,
}

export const notesSlice = createSlice({
  name: 'notes',
  initialState,
  reducers: {
    storeBookmarks: (state: INotesState, action: PayloadAction<any[]>) => {
      state.bookmarks = action.payload
    },
    newBookmark: (state: INotesState, action: PayloadAction<number>) => {
      state.bookmarkBeingEdited = {
        id: null,
        noteIndex: action.payload,
        title: '',
        description: '',
        position: '',
        contentId: '',
      }
    },
    editBookmark: (state: INotesState, action: PayloadAction<number>) => {
      const editedBookmark = state.bookmarks[action.payload]
      editedBookmark.noteIndex = action.payload
      state.bookmarkBeingEdited = editedBookmark
    },
    writeBookmark: (state: INotesState, action: PayloadAction<null | IBookmarkContent>) => {
      if (action.payload === null) {
        state.bookmarkBeingEdited = null
      } else {
        const { title, description } = action.payload
        state.bookmarkBeingEdited.title = title
        state.bookmarkBeingEdited.description = description
      }
    },
  },
})

export const { storeBookmarks, newBookmark, editBookmark, writeBookmark } = notesSlice.actions
export default notesSlice.reducer
