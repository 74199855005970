import { v4 as uuidv4 } from 'uuid'
import { display } from 'store/reducers/alertMsg'
import { IAlertMsg } from 'store/storeTypes'
import { store } from 'store'

const AlertMsg = {
  /**\
   * If `alert.message` is an error object it transforms it into a string based on the
   * type of the error. Prepends the `alert.context` to the message if it is given.
   * Shows the message snackbar by dispatching the `alert` object to the store which
   * triggers the AlertMsgFrame to display the message (`alert.type` can be:
   * 'info', 'success', 'warning', 'error').
   * @param alert an alert message object
   * @return undefined
  \**/
  show(alert: IAlertMsg) {
    if (alert.type === 'error') {
      const error = alert.message
      let errorMessage = ''

      if (error?.response?.data) {
        // handling 400 form validation errors
        // https://blueguava.atlassian.net/wiki/spaces/IGEMS/pages/3442475036/HTTP+Error+Handling
        if (error.response.data.errors) {
          const allErrors = error.response.data.errors
          Object.keys(allErrors).forEach((value) => {
            allErrors[value].forEach((errorString: string) => {
              let errorMessage = errorString
              if (alert.valueToLabel) {
                errorMessage = errorString.replaceAll(
                  value,
                  alert.valueToLabel[value.toLowerCase()],
                )
              }
              if (alert.context) {
                errorMessage = ''.concat(alert.context, errorMessage)
              }
              store.dispatch(
                display({
                  type: alert.type,
                  message: errorMessage,
                  id: uuidv4(),
                  title: alert.title,
                  duration: alert.duration ?? 10000,
                }),
              )
            })
          })
          return
        } else if (typeof error.response.data === 'string') {
          errorMessage = error.response.data
        } else if (typeof error.response.data.message === 'string') {
          errorMessage = error.response.data.message
        } else if (error?.title) {
          errorMessage = error.title
        }
      } else if (error?.toJSON) {
        const errorObj = error.toJSON()
        errorMessage = errorObj.message
        if (errorObj.status === 401) {
          errorMessage = 'Wrong token: you are unauthorized to make this request!'
        }
      } else if (error?.message) {
        errorMessage = error.message
      } else if (typeof error.data === 'string') {
        errorMessage = error.data
      } else if (error?.data?.message) {
        errorMessage = error.data.message
      } else if (typeof error === 'string') {
        errorMessage = error
      } else if (typeof error === 'number') {
        errorMessage = error.toString()
      }

      if (errorMessage === '') {
        errorMessage = 'An error occurred!'
      }
      alert.message = errorMessage
    }

    if (alert.context) {
      alert.message = ''.concat(alert.context, alert.message)
    }

    store.dispatch(
      display({
        type: alert.type,
        message: alert.message,
        id: uuidv4(),
        title: alert.title,
        duration: alert.duration ?? 10000,
      }),
    )
  },
}

export default AlertMsg
