import { useSelector } from 'react-redux'
import { AxiosError } from 'axios'
import { Box, Table, TableBody, TableCell, TableRow } from '@mui/material'
import { styled } from '@mui/system'
import AlertMsg from 'services/alertMsg'
import downloadFile from 'services/file'
import { WebAssetOutlined } from '@mui/icons-material'
import { formatFileSize } from 'utils/formatFileSize'
import Excel from 'assets/icons/iconExcel.svg'
import Pdf from 'assets/icons/iconPdf.svg'
import Powerpoint from 'assets/icons/iconPowerpoint.svg'
import Video from 'assets/icons/iconVideo.svg'
import Word from 'assets/icons/iconWord.svg'
import Image from 'assets/icons/iconImg.svg'
import Text from 'assets/icons/iconText.svg'
import Audio from 'assets/icons/iconAudio.svg'
import DownloadSrc from 'assets/icons/download.svg'
import type { RootState } from 'store/index'

const AssetContainer = styled('div')({
  width: '100%',
  margin: 0,
  padding: 0,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
})
const MobileTable = styled(Table)({
  width: '95%',
  margin: '12px 0 20px 0',
})
const DescBox = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  overflowWrap: 'break-word',
  wordBreak: 'break-word',
  whiteSpace: 'pre-line',
})
const DownloadBox = styled(Box)({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'flex-end',
  alignItems: 'center',
  fontSize: '10px',
})
const DownloadIcon = styled('img')({
  width: '18px',
  height: '18px',
})
const AssetInfo = styled('span')({
  textIndent: '2px',
  fontSize: '12px',
})
const TCell = styled(TableCell)({
  border: 'none',
  padding: '0 2px 10px 2px',
})
const WebAssetIcon = styled(WebAssetOutlined)({
  width: '25px',
  height: '25px',
})
const FileIcon = styled('img')({
  width: '25px',
  height: '25px',
})

function Assets() {
  const learnObjItem = useSelector((state: RootState) => state.learningObject.item)

  const getFile = (downloadUrl: string) => {
    downloadFile(downloadUrl).catch((error: AxiosError) => {
      AlertMsg.show({
        type: 'error',
        message: error,
        context: 'Could not download the file → ',
      })
    })
  }

  const renderIcon = (name: string) => {
    const extensionDotIndex = name.lastIndexOf('.')
    const fileExtension = name.slice(extensionDotIndex + 1)
    switch (fileExtension) {
      case 'bmp':
      case 'jpg':
      case 'jpeg':
      case 'png':
      case 'svg':
        return <FileIcon src={Image} alt="image extension icon" />
      case 'csv':
      case 'txt':
        return <FileIcon src={Text} alt="text extension icon" />
      case 'doc':
      case 'docx':
        return <FileIcon src={Word} alt="word extension icon" />
      case 'mp4':
      case 'mpd':
      case 'm3u8':
      case 'ism':
        return <FileIcon src={Video} alt="video extension icon" />
      case 'wav':
      case 'mp3':
      case 'ogg':
        return <FileIcon src={Audio} alt="audio extension icon" />
      case 'xls':
      case 'xlsx':
        return <FileIcon src={Excel} alt="excel extension icon" />
      case 'ppt':
      case 'pptx':
        return <FileIcon src={Powerpoint} alt="powerpoint extension icon" />
      case 'pdf':
        return <FileIcon src={Pdf} alt="pdf extension icon" />
      default:
        return <WebAssetIcon />
    }
  }

  return (
    <AssetContainer>
      <MobileTable padding="none" size="small">
        <TableBody>
          {learnObjItem?.assets.map((asset: any, index: number) => {
            const { name, locale, downloadUrl, fileSize } = asset
            return (
              <TableRow key={`${asset.id}_${index}`}>
                <TCell align="left">{renderIcon(name)}</TCell>

                <TCell align="left">
                  <DescBox>
                    <AssetInfo>
                      {name}
                      {locale && locale !== '--' ? ` — ${locale}` : ''}
                    </AssetInfo>
                  </DescBox>
                </TCell>

                <TCell align="right">
                  <AssetInfo>{formatFileSize(fileSize)}</AssetInfo>
                </TCell>

                <TCell align="right">
                  <DownloadBox>
                    <DownloadIcon src={DownloadSrc} onPointerDown={() => getFile(downloadUrl)} />
                  </DownloadBox>
                </TCell>
              </TableRow>
            )
          })}
        </TableBody>
      </MobileTable>
    </AssetContainer>
  )
}

export default Assets
