import axios, { AxiosResponse, AxiosPromise } from 'axios'
import { store } from 'store'

export type TNote = {
  id?: string
  index?: null
  title: string
  description: string
  position: number
  contentId: string
  providerId?: string
}

interface IBookmarks {
  baseURL: string
  getOneNote(bookmarkId: string): AxiosPromise<AxiosResponse>
  listNotes(contentId: string, providerId?: string): AxiosPromise<AxiosResponse>
  addNote(payload: TNote): AxiosPromise<AxiosResponse>
  updateNote(bookmarkId: string, payload: TNote): AxiosPromise<AxiosResponse>
  deleteNote(bookmarkId: string): AxiosPromise<AxiosResponse>
}

const Bookmarks: IBookmarks = {
  baseURL: store.getState().apiURLs.bookmarks,

  getOneNote(bookmarkId) {
    return axios({
      baseURL: this.baseURL,
      url: `/${bookmarkId}`,
      method: 'get',
    })
  },

  listNotes(contentId, providerId) {
    return axios({
      baseURL: this.baseURL,
      url: `/List/${contentId}${providerId ? '/' + providerId : ''}?pageSize=100&pageIndex=0`,
      method: 'get',
    })
  },

  addNote(payload) {
    return axios({
      baseURL: this.baseURL,
      url: '',
      method: 'post',
      data: payload,
    })
  },

  updateNote(bookmarkId, payload) {
    return axios({
      baseURL: this.baseURL,
      url: `/${bookmarkId}`,
      method: 'put',
      data: payload,
    })
  },

  deleteNote(bookmarkId) {
    return axios({
      baseURL: this.baseURL,
      url: `/${bookmarkId}`,
      method: 'delete',
    })
  },
}

export default Bookmarks
