import { createSlice } from '@reduxjs/toolkit'
import { IApiURLs, IApiNameUrl } from 'store/storeTypes'
import type { PayloadAction } from '@reduxjs/toolkit'

const initialState: IApiURLs = {
  appBase: `${window.__RUNTIME_CONFIG__.BASE_URL}/api/v1.2`,
  auth: `${window.__RUNTIME_CONFIG__.AUTHSUB_URL}/api/v2.0`,
  playback: '/App/Playback',
  bookmarks: `${window.__RUNTIME_CONFIG__.COLLECTIONS_URL}/CustomBookmark`,
  customer: `${window.__RUNTIME_CONFIG__.AUTHSUB_URL}/api/v2.0/Customer`,
  discussion: `${window.__RUNTIME_CONFIG__.COLLECTIONS_URL}`,
  reporting: `${window.__RUNTIME_CONFIG__.CLIENT_REPORT}`,
  markers: `${window.__RUNTIME_CONFIG__.MARKERS_URL}/api/v1.0`,
  shootout: `${window.__RUNTIME_CONFIG__.SHOOTOUT_WSS}`,
}

export const apiURLSlice = createSlice({
  name: 'apiURLs',
  initialState,
  reducers: {
    storeOneEP: (state: IApiURLs, action: PayloadAction<IApiNameUrl>) => {
      state[action.payload.name] = action.payload.url
    },
  },
})

export const { storeOneEP } = apiURLSlice.actions

export default apiURLSlice.reducer
