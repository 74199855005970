import { useSelector } from 'react-redux'
import { styled } from '@mui/system'
import type { RootState } from 'store/index'
import { Pause, PlayArrow } from '@mui/icons-material'
import { IconButton } from '@mui/material'

const IconButtonBlock = styled(IconButton)({
  width: '32px',
  height: '32px',
  padding: 0,
  margin: 0,
  cursor: 'pointer',
  '&:hover': {
    backgroundColor: 'transparent',
  },
})

const PlayAndPause = styled('div')({
  display: 'flex',
  maxHeight: '60px',
  minHeight: '38px',
  '& svg': {
    fontSize: '3rem',
    color: '#fff',
  },
  '&:hover': {
    backgroundColor: 'transparent',
  },
})

const PlayAndPauseMobile = styled('div')({
  display: 'flex',
  maxHeight: '25px',
  minHeight: '15px',
  position: 'relative',
  right: '5px',
  alignItems: 'center',
  '& svg': {
    fontSize: '2rem',
    color: '#fff',
  },
  '&:hover': {
    backgroundColor: 'transparent',
  },
})

interface IPlayPauseProps {
  pressPlayPause: any
  vidInd?: number
  videoId: string
  isMobile?: boolean
}

type ReadonlyPlayPauseProps = Readonly<IPlayPauseProps>

function PlayPauseButton(props: ReadonlyPlayPauseProps) {
  const { pressPlayPause, vidInd, videoId, isMobile } = props

  const isPlaying = useSelector((state: RootState) => state.playback.isPlaying)
  const headVideoIndex = useSelector((state: RootState) => state.componentDisplay.headVideoIndex)

  const getPlayOrPauseIcon = () => {
    if (typeof vidInd === 'number') {
      return isPlaying && vidInd === headVideoIndex ? <Pause /> : <PlayArrow />
    } else {
      return isPlaying ? <Pause /> : <PlayArrow />
    }
  }

  return (
    <IconButtonBlock key={videoId} onClick={() => pressPlayPause(!isPlaying, vidInd)}>
      {isMobile ? (
        <PlayAndPauseMobile>{getPlayOrPauseIcon()}</PlayAndPauseMobile>
      ) : (
        <PlayAndPause>{getPlayOrPauseIcon()}</PlayAndPause>
      )}
    </IconButtonBlock>
  )
}

export default PlayPauseButton
