import styled from '@emotion/styled'
import { Button } from '@mui/material'
import { MouseEvent } from 'react'
import { useSelector } from 'react-redux'
import { RootState } from 'store'

const PopOverButton = styled(Button)({
  fontSize: '12px',
  textAlign: 'center',
  marginRight: '5px',
  textTransform: 'lowercase',
})

const PlaybackSpeed = ({
  openSpPicker,
  popoverId,
  isMobile,
}: {
  openSpPicker: (event: MouseEvent<HTMLButtonElement>) => void
  popoverId: string | undefined
  isMobile?: boolean
}) => {
  const playbackSpeed = useSelector((state: RootState) => state.playback.playbackSpeed)

  return (
    <PopOverButton
      aria-describedby={popoverId}
      variant="outlined"
      color="inherit"
      onPointerDown={openSpPicker}
      sx={{
        width: isMobile ? '18px' : '33px',
        height: isMobile ? '18px' : '25px',
        minWidth: isMobile ? '18px' : '38px',
        color: '#ffffff',
      }}
    >
      {`${playbackSpeed}x`}
    </PopOverButton>
  )
}

export default PlaybackSpeed
