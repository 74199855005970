import { store } from 'store'
import { AxiosResponse } from 'axios'
import { TLocLabels } from 'store/storeTypes'

/**\
 * Finish docstring
 * @param labelCode 
 * @returns 
\**/
export const showLabel = (labelCode: string): string => {
  const { locLabels, selected: locale } = store.getState().appLanguage
  if (Object.keys(locLabels).length > 0) {
    return locLabels[labelCode] && locLabels[labelCode][locale]
      ? locLabels[labelCode][locale].value
      : labelCode
  } else {
    const locLabelsStr = localStorage.getItem('locLabels')
    if (locLabelsStr) {
      const locStorageLabels = JSON.parse(locLabelsStr)
      return locStorageLabels[labelCode] && locStorageLabels[labelCode][locale]
        ? locStorageLabels[labelCode][locale].value
        : labelCode
    }
    return labelCode
  }
}

/**\
 * Transforms the backend response of UI labels to a `TLocLabels` object to 
 * store in Redux.
 * @param response: AxiosResponse 
 * @returns a TLocLabels object
\**/
export const transformLocLabels = (response: AxiosResponse): TLocLabels => {
  // TODO -> set up proper types, maybe put into a service
  const LocLabels = response?.data.reduce((labels: TLocLabels, entry: any) => {
    labels[entry.key] = {
      [entry.locale]: {
        value: entry.value,
        flavor: entry.flavor || '',
      },
    }
    return labels
  }, {})
  return LocLabels
}
