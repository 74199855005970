export function findAtom(
  atomStarts: number[],
  startAt: number,
  bottomInd = 0,
  topInd = atomStarts?.length - 1,
): number {
  const midInd = Math.floor((bottomInd + topInd) / 2)

  if (startAt < atomStarts[midInd]) {
    return findAtom(atomStarts, startAt, bottomInd, midInd - 1)
  }
  if (startAt > atomStarts[midInd]) {
    if (startAt > atomStarts[atomStarts?.length - 1]) {
      return atomStarts?.length - 1
    } else if (startAt < atomStarts[midInd + 1]) {
      return midInd
    }
    return findAtom(atomStarts, startAt, midInd + 1, topInd)
  }
  return midInd
}
