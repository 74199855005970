import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import { ISearchState, ISearchPerTrack, TCloudTag } from 'store/storeTypes'

const initialState: ISearchState = {
  tagCloud: [],
  searchTerm: '',
  searchResultsPerTracks: {},
}

export const searchSlice = createSlice({
  name: 'search',
  initialState,
  reducers: {
    storeTagCloud: (state: ISearchState, action: PayloadAction<TCloudTag[]>) => {
      state.tagCloud = action.payload
    },
    setSearchTerm: (state: ISearchState, action: PayloadAction<string>) => {
      state.searchTerm = action.payload
    },
    setSearchResultsPerTracks: (state: ISearchState, action: PayloadAction<ISearchPerTrack>) => {
      state.searchResultsPerTracks = action.payload
    },
  },
})

export const { storeTagCloud, setSearchTerm, setSearchResultsPerTracks } = searchSlice.actions
export default searchSlice.reducer
