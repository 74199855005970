import { useState, SyntheticEvent } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Box, Tabs, Tab, Typography, Button } from '@mui/material'
import { Close } from '@mui/icons-material'
import { styled } from '@mui/system'
import { About, Search, Chapters, Notes, Assets, Language } from './PanelTabs'
import RemixDeskVids from './DeskVideos/RemixDeskVids'
import PlaylistDeskVids from './DeskVideos/PlaylistDeskVids'
import SpentTimeDisplay from 'components/SpentTimeDisplay'
import AudioSubtSidebar from './AudioSubtSidebar'
import { showLabel } from 'services/language'
import { toggleAudioSubtSidebar } from 'store/reducers/componentDisplay'
import packageJSON from '../../../../package.json'
import SubAud from 'assets/icons/subAud.svg'
import LogoNexSrc from 'assets/icons/logo_nexius.svg'
import type { RootState } from 'store/index'
import { usePreviewContext } from 'contexts/PreviewContext'
import { useSearchParams } from 'react-router-dom'
import { ArrowBack } from '@mui/icons-material'

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  }
}

const PanelSection = styled('div')({
  minWidth: '750px',
  width: '100%',
  maxWidth: '2500px',
  padding: '60px 5% 10px 5%',
})
const LogoStripe = styled('div')({
  width: '100%',
  height: '80px',
})
const NexLogo = styled('img')({})
const TitleBar = styled('div')({
  width: '100%',
  height: '35px',
  padding: '6px 0 10px',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
  marginBottom: '30px',
})
const TitleBarLeft = styled(Box)({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'flex-start',
  alignItems: 'center',
  '& h4': {
    minWidth: '20px',
    maxWidth: '1050px',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
})
const TitleBox = styled(Box)({
  marginLeft: '20px',
})
const TitleBarRight = styled(Box)({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'flex-end',
})
const AudioSubtSelectorIcon = styled('img')({
  width: '34px',
  height: '34px',
  cursor: 'pointer',
  zIndex: 100,
})

const BackButton = styled(Button)({
  display: 'flex',
  gap: '4px',
  width: '100px',
  color: '#000000',
  backgroundColor: '#7bc0da',
  borderRadius: '24px',
  padding: '5px',
  margin: 0,
  ':hover': {
    color: '#ffffff',
    backgroundColor: '#7bc0da',
  },
  ':active': {
    color: '#ffffff',
    backgroundColor: '#7bc0da',
  },
  zIndex: 9999,
})

const BackButtonText = styled(Typography)({
  fontSize: '16px',
  cursor: 'pointer',
})

const Aligned = styled('div')({
  display: 'flex',
  justifyContent: 'end',

  '& svg': {
    cursor: 'pointer',
  },
})

const TabsSection = styled('section')({
  width: '100%',
})
const TabBox = styled(Box)({
  marginBottom: '25px',
})
const StyledTabs = styled(Tabs)({
  '& .MuiTabs-indicator': {
    backgroundColor: 'transparent',
  },
})
const LearnObjTitle = styled(Typography)({
  fontSize: '32px',
  opacity: 0.9,
  fontWeight: 600,
})
const LearnObjShortinfo = styled(Typography)({
  fontSize: '20px',
  opacity: 0.6,
  marginLeft: '10px',
})
const InterPanelContainer = styled('div')({
  width: '100%',
  minHeight: '100vh',
  margin: 0,
  padding: 0,
})
const TabLabel = styled('span')({
  fontSize: '20px',
  letterSpacing: '0.44px',
  color: '#ffffff',
})
const Footer = styled('footer')({
  color: '#a9a9a9',
})
const FooterBox = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
})

const ContentFrame = () => {
  const contentType = useSelector((state: RootState) => state.learningObject.type)

  if (contentType) {
    return ['Remix', 'RemixV2'].includes(contentType) ? <RemixDeskVids /> : <PlaylistDeskVids />
  } else {
    return null
  }
}

function DeskMainPanel() {
  const { seekTo, isPreview, currentlyAtRef, setIsPressExitButton, getReturnURL } =
    usePreviewContext()

  const [tabIndex, setTabIndex] = useState<number>(0)

  const learnObjItem = useSelector((state: RootState) => state.learningObject.item)
  const appLanguage = useSelector((state: RootState) => state.appLanguage.selected)
  const localizationDict = useSelector((state: RootState) => state.appLanguage.localizationDict)
  const [searchParams] = useSearchParams()
  const dispatch = useDispatch()

  const handleTabChange = (event: SyntheticEvent, newValue: number) => {
    setTabIndex(newValue)
  }

  const displayNotesLabel = () => {
    const notesNo = learnObjItem?.bookmarks?.length
    return `${showLabel('lms_menu_notes')} ${notesNo ? '(' + notesNo + ')' : ''}`
  }

  const displayAssetsLabel = () => {
    const assetsNo = learnObjItem?.assets?.length
    return `${showLabel('lms_menu_assets')} ${assetsNo ? '(' + assetsNo + ')' : ''}`
  }

  const tabs = [
    {
      name: showLabel('lms_menu_about'),
      content: <About />,
    },
    {
      name: showLabel('lms_menu_search'),
      content: <Search seekTo={seekTo} isPreview={isPreview} />,
    },
    // isPreview.current
    //   ? {
    //       name: showLabel('lms_menu_forum'),
    //       content: <Forum seekTo={seekTo} currentlyAtRef={currentlyAtRef} />,
    //     }
    //   : null,
    {
      name: showLabel('lms_menu_chapter'),
      content: <Chapters seekTo={seekTo} />,
    },
    {
      name: displayNotesLabel(),
      content: <Notes seekTo={seekTo} currentlyAtRef={currentlyAtRef} />,
    },
    {
      name: displayAssetsLabel(),
      content: <Assets />,
    },
    {
      name: showLabel('lms_menu_language'),
      content: <Language />,
    },
  ]

  return (
    <PanelSection>
      <Aligned>
      {searchParams.get('isPreview') && <Close onClick={() => {
        window.close()
      }}></Close>}
      </Aligned>
      <LogoStripe>
        <NexLogo width={200} height={45} src={LogoNexSrc} alt="Nexius logo" />
      </LogoStripe>

      <TitleBar>
        <TitleBarLeft>
          {!searchParams.get('isPreview') && <BackButton
            variant="text"
            onClick={(event: any) => {
              setIsPressExitButton(true)

              event.preventDefault()

              setTimeout(() => {
                window.location.href = getReturnURL()
              }, 3000)
            }}
          >
            <ArrowBack />
            <BackButtonText variant="caption">{showLabel('lms_back')}</BackButtonText>
          </BackButton>}
          <TitleBox>
            <LearnObjTitle variant="h4">{localizationDict[appLanguage]?.title ?? ''}</LearnObjTitle>
            <LearnObjShortinfo variant="subtitle1">
              {localizationDict[appLanguage]?.shortinfo ?? ''}
            </LearnObjShortinfo>
          </TitleBox>
        </TitleBarLeft>

        <TitleBarRight>
          <SpentTimeDisplay />

          <AudioSubtSelectorIcon
            src={SubAud}
            alt="Subtile + Audio selector modal"
            onPointerDown={() => {
              setTimeout(() => dispatch(toggleAudioSubtSidebar(true)), 200)
            }}
          />
        </TitleBarRight>
      </TitleBar>

      <InterPanelContainer>
        {ContentFrame()}

        <TabsSection>
          <TabBox>
            <StyledTabs
              value={tabIndex}
              onChange={handleTabChange}
              variant="scrollable"
              scrollButtons="auto"
              aria-label="interaction panel tabs"
            >
              {tabs
                .filter((tab) => tab)
                .map((tab, index) => (
                  <Tab
                    key={'iPanelTab' + index}
                    label={
                      <TabLabel sx={index === tabIndex ? { fontWeight: 600 } : { opacity: 0.5 }}>
                        {tab?.name}
                      </TabLabel>
                    }
                    sx={{ textTransform: 'capitalize' }}
                    {...a11yProps(index)}
                  />
                ))}
            </StyledTabs>
          </TabBox>
          {tabs
            .filter((tab) => tab)
            .map(
              (tab, index) =>
                index === tabIndex && <Box key={'iPanelTabContent' + index}>{tab?.content}</Box>,
            )}
        </TabsSection>
      </InterPanelContainer>

      <AudioSubtSidebar />

      <Footer>
        <FooterBox>
          <Typography sx={{ fontSize: '13px' }}>
            {`${window.__RUNTIME_CONFIG__.SITE_OWNER}`}
          </Typography>

          <Typography sx={{ fontSize: '12px' }}>
            {`${showLabel('lms_app_version')}: ${
              packageJSON.version
            } | © ${new Date().getFullYear()}`}
          </Typography>
        </FooterBox>
      </Footer>
    </PanelSection>
  )
}

export default DeskMainPanel
