import { useSelector, useDispatch } from 'react-redux'
import axios, { AxiosError, AxiosResponse } from 'axios'
import { styled } from '@mui/system'
import { List, ListItem, ListItemText, ListItemIcon } from '@mui/material'
import { changeAppLanguage, storeLocLabels } from 'store/reducers/appLanguage'
import { transformLocLabels } from 'services/language'
import Tick from 'assets/icons/tick.svg'
import uiLangSrcs from 'assets/flags'
import AlertMsg from 'services/alertMsg'
import type { RootState } from 'store/index'

const AppLangSelectorList = styled(List)({
  width: '210px',
  marginBottom: '20px',
})
const AppLangListItem = styled(ListItem)({
  padding: 0,
  margin: '0 0 10px 0',
  cursor: 'pointer',
})
const ListLangName = styled(ListItemText)({
  marginLeft: '10px',
})
const ListIcon = styled(ListItemIcon)({
  minWidth: '35px',
})
const LangFlag = styled('img')({
  width: '25px',
  height: '25px',
  marginTop: '2px',
  borderRadius: '50%',
})
const TickMark = styled('img')({
  opacity: 0,
  transition: 'opacity 0.5s ease',
})

function Language() {
  const appLanguage = useSelector((state: RootState) => state.appLanguage.selected)
  const localizationOptions = useSelector((state: RootState) => state.appLanguage.langCodes)

  const dispatch = useDispatch()

  const getLanguageCodes = (langCode: string) => {
    axios
      .get(`${window.__RUNTIME_CONFIG__.BASE_URL}/api/v1.0/App/Localizations?locale=${langCode}`)
      .then((response: AxiosResponse) => dispatch(storeLocLabels(transformLocLabels(response))))
      .catch((error: AxiosError) => {
        AlertMsg.show({
          type: 'warning',
          message: error,
          context: "Can't retrieve the dictionary → ",
        })
      })
      .finally(() => {
        dispatch(changeAppLanguage(langCode))
      })
  }

  return (
    <AppLangSelectorList>
      {Object.keys(localizationOptions).map((langCode: string, index: number) => {
        return (
          <AppLangListItem
            key={`appLang_${langCode}_${index}`}
            onPointerDown={() => getLanguageCodes(langCode)}
          >
            <ListIcon>
              <LangFlag src={uiLangSrcs[langCode]} alt={`${langCode} flag icon`} />
            </ListIcon>
            <ListLangName
              primaryTypographyProps={{ style: { fontSize: '18px' } }}
              sx={{ color: `${appLanguage === langCode ? '#ffffff' : '#737373'}` }}
            >
              {localizationOptions[langCode]}
            </ListLangName>
            <ListIcon>
              <TickMark
                src={Tick}
                alt="Tick mark icon"
                sx={{ opacity: `${appLanguage === langCode ? 1 : 0}` }}
              />
            </ListIcon>
          </AppLangListItem>
        )
      })}
    </AppLangSelectorList>
  )
}

export default Language
