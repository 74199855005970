import { useSelector } from 'react-redux'
import { RootState } from 'store'
import {
  Fullscreen as FullscreenIcon,
  FullscreenExit as FullscreenExitIcon,
} from '@mui/icons-material'
import styled from '@emotion/styled'
import { Box } from '@mui/material'

const FullScreenButton = styled(Box)({
  width: '30px',
  height: '30px',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  alignItems: 'center',
  cursor: 'pointer',
})

const FullscreenComponent = ({
  toggleFullScreen,
  isMobile,
}: {
  toggleFullScreen: () => void
  isMobile?: boolean
}) => {
  const browser = useSelector((state: RootState) => state.deviceInfo.browser)
  const fullScreenVideoElem = useSelector(
    (state: RootState) => state.componentDisplay.fullScreenVideoElem,
  )

  return (
    <>
      {browser?.name !== 'Safari' && (
        <FullScreenButton onPointerDown={toggleFullScreen} onTouchEnd={() => toggleFullScreen()}>
          {fullScreenVideoElem ? (
            <FullscreenExitIcon
              fontSize={isMobile ? 'small' : 'large'}
              style={{
                color: '#ffffff',
              }}
            />
          ) : (
            <FullscreenIcon
              fontSize={isMobile ? 'small' : 'large'}
              style={{
                color: '#ffffff',
              }}
            />
          )}
        </FullScreenButton>
      )}
    </>
  )
}

export default FullscreenComponent
