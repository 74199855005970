export const playerConfiguration = {
  abr: {
    defaultBandwidthEstimate: 500000,
    enabled: false,
    switchInterval: 6,
    restrictions: {
      minPixels: 1920,
      maxWidth: 1920,
      maxHeight: 1080,
    },
  },
  streaming: {
    bufferBehind: 30,
    bufferingGoal: 30,
    ignoreTextStreamFailures: false,
    rebufferingGoal: 2,
    retryParameters: {
      maxAttempts: 2,
      baseDelay: 1e3,
      backoffFactor: 2,
      fuzzFactor: 0.5,
      timeout: 0,
    },
  },
  manifest: {
    dash: {
      ignoreMinBufferTime: true,
    },
  },
}
