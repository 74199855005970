import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import {
  IComponentDisplayState,
  IVideoControlsBgState,
  TVideoLoaderSetter,
  TBlackHiddenPayload,
  TAbortType,
} from 'store/storeTypes'

const initialState: IComponentDisplayState = {
  isInSwitchMode: false,
  isPortableDevice: false,
  isPortraitView: false,
  isAudioSubtPanelOpen: false,
  isAbortModal: { on: false, type: '' },
  screenWidth: 0,
  screenHeight: 0,
  headVideoId: '',
  headVideoIndex: 0,
  fullScreenVideoElem: '',
  justOneTrack: false,
  isVidControlPanelOn: false,
  isFirstVideoSelected: false,
  isVideoLoading: [false],
  blackouts: [],
  hiddens: [],
  videoControlsBg: {
    id: '',
    value: 0,
    isDark: false,
  },
}

export const componentDisplaySlice = createSlice({
  name: 'componentDisplay',
  initialState,
  reducers: {
    switchAppView: (state: IComponentDisplayState) => {
      state.isInSwitchMode = !state.isInSwitchMode
    },
    deviceIsPortable: (state: IComponentDisplayState, action: PayloadAction<boolean>) => {
      state.isPortableDevice = action.payload
    },
    screenIsInPortrait: (state: IComponentDisplayState, action: PayloadAction<boolean>) => {
      state.isPortraitView = action.payload
    },
    toggleAudioSubtSidebar: (state: IComponentDisplayState, action: PayloadAction<boolean>) => {
      state.isAudioSubtPanelOpen = action.payload
    },
    getScreenWidth: (state: IComponentDisplayState, action: PayloadAction<number>) => {
      state.screenWidth = action.payload
    },
    getScreenHeight: (state: IComponentDisplayState, action: PayloadAction<number>) => {
      state.screenHeight = action.payload
    },
    setHeadVideoId: (state: IComponentDisplayState, action: PayloadAction<string>) => {
      state.headVideoId = action.payload
    },
    setHeadVideoIndex: (state: IComponentDisplayState, action: PayloadAction<number>) => {
      state.headVideoIndex = action.payload
    },
    setFullScreenVideoElem: (state: IComponentDisplayState, action: PayloadAction<string>) => {
      state.fullScreenVideoElem = action.payload
    },
    setUpVideoLoader: (state: IComponentDisplayState, action: PayloadAction<number>) => {
      state.isVideoLoading = new Array(action.payload).fill(false)
    },
    toggleVideoControls: (state: IComponentDisplayState, action: PayloadAction<boolean>) => {
      state.isVidControlPanelOn = action.payload
    },
    toggleVideoLoader: (
      state: IComponentDisplayState,
      action: PayloadAction<TVideoLoaderSetter>,
    ) => {
      const loaders = state.isVideoLoading
      loaders[action.payload.vidInd] = action.payload.toLoad
      state.isVideoLoading = loaders
    },
    showAbortModal: (state: IComponentDisplayState, action: PayloadAction<TAbortType>) => {
      state.isAbortModal = { on: true, type: action.payload }
    },
    hideAbortModal: (state: IComponentDisplayState) => {
      state.isAbortModal = { on: false, type: '' }
    },
    showJustOneTrack: (state: IComponentDisplayState, action: PayloadAction<boolean>) => {
      state.justOneTrack = action.payload
    },
    setBlackouts: (state: IComponentDisplayState, action: PayloadAction<boolean[]>) => {
      state.blackouts = action.payload
    },
    toggleBlackout: (state: IComponentDisplayState, action: PayloadAction<TBlackHiddenPayload>) => {
      const blackouts = state.blackouts
      blackouts[action.payload.index] = action.payload.to
      state.blackouts = blackouts
    },
    setHiddens: (state: IComponentDisplayState, action: PayloadAction<boolean[]>) => {
      state.hiddens = action.payload
    },
    toggleHidden: (state: IComponentDisplayState, action: PayloadAction<TBlackHiddenPayload>) => {
      const hiddens = state.hiddens
      hiddens[action.payload.index] = action.payload.to
      state.hiddens = hiddens
    },
    setIsFirstVideoSelected: (state: IComponentDisplayState, action: PayloadAction<boolean>) => {
      state.isFirstVideoSelected = action.payload
    },
    setVideoControlsBg: (
      state: IComponentDisplayState,
      action: PayloadAction<IVideoControlsBgState>,
    ) => {
      state.videoControlsBg = action.payload
    },
  },
})

export const {
  switchAppView,
  deviceIsPortable,
  screenIsInPortrait,
  toggleAudioSubtSidebar,
  getScreenWidth,
  getScreenHeight,
  setHeadVideoId,
  setHeadVideoIndex,
  setFullScreenVideoElem,
  setIsFirstVideoSelected,
  setUpVideoLoader,
  toggleVideoControls,
  toggleVideoLoader,
  showJustOneTrack,
  showAbortModal,
  hideAbortModal,
  setBlackouts,
  toggleBlackout,
  setHiddens,
  toggleHidden,
  setVideoControlsBg,
} = componentDisplaySlice.actions

export default componentDisplaySlice.reducer
