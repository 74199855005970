import { useCallback } from 'react'
import { useSelector } from 'react-redux'
import { styled } from '@mui/system'
import { secToHHMMSS } from 'utils/dateTimeConfig'
import type { RootState } from 'store/index'

const PosDurTimer = styled('div')({
  display: 'inline-block',
  backgroundColor: 'rgba(255, 255, 255, 0.6)',
  color: '#000000',
  padding: '5px',
  borderRadius: '5px',
  fontWeight: 500,
  letterSpacing: '0.45px',
})

function PositionDuration(props: any) {
  const { currentlyAtRef } = props

  const currentlyAt = useSelector((state: RootState) => state.playback.currentlyAt)
  const mediaDuration = useSelector((state: RootState) => state.playback.mediaDuration)
  const isPortableDevice = useSelector(
    (state: RootState) => state.componentDisplay.isPortableDevice,
  )

  const displayTimers = useCallback(() => {
    currentlyAtRef.current = currentlyAt
    const HHMMSScurrentlyAt = secToHHMMSS(currentlyAt)
    const HHMMSSMediaDuration = secToHHMMSS(mediaDuration)
    if (HHMMSSMediaDuration.startsWith('00')) {
      return `${HHMMSScurrentlyAt.slice(3)} / ${HHMMSSMediaDuration.slice(3)}`
    }
    return `${HHMMSScurrentlyAt} / ${HHMMSSMediaDuration}`
  }, [currentlyAt, mediaDuration])

  return (
    <PosDurTimer sx={{ fontSize: isPortableDevice ? '12px' : '14px' }}>
      {displayTimers()}
    </PosDurTimer>
  )
}

export default PositionDuration
