import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import { IAppLanguage, TLangCodes, TLocLabels } from 'store/storeTypes'

const pickSelectedLanguage = (): string => {
  const persistedSelectLang = localStorage.getItem('selectedAppLang')
  const appLocLang = ['ro-RO', 'de-DE', 'hu-HU'].includes(window.navigator.language)
    ? window.navigator.language
    : 'en-US'
  return persistedSelectLang ?? appLocLang
}

const initialState: IAppLanguage = {
  selected: pickSelectedLanguage(),
  locLabels: {},
  langCodes: {},
  localizationDict: {},
}

export const appLanguageSlice = createSlice({
  name: 'appLanguage',
  initialState,
  reducers: {
    changeAppLanguage: (state: IAppLanguage, action: PayloadAction<string>) => {
      localStorage.setItem('selectedAppLang', action.payload)
      state.selected = action.payload
    },
    stroreLangCodes: (state: IAppLanguage, action: PayloadAction<TLangCodes>) => {
      state.langCodes = action.payload
    },
    storeLocLabels: (state: IAppLanguage, action: PayloadAction<TLocLabels>) => {
      state.locLabels = Object.keys(action.payload).reduce((labels: TLocLabels, label: string) => {
        if (labels[label]) {
          labels[label] = { ...labels[label], ...action.payload[label] }
        } else {
          labels[label] = action.payload[label]
        }
        return labels
      }, state.locLabels)
      localStorage.setItem('locLabels', JSON.stringify(state.locLabels))
    },
    storeLocalizationDict: (state: IAppLanguage, action: PayloadAction<TLocLabels>) => {
      state.localizationDict = action.payload
    },
  },
})

export const { changeAppLanguage, stroreLangCodes, storeLocLabels, storeLocalizationDict } =
  appLanguageSlice.actions
export default appLanguageSlice.reducer
