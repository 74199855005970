import { IMaxDims } from 'pages/AppFrame'
import { SyntheticEvent, createContext, useContext } from 'react'
import * as Shaka from 'shaka-player/dist/shaka-player.compiled'

export type PreviewContextType = {
  VideoRefs: React.MutableRefObject<HTMLVideoElement[]>
  selectedVideoId: string
  setSelectedVideoId: (id: string) => void
  showCPanel: (isTemporary: boolean, keyEvent?: SyntheticEvent<HTMLDivElement>) => void
  hideCPanel: () => void
  currentlyAtRef: React.MutableRefObject<number>
  isShakaInstalledOk: React.MutableRefObject<boolean>
  shakaInstances: React.MutableRefObject<Shaka.Player[]>
  maxVideoSizeForVariant: React.MutableRefObject<IMaxDims>
  subtitleRefs: React.MutableRefObject<HTMLParagraphElement[]>
  castSubtitles: (event: SyntheticEvent<HTMLTrackElement>) => void
  displaySubs: (subtitleLang: string) => void
  pressPlayPause: (toPlay: boolean, vidInd?: number) => any
  updateClipPosition: () => void
  trackPlaybackStateChange: (vidInd: number, stateChange: string, toLoad: boolean) => void
  seekTo: (toWhere: number, isPlaying?: boolean, fromContentId?: string) => void
  getReturnURL: () => string
  isPreview: React.MutableRefObject<boolean>
  initOneTrack: (tracks: any[], trInd: number, video: HTMLVideoElement) => Promise<any>
  plstPlaybackStateChange: (vidInd: number, stateChange: string, toLoad: boolean) => void
  updateCurrentTime: (vidInd: number) => void
  continueWithSetSubs: (headVidInd: number, subtLangCopy: string) => void
  setIsPressExitButton: (isPress: boolean) => void
  isFirstPlayed: boolean
  setIsFirstPlayed: (isFirstPlayed: boolean) => void
}

export const PreviewContext = createContext({} as PreviewContextType)

export const usePreviewContext = () => useContext(PreviewContext)
