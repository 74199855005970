import { useState, useEffect, useRef, useMemo } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Box,
  Button,
  Drawer,
  Input,
  List,
  ListItem,
  Typography,
  ListItemText,
  ListItemIcon,
  Slider,
} from '@mui/material'
import { ExpandMore } from '@mui/icons-material'
import { styled } from '@mui/system'
import { showLabel } from 'services/language'
import {
  chooseSubtitle,
  setSubTextColor,
  setSubBckgColor,
  setSubOpacity,
} from 'store/reducers/playback'
import { toggleAudioSubtSidebar } from 'store/reducers/componentDisplay'
import Close2 from 'assets/icons/close_2.svg'
import Tick from 'assets/icons/tick.svg'
import type { RootState } from 'store/index'

const LangListItem = styled(ListItem)({
  '& .MuiTypography-root': {
    fontSize: '18px',
  },
})
const TickMark = styled('img')({
  opacity: 0,
  transition: 'opacity 0.5s ease',
})
const AudioSubtDrawer = styled(Drawer)({
  '& .MuiDrawer-paperAnchorRight': {
    backdropFilter: 'blur(15px)',
    backgroundColor: 'rgba(40, 40, 40, 0.5)',
    borderRadius: '50px 0 0 50px',
  },
})
const AudioSubtCont = styled('div')({
  width: '40vw',
  minWidth: '420px',
  height: '100vh',
  padding: '0 20px',
})
const AudioSubtHeader = styled(Box)({
  width: '100%',
  height: '140px',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-evenly',
  alignItems: 'flex-start',
  marginTop: '10px',
  padding: '0 8px',
})
const AudioSubtBody = styled(Box)({
  width: '100%',
})
const SubtAccord = styled(Accordion)({
  marginBottom: '10px',
  color: '#ffffff',
  boxShadow: 'none',
  borderRadius: '10px',
  backgroundColor: 'transparent',
})
const SubtAccSum = styled(AccordionSummary)({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  backgroundColor: '#333333',
  borderRadius: '6px',
})
const ExpandIcon = styled(ExpandMore)({
  color: '#ffffff',
})
const SubtAccLabel = styled(Box)({
  width: '99%',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
})
const SubtAccDet = styled(AccordionDetails)({
  backgroundColor: 'transparent',
  borderRadius: '6px',
})
const CloseButton = styled(Button)({
  textTransform: 'initial',
  color: 'inherit',
  marginLeft: 0,
  paddingLeft: 0,
})
const SidebarTitle = styled(Typography)({
  marginLeft: '3px',
})
const CloseIcon = styled('img')({
  width: '25px',
  height: '25px',
  cursor: 'pointer',
})
const CloseText = styled(Typography)({
  fontSize: '16px',
  cursor: 'pointer',
})
const OpaqueSlider = styled(Slider)({
  color: '#ffffff',
  height: '3px',
  '& .MuiSlider-thumb.Mui-focusVisible, .MuiSlider-thumb:hover': {
    boxShadow: 'none!important',
  },
  '&.MuiSlider-root': {
    padding: 0,
  },
  '& .MuiSlider-rail': {
    backgroundColor: '#595959',
    opacity: 1,
  },
  '& .MuiSlider-thumb': {
    width: '14px',
    height: '14px',
  },
})
const ColorInput = styled(Input)({
  margin: 0,
  padding: 0,
  width: '45px',
  height: '45px',
  position: 'relative',
  right: '3px',
  '& .MuiInput-input': {
    width: '100%',
    height: '100%',
    '-webkit-appearance': 'none',
    '-moz-appearance': 'none',
    appearance: 'none',
    '::-webkit-color-swatch': {
      border: 'none',
      borderRadius: '50%',
    },
    '::-moz-color-swatch': {
      border: 'none',
      borderRadius: '50%',
    },
    margin: 0,
    padding: 0,
    cursor: 'pointer',
  },
})

function AudioSubtSidebar() {
  const isAudioSubtPanelOpen = useSelector(
    (state: RootState) => state.componentDisplay.isAudioSubtPanelOpen,
  )
  const dispatch = useDispatch()

  return (
    <AudioSubtDrawer
      anchor="right"
      open={isAudioSubtPanelOpen}
      onClose={() => dispatch(toggleAudioSubtSidebar(false))}
    >
      <AudioSubtCont>
        <AudioSubtHeader>
          <CloseButton variant="text">
            <CloseIcon
              src={Close2}
              alt="Close icon of the Audio & Subtitle selection modal"
              onPointerDown={() => dispatch(toggleAudioSubtSidebar(false))}
            />
            <CloseText
              variant="caption"
              onPointerDown={() => dispatch(toggleAudioSubtSidebar(false))}
            >
              {showLabel('lms_close')}
            </CloseText>
          </CloseButton>

          <SidebarTitle variant="h4">{showLabel('lms_audio_subtitle')}</SidebarTitle>
        </AudioSubtHeader>

        <AudioSubtBody>
          <SubtLanguage />
          <SubtTextColor />
          <SubtBckgColor />
          <OpacitySlider />
        </AudioSubtBody>
      </AudioSubtCont>
    </AudioSubtDrawer>
  )
}

const SubtLanguage = () => {
  const mediaTracks = useSelector((state: RootState) => state.learningObject.mediaTracks)
  const contentType = useSelector((state: RootState) => state.learningObject.type)
  const headVideoIndex = useSelector((state: RootState) => state.componentDisplay.headVideoIndex)
  const subtitleLang = useSelector((state: RootState) => state.playback.subtitleLang)
  const dispatch = useDispatch()

  const displaySubtitleLabel = (subtCode: string) =>
    subtCode === 'Off' ? showLabel('lms_off') : showLabel(`lms_lang_${subtCode}`)

  const subtitlesList = useMemo(() => {
    const vIndex: number = ['Remix', 'RemixV2'].includes(contentType)
      ? mediaTracks?.findIndex((track: any) => track.contentTrack) || 0
      : headVideoIndex

    const subtitles: any[] = [].concat(mediaTracks?.[vIndex]?.subtitles)
    const findOffParam = subtitles?.find((sLang: any) => sLang.locale === 'Off')

    if (!findOffParam && subtitles?.length) {
      const offSubtitle = {
        locale: 'Off',
        label: 'SUBTITLE:Off',
        url: null,
      }

      subtitles?.unshift(offSubtitle)
    }

    return subtitles
  }, [])

  return (
    <>
      {subtitlesList?.length ? (
        <SubtAccord disableGutters>
          <SubtAccSum
            expandIcon={<ExpandIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <SubtAccLabel>
              <Typography>{showLabel('lms_subtitle_language')}</Typography>
              <Typography>{displaySubtitleLabel(subtitleLang)}</Typography>
            </SubtAccLabel>
          </SubtAccSum>

          <SubtAccDet>
            <List>
              {subtitlesList?.map((sLang: any, index: number) => (
                <LangListItem
                  key={`subtitle_${sLang.locale}_${index}`}
                  onPointerDown={() => dispatch(chooseSubtitle(sLang.locale))}
                  disableGutters
                  disablePadding
                >
                  <ListItemText
                    sx={{
                      color: `${subtitleLang === sLang.locale ? '#ffffff' : '#737373'}`,
                      cursor: 'pointer',
                    }}
                  >
                    {displaySubtitleLabel(sLang.locale)}
                  </ListItemText>
                  <ListItemIcon>
                    <TickMark
                      src={Tick}
                      alt="Tick mark icon"
                      sx={{ opacity: `${subtitleLang === sLang.locale ? 1 : 0}` }}
                    />
                  </ListItemIcon>
                </LangListItem>
              ))}
            </List>
          </SubtAccDet>
        </SubtAccord>
      ) : null}
    </>
  )
}

interface IColorMap {
  [key: string]: string
}
const ColorMap: IColorMap = {
  '#ffffff': 'white',
  '#000000': 'black',
  '#990000': 'red',
}

const SubtTextColor = () => {
  const subTextColor = useSelector((state: RootState) => state.playback.subTextColor)
  const dispatch = useDispatch()

  const isActive = !Object.keys(ColorMap).includes(subTextColor)

  return (
    <SubtAccord disableGutters>
      <SubtAccSum expandIcon={<ExpandIcon />} aria-controls="panel2a-content" id="panel2a-header">
        <SubtAccLabel>
          <Typography>{showLabel('lms_subtitle_color')}</Typography>
          <Typography sx={{ color: subTextColor }}>
            {showLabel(
              ColorMap[subTextColor] ? `lms_color_${ColorMap[subTextColor]}` : 'lms_color_custom',
            )}
          </Typography>
        </SubtAccLabel>
      </SubtAccSum>

      <SubtAccDet>
        <List>
          {Object.keys(ColorMap).map((textColor: string, index: number) => (
            <LangListItem
              key={`subtitle_${textColor}_${index}`}
              onPointerDown={() => dispatch(setSubTextColor(textColor))}
              disableGutters
              disablePadding
            >
              <ListItemText
                sx={{
                  color: `${subTextColor === textColor ? '#ffffff' : '#737373'}`,
                  cursor: 'pointer',
                }}
              >
                {showLabel(`lms_color_${ColorMap[textColor]}`)}
              </ListItemText>
              <ListItemIcon>
                <TickMark
                  src={Tick}
                  alt="Tick mark icon"
                  sx={{ opacity: `${subTextColor === textColor ? 1 : 0}` }}
                />
              </ListItemIcon>
            </LangListItem>
          ))}
          <LangListItem disableGutters disablePadding>
            <ListItemText>
              <ColorPicker
                isActive={isActive}
                pickedColor={subTextColor}
                storePickedColor={setSubTextColor}
              />
            </ListItemText>
            <ListItemIcon>
              <TickMark src={Tick} alt="Tick mark icon" sx={{ opacity: isActive ? 1 : 0 }} />
            </ListItemIcon>
          </LangListItem>
        </List>
      </SubtAccDet>
    </SubtAccord>
  )
}

const SubtBckgColor = () => {
  const subBckgColor = useSelector((state: RootState) => state.playback.subBckgColor)
  const dispatch = useDispatch()

  const isActive = !Object.keys(ColorMap).includes(subBckgColor)

  return (
    <SubtAccord disableGutters>
      <SubtAccSum expandIcon={<ExpandIcon />} aria-controls="panel3a-content" id="panel3a-header">
        <SubtAccLabel>
          <Typography>{showLabel('lms_subtitle_bckgColor')}</Typography>
          <Typography sx={{ color: subBckgColor }}>
            {showLabel(
              ColorMap[subBckgColor] ? `lms_color_${ColorMap[subBckgColor]}` : 'lms_color_custom',
            )}
          </Typography>
        </SubtAccLabel>
      </SubtAccSum>

      <SubtAccDet>
        <List>
          {Object.keys(ColorMap).map((bckgColor: string, index: number) => (
            <LangListItem
              key={`subtitle_${bckgColor}_${index}`}
              onPointerDown={() => dispatch(setSubBckgColor(bckgColor))}
              disableGutters
              disablePadding
            >
              <ListItemText
                sx={{
                  color: `${subBckgColor === bckgColor ? '#ffffff' : '#737373'}`,
                  cursor: 'pointer',
                }}
              >
                {showLabel(`lms_color_${ColorMap[bckgColor]}`)}
              </ListItemText>
              <ListItemIcon>
                <TickMark
                  src={Tick}
                  alt="Tick mark icon"
                  sx={{ opacity: `${subBckgColor === bckgColor ? 1 : 0}` }}
                />
              </ListItemIcon>
            </LangListItem>
          ))}
          <LangListItem disableGutters disablePadding>
            <ListItemText>
              <ColorPicker
                isActive={isActive}
                pickedColor={subBckgColor}
                storePickedColor={setSubBckgColor}
              />
            </ListItemText>
            <ListItemIcon>
              <TickMark src={Tick} alt="Tick mark icon" sx={{ opacity: isActive ? 1 : 0 }} />
            </ListItemIcon>
          </LangListItem>
        </List>
      </SubtAccDet>
    </SubtAccord>
  )
}

const OpacitySlider = () => {
  const subOpacity = useSelector((state: RootState) => state.playback.subOpacity)
  const dispatch = useDispatch()

  return (
    <SubtAccord disableGutters>
      <SubtAccSum expandIcon={<ExpandIcon />} aria-controls="panel4a-content" id="panel4a-header">
        <SubtAccLabel>
          <Typography>{showLabel('lms_subtitle_opacity')}</Typography>
          <Typography>{typeof subOpacity === 'string' ? 0 : `${subOpacity}%`}</Typography>
        </SubtAccLabel>
      </SubtAccSum>

      <SubtAccDet sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <Input
          value={subOpacity}
          margin="dense"
          onChange={(event) => {
            const opacNum = parseInt(event.target.value, 10)
            if (event.target.value === '') {
              dispatch(setSubOpacity(''))
            } else if (!Number.isNaN(opacNum)) {
              if (opacNum >= 0 && opacNum <= 100) dispatch(setSubOpacity(opacNum))
            }
          }}
          inputProps={{
            step: 1,
            min: 0,
            max: 100,
            type: 'number',
            'aria-labelledby': 'input-slider',
          }}
          sx={{ marginRight: '12px' }}
        />

        <OpaqueSlider
          value={typeof subOpacity === 'string' ? 0 : subOpacity}
          aria-labelledby="discrete-slider"
          valueLabelDisplay="off"
          step={1}
          min={0}
          max={100}
          onChange={(_, newValue) => {
            if (typeof newValue === 'number') dispatch(setSubOpacity(newValue))
          }}
        />
      </SubtAccDet>
    </SubtAccord>
  )
}

const ColorPicker = (props: any) => {
  const { isActive, pickedColor, storePickedColor } = props
  const dispatch = useDispatch()
  const colorSelectDelay = useRef<number>(0)

  const [tempColor, setTempColor] = useState<string>(pickedColor)

  useEffect(() => {
    if (!isActive) setTempColor(pickedColor)
  }, [pickedColor])

  return (
    <ColorInput
      type="color"
      value={tempColor}
      onChange={(event) => {
        setTempColor(event.target.value)
        window.clearTimeout(colorSelectDelay.current)
        colorSelectDelay.current = window.setTimeout(() => {
          dispatch(storePickedColor(event.target.value))
        }, 100)
      }}
      disableUnderline
    />
  )
}

export default AudioSubtSidebar
