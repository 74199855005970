import { useRef, useMemo } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Slider } from '@mui/material'
import { styled } from '@mui/system'
import { userSeek } from 'store/reducers/playback'
import type { RootState } from 'store/index'
import { secToHHMMSS } from 'utils/dateTimeConfig'

const ProgressRange = styled(Slider)({
  color: '#7bc0da',
  height: '6px',
  '& .MuiSlider-thumb.Mui-focusVisible, .MuiSlider-thumb:hover': {
    boxShadow: 'none!important',
  },
  '&.MuiSlider-root': {
    padding: 0,
  },
  '& .MuiSlider-rail': {
    backgroundColor: '#595959',
    opacity: 1,
  },
  '& .MuiSlider-thumb': {
    width: '14px',
    height: '14px',
    backgroundColor: '#fff',
  },
})

function DtopProgBar(props: any) {
  const { showCPanel, seekTo, progressAt, setProgressAt, isMobile } = props

  const currentlyAt = useSelector((state: RootState) => state.playback.currentlyAt)
  const mediaDuration = useSelector((state: RootState) => state.playback.mediaDuration)
  const isPlaying = useSelector((state: RootState) => state.playback.isPlaying)
  const isUserSeeking = useSelector((state: RootState) => state.playback.isUserSeeking)

  const dispatch = useDispatch()

  const progressTimeRef = useRef<HTMLSpanElement>(null)

  const startMoveProgress = (event: any) => {
    event.stopPropagation()
    showCPanel(false)
    if (progressTimeRef?.current?.style) {
      progressTimeRef.current.style.opacity = '1'
    }
    dispatch(userSeek(true))
  }

  const moveProgress = (event: Event, value: number | number[]) => {
    event.stopPropagation()
    if (typeof value === 'number') {
      if (progressTimeRef?.current?.style) {
        progressTimeRef.current.style.left = `clamp(
          -5px,
          calc(${(value / mediaDuration) * 100}% - 30px),
          ${progressTimeRef.current.parentElement!.getBoundingClientRect().width - 55}px
        )`
      }
      setProgressAt(value)
    }
  }

  const stopMoveProgress = () => {
    if (isMobile) {
      setTimeout(() => {
        dispatch(userSeek(false))
      }, 3000)
    } else {
      dispatch(userSeek(false))
    }

    seekTo(progressAt, isPlaying)
    if (progressTimeRef?.current?.style) {
      progressTimeRef.current.style.opacity = '0'
    }

    if (isPlaying) showCPanel(true)
  }

  const actualProgress = useMemo(() => {
    return isUserSeeking ? progressAt : currentlyAt
  }, [isUserSeeking, progressAt, currentlyAt])

  return (
    <ProgressRange
      size="small"
      defaultValue={70}
      aria-label="Small"
      min={0}
      max={mediaDuration || 0}
      step={1}
      value={actualProgress}
      valueLabelFormat={secToHHMMSS(actualProgress)}
      valueLabelDisplay="auto"
      onPointerDown={startMoveProgress}
      onChange={moveProgress}
      onChangeCommitted={stopMoveProgress}
      style={{
        minWidth: isMobile ? '130px' : 'auto',
      }}
    />
  )
}

export default DtopProgBar
