import { useState, useEffect, RefObject, FC, SetStateAction, Dispatch } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { AxiosError, AxiosResponse } from 'axios'
import { styled } from '@mui/system'
import {
  Box,
  Button,
  Dialog,
  DialogTitle,
  Fade,
  List,
  ListItem,
  TextField,
  Typography,
} from '@mui/material'
import { Delete } from '@mui/icons-material'
import { useMediaQuery } from 'react-responsive'
import { newBookmark, editBookmark, writeBookmark, storeBookmarks } from 'store/reducers/notes'
import Bookmarks, { TNote } from 'services/api/bookmarks'
import AlertMsg from 'services/alertMsg'
import { showLabel } from 'services/language'
import { secToHHMMSS } from 'utils/dateTimeConfig'
import DotDotDotSrc from 'assets/icons/dotDotDot.svg'
import PencilSrc from 'assets/icons/pencil.svg'
import Close from 'assets/icons/close.svg'
import XSrc from 'assets/icons/x.svg'
import type { RootState } from 'store/index'

const NotesSection = styled('section')({
  width: '100%',
  margin: 0,
  padding: '12px',
})
const AddNoteButton = styled(Button)({
  height: '100%',
  border: '2px solid #0497BB',
  backgroundColor: 'transparent',
  borderRadius: '10px',
  padding: '10px',
  color: '#0497BB',
  fontSize: '13px',
  letterSpacing: '1.5px',
  textAlign: 'center',
  textTransform: 'initial',
  '&:hover': {
    border: '2px solid #0497BB',
  },
})
const NoteTop = styled('div')({
  width: '100%',
})
const NoteBottom = styled('div')({
  width: '100%',
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'flex-end',
  padding: '20px 15px',
})
const ClearButton = styled(Button)({
  height: '44px',
  width: '150px',
  backgroundColor: 'transparent',
  border: 'none',
  borderRadius: '8px',
  color: 'inherit',
  fontSize: '13px',
  letterSpacing: '1px',
  textTransform: 'capitalize',
  marginBottom: '50px',
})
const SaveButton = styled(Button)({
  height: '44px',
  width: '150px',
  backgroundColor: '#1090B1',
  border: 'none',
  borderRadius: '8px',
  color: 'inherit',
  fontSize: '13px',
  letterSpacing: '1px',
  textTransform: 'capitalize',
  marginBottom: '50px',
  '&:hover': {
    backgroundColor: '#1090B1',
  },
})
const ListItemTitle = styled('div')({
  width: '100%',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  marginBottom: '3px',
  fontSize: '14px',
})
const TitleBox = styled(Box)({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'flex-start',
  alignItems: 'center',
  cursor: 'pointer',
})
const NoteTitle = styled('h4')({
  margin: '4px 10px',
  padding: 0,
  fontSize: '14px',
})
const NoteAt = styled('span')({
  cursor: 'pointer',
  backgroundColor: 'rgba(255, 255, 255, 0.7)',
  color: 'rgba(48, 47, 47, 0.84)',
  borderRadius: '6px',
  textAlign: 'center',
  fontSize: '14px',
  fontWeight: 500,
  lineHeight: '22px',
  letterSpacing: '0.46px',
  minWidth: '75px',
  padding: '1px 0',
})
const ListElem = styled(ListItem)({
  width: '100%',
  padding: '10px 0',
})
const ListBox = styled(Box)({
  width: '100%',
})
const ListItemNote = styled('div')({
  fontSize: '13px',
  width: '100%',
})
const DotDotDotIcon = styled('img')({
  width: '16px',
  height: '30px',
})
const EditButtons = styled('div')({
  height: '30px',
  color: '#ffffff',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'flex-end',
  alignItems: 'center',
})
const EditNoteButton = styled(Button)({
  maxWidth: '42px',
  minWidth: 0,
  height: '100%',
  margin: '0 6px 0 0',
  padding: 0,
  border: 'none',
  color: 'inherit',
  fontSize: '12px',
  textTransform: 'initial',
})
const PencilIcon = styled('img')({
  width: '20px',
  height: '20px',
})
const RemoveNoteButton = styled(Button)({
  maxWidth: '66px',
  minWidth: 0,
  height: '100%',
  margin: '0 6px 0 0',
  padding: 0,
  border: 'none',
  color: 'inherit',
  fontSize: '12px',
  textTransform: 'initial',
})
const TrashIcon = styled(Delete)({
  width: '15px',
  height: '15px',
  marginLeft: '2px',
})
const CancelNoteButton = styled(Button)({
  maxWidth: '65px',
  minWidth: 0,
  height: '100%',
  margin: 0,
  padding: 0,
  border: 'none',
  color: 'inherit',
  fontSize: '12px',
  textTransform: 'initial',
})
const XIcon = styled('img')({
  width: '13px',
  height: '13px',
  marginLeft: '3px',
})
const NoteDialog = styled(Dialog)({
  '& .MuiDialog-paper': {
    backdropFilter: 'blur(15px)',
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
  },
})
const NoteDialogTitle = styled(DialogTitle)({
  margin: 0,
  padding: '15px 15px',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
})
const TitleText = styled(Typography)({
  fontSize: '18px',
  marginTop: '30px',
})
const TitleBarIcon = styled('img')({
  width: '26px',
  height: '26px',
  marginLeft: '5px',
})
const NoteDialogBody = styled('div')({
  margin: 0,
  padding: '10px 15px',
})
const AddNoteSection = styled('section')({
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
})
const NoteField = styled(TextField)({
  backgroundColor: 'rgba(255, 255, 255, 0.2)',
  borderRadius: '8px',
  '& .Mui-focused .MuiOutlinedInput-notchedOutline': {
    border: 'none',
  },
  '&:hover .MuiOutlinedInput-notchedOutline': {
    border: 'none',
  },
})
const LetterCounter = styled('span')({
  width: '100%',
  fontSize: '11px',
  color: '#808080',
  textAlign: 'right',
})

function Notes(props: any) {
  const { seekTo, currentlyAtRef } = props

  const [dotsOpenAt, setDotsOpenAt] = useState<number | null>(null)
  const [isNoteDialogOpen, setIsNoteDialogOpen] = useState<boolean>(false)
  const [isNewNote, setIsNewNote] = useState<boolean>(false)

  const learnObjItem = useSelector((state: RootState) => state.learningObject.item)
  const bookmarks = useSelector((state: RootState) => state.notes.bookmarks)
  const isPlaying = useSelector((state: RootState) => state.playback.isPlaying)

  const dispatch = useDispatch()

  const isNarrowWidth = useMediaQuery({ query: '(max-width: 450px)' })

  const addNewNote = (index: number) => {
    setIsNewNote(true)
    dispatch(newBookmark(index))
    setIsNoteDialogOpen(true)
  }

  const openNote = (index: number) => {
    setIsNewNote(false)
    dispatch(editBookmark(index))
    setIsNoteDialogOpen(true)
  }

  const closeNote = () => {
    setIsNoteDialogOpen(false)
    dispatch(writeBookmark(null))
  }

  const calcNoteAt = (noteAt: number) => {
    const time = secToHHMMSS(noteAt)
    return time.startsWith('00') ? time.slice(3) : time
  }

  const displayEditButtons = (index: number) => (
    <EditButtons>
      <EditNoteButton variant="text" onPointerDown={() => openNote(index)}>
        {showLabel('lms_edit')}
        <PencilIcon src={PencilSrc} />
      </EditNoteButton>

      <RemoveNoteButton variant="text" onPointerDown={() => removeNote(index)}>
        {showLabel('lms_remove')}
        <TrashIcon />
      </RemoveNoteButton>

      <CancelNoteButton variant="text" onPointerDown={() => setDotsOpenAt(null)}>
        {showLabel('lms_cancel')}
        <XIcon src={XSrc} />
      </CancelNoteButton>
    </EditButtons>
  )

  const removeNote = (index: number) => {
    Bookmarks.deleteNote(bookmarks[index].id)
      //.then(() => Discussion.getOneComment(parentId || commentId))
      .then(() => {
        dispatch(storeBookmarks(bookmarks.filter((_, i: number) => i !== index)))
        AlertMsg.show({
          type: 'success',
          message: 'Your note is deleted!',
        })
      })
      .catch((error: AxiosError) => {
        AlertMsg.show({
          type: 'error',
          message: error,
          context: "Can't delete your note → ",
        })
      })
      .finally(() => setDotsOpenAt(null))
  }

  useEffect(() => {
    if (learnObjItem) {
      Bookmarks.listNotes(learnObjItem.id)
        .then((response: AxiosResponse) => response.data && dispatch(storeBookmarks(response.data)))
        .catch((error: AxiosError) => {
          AlertMsg.show({
            type: 'error',
            message: error,
            context: "Can't load the related discussions → ",
          })
        })
    }
  }, [])

  return (
    <NotesSection>
      <AddNoteButton
        variant="outlined"
        onPointerDown={() => addNewNote(bookmarks.length)}
        sx={{ width: `${isNarrowWidth ? '100%' : '250px'}` }}
      >
        {showLabel('lms_leave_note')}
      </AddNoteButton>

      <List sx={{ width: '100%' }}>
        {bookmarks.map((note: any, index: number) => (
          <ListElem key={`${note.title}_${index}`}>
            <ListBox>
              <ListItemTitle>
                <TitleBox>
                  <NoteAt onPointerDown={() => seekTo(note.position, isPlaying)}>
                    {calcNoteAt(note.position)}
                  </NoteAt>

                  <NoteTitle>
                    <span>{note.title}</span>
                  </NoteTitle>
                </TitleBox>

                {dotsOpenAt === index ? (
                  <Fade in={dotsOpenAt === index} timeout={500}>
                    {displayEditButtons(index)}
                  </Fade>
                ) : (
                  <DotDotDotIcon src={DotDotDotSrc} onPointerDown={() => setDotsOpenAt(index)} />
                )}
              </ListItemTitle>

              <ListItemNote>{note.description}</ListItemNote>
            </ListBox>
          </ListElem>
        ))}
      </List>

      <NoteDialog fullScreen fullWidth open={isNoteDialogOpen} onClose={() => closeNote()}>
        <NoteForm
          isNarrowWidth={isNarrowWidth}
          isNewNote={isNewNote}
          closeNote={closeNote}
          setDotsOpenAt={setDotsOpenAt}
          currentlyAtRef={currentlyAtRef}
        />
      </NoteDialog>
    </NotesSection>
  )
}

interface NoteFormPropTypes {
  isNarrowWidth: boolean
  isNewNote: boolean
  closeNote(): void
  setDotsOpenAt: Dispatch<SetStateAction<number | null>>
  currentlyAtRef: RefObject<number>
}
const NoteForm: FC<NoteFormPropTypes> = (props: NoteFormPropTypes) => {
  const { isNarrowWidth, isNewNote, closeNote, setDotsOpenAt, currentlyAtRef } = props

  const [note, setNote] = useState({
    id: '',
    index: null,
    title: '',
    description: '',
    position: 0,
    contentId: '',
    providerId: '',
  })

  const learnObjItem = useSelector((state: RootState) => state.learningObject.item)
  const bookmarks = useSelector((state: RootState) => state.notes.bookmarks)
  const bookmarkBeingEdited = useSelector((state: RootState) => state.notes.bookmarkBeingEdited)

  const dispatch = useDispatch()

  const calcLettersLeft = (): string => {
    const lettersLeftNumb = 420 - (note?.description?.length || 0)
    const lettersLeftText =
      lettersLeftNumb > 1
        ? showLabel('lms_characters_remaining')
        : showLabel('lms_character_remaining')
    return `${lettersLeftNumb} ${lettersLeftText}`
  }

  const clearNote = () => {
    setNote((note) => ({ ...note, title: '', description: '' }))
  }

  const saveNote = () => {
    if (note.id === '') {
      Bookmarks.addNote({
        title: note.title,
        description: note.description,
        position: currentlyAtRef.current as number,
        contentId: learnObjItem!.id,
        providerId: note.providerId,
      })
        .then((response: AxiosResponse) => {
          dispatch(
            storeBookmarks(
              [...bookmarks, response.data].sort(
                (bkmrk1: any, bkmrk2: any) => bkmrk1.position - bkmrk2.position,
              ),
            ),
          )
          AlertMsg.show({
            type: 'success',
            message: 'Your note is submitted!',
          })
        })
        .catch((error: AxiosError) => {
          AlertMsg.show({
            type: 'error',
            message: error,
            context: "Can't save your note → ",
          })
        })
        .finally(() => {
          closeNote()
          setDotsOpenAt(null)
        })
    } else {
      Bookmarks.updateNote(note.id, note)
        .then((response: AxiosResponse) => {
          dispatch(
            storeBookmarks(
              bookmarks
                .map((bookmark: TNote, i: number) => {
                  if (i === note.index) return response.data
                  return bookmark
                })
                .sort((bkmrk1: any, bkmrk2: any) => bkmrk1.position - bkmrk2.position),
            ),
          )
          AlertMsg.show({
            type: 'success',
            message: 'Your note is updated!',
          })
        })
        .catch((error: AxiosError) => {
          AlertMsg.show({
            type: 'error',
            message: error,
            context: "Can't update your note → ",
          })
        })
        .finally(() => {
          closeNote()
          setDotsOpenAt(null)
        })
    }
  }

  useEffect(() => {
    if (!isNewNote) {
      setNote(() => ({ ...bookmarkBeingEdited, index: bookmarkBeingEdited.noteIndex }))
    }
  }, [isNewNote])

  return (
    <>
      <NoteTop>
        <NoteDialogTitle>
          <TitleText>
            {isNewNote ? showLabel('lms_add_note') : showLabel('lms_edit_note')}
          </TitleText>
          <TitleBarIcon
            src={Close}
            alt="Close icon of the Note modal"
            onPointerDown={() => closeNote()}
          />
        </NoteDialogTitle>

        <NoteDialogBody>
          <AddNoteSection>
            <NoteField
              placeholder={showLabel('lms_enter_title')}
              value={note?.title}
              variant="outlined"
              type="text"
              margin="normal"
              fullWidth
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                setNote((note) => ({ ...note, title: (event.target as HTMLInputElement).value }))
              }}
            />

            <NoteField
              placeholder={showLabel('lms_note_tooltip')}
              value={note?.description}
              variant="outlined"
              type="text"
              margin="normal"
              multiline
              rows={10}
              inputProps={{ maxLength: 420 }}
              fullWidth
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                setNote((note) => ({
                  ...note,
                  description: (event.target as HTMLInputElement).value,
                }))
              }}
            />
            <LetterCounter>{calcLettersLeft()}</LetterCounter>
          </AddNoteSection>
        </NoteDialogBody>
      </NoteTop>

      <NoteBottom sx={{ justifyContent: `${isNarrowWidth ? 'space-between' : 'center'}` }}>
        <ClearButton variant="text" onPointerDown={clearNote}>
          {showLabel('lms_clear')}
        </ClearButton>

        <SaveButton variant="contained" onPointerDown={saveNote}>
          {showLabel('lms_save')}
        </SaveButton>
      </NoteBottom>
    </>
  )
}

export default Notes
