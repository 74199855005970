import { useState, useEffect, useCallback, MouseEvent, SyntheticEvent, useRef } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import * as Shaka from 'shaka-player/dist/shaka-player.compiled'
import screenfull from 'screenfull'
import { styled } from '@mui/system'
import { v4 as uuidv4 } from 'uuid'

import { Box, Button, Fade, List, ListItemButton, Popover } from '@mui/material'
import {
  Fullscreen as FullscreenIcon,
  FullscreenExit as FullscreenExitIcon,
} from '@mui/icons-material'
import AudioSubtSidebar from '../AudioSubtSidebar'
import BouncingLoader from 'components/BouncingLoader'
import PositionDuration from 'components/PositionDuration'
import SpentTimeDisplay from 'components/SpentTimeDisplay'
import PlayPauseButton from 'components/PlayPauseButton'
import SubtitleOverlay from 'components/SubtitleOverlay'
import { UAParser } from 'ua-parser-js'
import {
  setHeadVideoId,
  setFullScreenVideoElem,
  showAbortModal,
  toggleAudioSubtSidebar,
} from 'store/reducers/componentDisplay'
import {
  changePlaybackSpeed,
  signalContentEnded,
  setVolumeLevel,
  setIsBuffering,
} from 'store/reducers/playback'
import ProgressBar from '../PortProgBar'
import AlertMsg from 'services/alertMsg'
import Tick from 'assets/icons/tick.svg'
import SubAud from 'assets/icons/subAud.svg'
import VideoSwitch from 'assets/icons/videoSwitch.svg'
import type { RootState } from 'store/index'
import { IMediaTrack } from 'store/storeTypes'
import { playerConfiguration } from 'constants/videoPlayer'
import VolumeComponent from 'pages/Desktop/MainPanel/DeskVideos/components/VolumeComponent'
import { PlayerControlsMobile } from 'pages/Desktop/MainPanel/DeskVideos/components/PlayerControls'

const SHORTCUTS = [
  'Enter',
  'Space',
  'KeyF',
  'KeyM',
  'ArrowUp',
  'ArrowDown',
  'ArrowRight',
  'ArrowLeft',
]

function RemixPortVids(props: any) {
  const {
    VideoRefs,
    selectedVideoId,
    setSelectedVideoId,
    headVideoContainerRef,
    videosContainerRef,
    videoBoxRefs,
    showCPanel,
    currentlyAtRef,
    isShakaInstalledOk,
    shakaInstances,
    maxVideoSizeForVariant,
    subtitleRefs,
    displaySubs,
    pressPlayPause,
    updateClipPosition,
    trackPlaybackStateChange,
    switchView,
    seekTo,
  } = props

  const [anchorElem, setAnchorElem] = useState<null | HTMLElement>(null)

  const learnObjItem = useSelector((state: RootState) => state.learningObject.item)
  const contentType = useSelector((state: RootState) => state.learningObject.type)
  const mediaTracks: IMediaTrack[] = useSelector(
    (state: RootState) => state.learningObject.mediaTracks,
  )
  const isPlaying = useSelector((state: RootState) => state.playback.isPlaying)
  const subtitleLang = useSelector((state: RootState) => state.playback.subtitleLang)
  const playbackSpeed = useSelector((state: RootState) => state.playback.playbackSpeed)
  const isUserSeeking = useSelector((state: RootState) => state.playback.isUserSeeking)
  const pbSpeeds = useSelector((state: RootState) => state.playback.pbSpeeds)
  const justOneTrack = useSelector((state: RootState) => state.componentDisplay.justOneTrack)
  const headVideoId = useSelector((state: RootState) => state.componentDisplay.headVideoId)
  const fullScreenVideoElem = useSelector(
    (state: RootState) => state.componentDisplay.fullScreenVideoElem,
  )
  const isVideoLoading = useSelector((state: RootState) => state.componentDisplay.isVideoLoading)
  const isVidControlPanelOn = useSelector(
    (state: RootState) => state.componentDisplay.isVidControlPanelOn,
  )
  const isInSwitchMode = useSelector((state: RootState) => state.componentDisplay.isInSwitchMode)
  const isPortraitView = useSelector((state: RootState) => state.componentDisplay.isPortraitView)
  const screenWidth = useSelector((state: RootState) => state.componentDisplay.screenWidth)
  const screenHeight = useSelector((state: RootState) => state.componentDisplay.screenHeight)
  const blackouts = useSelector((state: RootState) => state.componentDisplay.blackouts)
  const hiddens = useSelector((state: RootState) => state.componentDisplay.hiddens)
  const isAbortModal = useSelector((state: RootState) => state.componentDisplay.isAbortModal)
  const browser = useSelector((state: RootState) => state.deviceInfo.browser)

  const currentlyAt = useSelector((state: RootState) => state.playback.currentlyAt)
  const isMobile = new UAParser().getResult().device.type === 'mobile'

  const volumeMem = useRef<number>(0)
  const volumeLevel = useSelector((state: RootState) => state.playback.volumeLevel)

  const [progressAt, setProgressAt] = useState<number>(0)
  const [bufferingState, setBufferingState] = useState({})

  const noContentTracks = mediaTracks?.filter((track) => !track.contentTrack)

  const videoAspect = 1.778

  const dispatch = useDispatch()

  const makeHeadVideoContainerStyles = useCallback(() => {
    const switchModeStyles = {
      width: '66%',
      height: `${screenHeight - 90}px`,
      display: 'inline-block',
    }
    const standardModeStyles = {
      display: 'none',
    }
    return isInSwitchMode ? switchModeStyles : standardModeStyles
  }, [screenHeight, isInSwitchMode])

  const makeVideosContainerStyles = useCallback(() => {
    const switchModeStyles = {
      height: `${screenHeight - 100}px`,
      width: '33%',
      flexDirection: 'column',
      overflowX: 'hidden',
      overflowY: 'auto',
      whiteSpace: 'nowrap',
    }
    const standardModeStyles = {
      width: '100%',
      height: isPortraitView ? '29vh' : `${screenHeight}px`,
      overflowX: 'auto',
      overflowY: 'hidden',
      scrollSnapType: 'x mandatory',
      scrollSnapStop: 'always',
      whiteSpace: 'nowrap',
      flexDirection: 'row',
    }
    return isInSwitchMode ? switchModeStyles : standardModeStyles
  }, [isInSwitchMode, isPortraitView, screenHeight])

  const makeVideoBoxStyles = useCallback(
    (vidInd: number, videoId: string, isContentTrack: boolean) => {
      let height = ''
      let minHeight = ''
      let scrollSnapAlign = undefined
      const isHeadVideo = headVideoId === videoId

      if (isInSwitchMode) {
        height = '50%'
        minHeight = '50%'

        const noHiddens: number = hiddens.reduce(
          (hids: number, hid: boolean) => hids + (hid ? 1 : 0),
          0,
        )
        const fewerThanThree =
          noContentTracks?.length < 3 || noContentTracks?.length - noHiddens < 3
        if (isHeadVideo || fewerThanThree) {
          height = '100%'
          minHeight = '100%'
        }
      } else {
        height = isPortraitView ? `calc(100vw / ${videoAspect})` : '100%'
        minHeight = isPortraitView ? `calc(100vw / ${videoAspect})` : '100%'
        scrollSnapAlign = 'center'
      }

      const hiddensDisplayStyle = hiddens[vidInd] ? 'none' : 'inline-block'

      return {
        display: isContentTrack ? 'none' : hiddensDisplayStyle,
        order: isHeadVideo ? 0 : vidInd + 1,
        height,
        minHeight,
        scrollSnapAlign,
      }
    },
    [isInSwitchMode, headVideoId, hiddens, noContentTracks.length, isPortraitView],
  )

  const handleShakaError = (event: any): void => {
    AlertMsg.show({
      type: 'error',
      message: event?.detail?.code,
      context: 'Shaka Player error → ',
    })
  }

  const openSpPicker = (event: MouseEvent<HTMLButtonElement>): void => {
    setAnchorElem(event.currentTarget)
  }
  const closeSpPicker = () => setAnchorElem(null)
  const isSpeedPopUp = !!anchorElem
  const popoverId = isSpeedPopUp ? 'playback-speed-selector-popover' : undefined

  const handleSpeedPick = (event: SyntheticEvent<HTMLDivElement>): void => {
    const pbSpeed = parseFloat((event.target as HTMLDivElement).textContent as string)
    dispatch(changePlaybackSpeed(pbSpeed))

    VideoRefs.current.forEach((video: HTMLVideoElement) => {
      video.playbackRate = pbSpeed
    })
  }

  const openFullscreen = (elem: any) => {
    //# for most browsers
    if (elem.requestFullscreen) {
      elem?.requestFullscreen()
    }

    //# for Safari (older versions)
    else if (elem?.webkitRequestFullscreen) {
      elem?.webkitRequestFullscreen()
    }

    //# for Safari (newer versions)
    else if (elem?.webkitEnterFullscreen) {
      elem?.webkitEnterFullscreen()
    }

    //# for Safari iPhone (where only the Video tag itself can be fullscreen)
    else if (elem?.children[0]?.webkitEnterFullscreen) {
      elem?.children[0]?.webkitEnterFullscreen()
    }

    //# for Internet Explorer 11
    else if (elem.msRequestFullscreen) {
      elem?.msRequestFullscreen()
    }
  }

  const toggleFullScreen = () => {
    const element = videoBoxRefs.current[0]

    if (element) {
      openFullscreen(videoBoxRefs.current[0])
    } else {
      AlertMsg.show({
        type: 'warning',
        message: 'Not find first video element!',
      })
    }
  }

  const checkVideoBuffering = (player: any, i: number) => {
    // check video buffering before first play (loading event)
    const eventManager = new Shaka.util.EventManager()
    eventManager.listen(player, 'buffering', (event: any) => {
      handlePlayerLoaded({ [i]: event?.buffering })
    })
  }

  // check buffering of all video
  useEffect(() => {
    const isAllVideoSync = VideoRefs.current.every(
      (video: HTMLVideoElement) => video.currentTime === currentlyAt,
    )

    const isBuffered = Object.values(bufferingState).some((state) => state === true)

    const bufferingValue = isAllVideoSync ? false : isBuffered
    dispatch(setIsBuffering(isMobile ? bufferingValue : isBuffered))
  }, [bufferingState, currentlyAt, isMobile])

  // handle player buffering value
  const handlePlayerLoaded = (buffering: any) => {
    setBufferingState((prev) => ({ ...prev, ...buffering }))
  }

  // handle check players loaded event
  useEffect(() => {
    if (shakaInstances?.current?.length) {
      shakaInstances?.current?.forEach((player: any, i: number) => checkVideoBuffering(player, i))
    }
  }, [shakaInstances, isPlaying])

  useEffect(() => {
    let shakaRefs: any[] = []
    if (mediaTracks.length && isShakaInstalledOk.current && !shakaInstances.current.length) {
      if (
        maxVideoSizeForVariant.current.width === 0 &&
        maxVideoSizeForVariant.current.height === 0
      ) {
        maxVideoSizeForVariant.current.width = screenWidth
        maxVideoSizeForVariant.current.height = screenHeight
      }

      Promise.all(
        VideoRefs.current.map((video: HTMLVideoElement, i: number) => {
          video.volume = mediaTracks?.[i]?.contentTrack ? volumeLevel / 100 : 0

          shakaInstances.current[i] = new Shaka.Player()
          shakaInstances.current[i].attach(video)
          const player = shakaInstances.current[i]

          if (player) {
            player.configure(playerConfiguration)
            player.addEventListener('error', (error: any) => handleShakaError(error))

            return player.load(mediaTracks[i].url)
          }
        }),
      )
        .then(() => {
          window.shakaPlayers = shakaInstances.current
          shakaRefs = shakaInstances.current
        })
        .catch((error) => {
          if (!isAbortModal.on) dispatch(showAbortModal('playabort'))
          console.error('shaka load error: ', error)
        })
    }

    return () => {
      shakaRefs.forEach((shaka) => shaka.removeEventListener('error', handleShakaError))
    }
  }, [mediaTracks])

  useEffect(() => {
    if (selectedVideoId !== '') {
      setTimeout(() => {
        const selectedHeadVideoBox = videosContainerRef.current?.children?.namedItem(
          `VideoBox_${selectedVideoId}`,
        )
        if (
          selectedHeadVideoBox &&
          videosContainerRef.current &&
          headVideoContainerRef.current?.firstElementChild
        ) {
          dispatch(setHeadVideoId(selectedVideoId))
          const newHeadVideoBox = videosContainerRef.current.replaceChild(
            headVideoContainerRef.current.firstElementChild,
            selectedHeadVideoBox,
          )
          headVideoContainerRef.current.appendChild(newHeadVideoBox)
        }
        setSelectedVideoId('')
      }, 300)
    }
  }, [selectedVideoId])

  useEffect(() => {
    const forceFsButtonChange = () => {
      if (!screenfull.isFullscreen && fullScreenVideoElem) {
        dispatch(setFullScreenVideoElem(''))
      }
    }

    if (screenfull.isEnabled) {
      screenfull.on && screenfull.on('change', forceFsButtonChange)
    }
    return () => {
      screenfull.off && screenfull.off('change', forceFsButtonChange)
    }
  }, [fullScreenVideoElem])

  useEffect(() => {
    displaySubs(subtitleLang)
  }, [subtitleLang])

  useEffect(() => {
    if (shakaInstances?.current?.length) {
      maxVideoSizeForVariant.current.width = screenWidth
      maxVideoSizeForVariant.current.height = screenHeight

      shakaInstances.current.forEach((player: any) => {
        player.configure(playerConfiguration)
      })
    }
  }, [isPortraitView])

  const moveProgressWithKey = (keyboardEvent: number): void => {
    const toWhere = VideoRefs.current[0]!.currentTime + keyboardEvent
    seekTo(toWhere, isPlaying)
  }

  const handleVolumeSlide = (event: Event | null, newLevel: number | number[]) => {
    const soundLevel = newLevel as number
    const trackIndex = mediaTracks?.findIndex((track) => track.contentTrack)

    VideoRefs.current[trackIndex].volume = soundLevel / 100

    dispatch(setVolumeLevel(soundLevel))
  }

  const handleVolumeKeyDown = (volumeDiff: number) => {
    const trackIndex = mediaTracks?.findIndex((track) => track.contentTrack)

    const volume = VideoRefs.current[trackIndex].volume * 100
    const volumeUpdate = volume + volumeDiff
    if (volumeUpdate >= 0 && volumeUpdate <= 100) {
      handleVolumeSlide(null, volumeUpdate)
    } else if (volumeUpdate < 0) {
      handleVolumeSlide(null, 0)
    } else {
      handleVolumeSlide(null, 100)
    }
  }

  const muteUnmute = () => {
    const trackIndex = mediaTracks?.findIndex((track) => track.contentTrack)

    if (VideoRefs.current[trackIndex].volume === 0) {
      handleVolumeSlide(null, volumeMem.current)
      volumeMem.current = 0
    } else {
      volumeMem.current = VideoRefs.current[trackIndex].volume * 100
      handleVolumeSlide(null, 0)
    }
  }

  useEffect(() => {
    const keyDownHandler = (event: any) => {
      const { code } = event
      if (
        SHORTCUTS.includes(code) &&
        event.target.tagName !== 'TEXTAREA' &&
        event.target.tagName !== 'INPUT'
      ) {
        event.preventDefault()
        if (!VideoRefs?.current[0]?.paused) showCPanel(true, event)

        switch (code) {
          case 'Enter':
          case 'Space':
            pressPlayPause(VideoRefs?.current[0]?.paused)
            break
          case 'KeyF':
            toggleFullScreen()
            break
          case 'KeyM':
            muteUnmute()
            break
          case 'ArrowUp':
            handleVolumeKeyDown(10)
            break
          case 'ArrowDown':
            handleVolumeKeyDown(-10)
            break
          case 'ArrowRight':
            moveProgressWithKey(10)
            break
          case 'ArrowLeft':
            moveProgressWithKey(-10)
            break
          default:
            break
        }
      }
    }
    if (mediaTracks.length) {
      document.addEventListener('keydown', keyDownHandler)
    }
    return () => {
      document.removeEventListener('keydown', keyDownHandler)
    }
  }, [mediaTracks])

  const getPlayerPlaceholderPoster = (video: IMediaTrack) => {
    if (video?.poster && !video?.poster?.includes('undefined')) return video.poster
    else if (video?.landscapeImage && !video?.landscapeImage?.includes('undefined'))
      return `${video?.landscapeImage}?preventCache=${uuidv4()}`
    else if (learnObjItem?.images?.landscapeUrl) return learnObjItem?.images?.landscapeUrl
    else return null
  }

  const handleCheckEndEvent = useCallback(
    async (time: number) => {
      const contentTrackIndex = mediaTracks?.findIndex((track) => track.contentTrack)
      if (time >= learnObjItem?.duration) {
        pressPlayPause(false)
        trackPlaybackStateChange(contentTrackIndex, 'ended', false)
        dispatch(signalContentEnded(true))

        seekTo(0)
      } else {
        dispatch(signalContentEnded(false))
      }
    },
    [mediaTracks, learnObjItem?.duration],
  )

  const handleTouchStart = (video: any) => {
    if (
      (isInSwitchMode && video.id === headVideoId && !isUserSeeking && isPlaying) ||
      (!isInSwitchMode && !isUserSeeking && isPlaying)
    ) {
      showCPanel(true)
    }
  }

  const handleLoadedData = () => {
    VideoRefs.current.forEach((_: any, i: number) => {
      const player = shakaInstances.current[i]
      if (player) checkVideoBuffering(player, i)
    })
  }

  const renderSubtitles = (video: any, vidInd: number) => {
    if (vidInd === 0 && video.subtitles?.length > 1) {
      return video.subtitles
        .slice(1)
        .map((subtitle: any) => (
          <track
            key={`${video.id}-textTrack-${subtitle.locale}`}
            id={`${subtitle.locale}`}
            kind="subtitles"
            src={subtitle.url}
            srcLang={subtitle.locale.slice(0, 2)}
            label={subtitle.label}
          />
        ))
    }
    return null
  }

  const handleVideoControls = (video: any, vidInd: number) => {
    return (!isInSwitchMode || (isInSwitchMode && video.id === headVideoId)) &&
      fullScreenVideoElem !== '' ? (
      <VideoControls
        style={{
          backgroundColor: isVidControlPanelOn ? 'rgba(0, 0, 0, 0.4)' : 'transparent',
        }}
      >
        <ControlsTop>
          <TimeBox>
            <PositionDuration currentlyAtRef={currentlyAtRef} />
          </TimeBox>

          {!isPortraitView && (
            <ControlsTopRight>
              <SpentTimeDisplay />

              <NavBarButton
                variant="text"
                onPointerDown={(event: SyntheticEvent) => {
                  event.preventDefault()
                  event.stopPropagation()
                  dispatch(toggleAudioSubtSidebar(true))
                }}
              >
                <NavBarIcon src={SubAud} alt="Subtile + Audio selector modal" />
              </NavBarButton>

              {['Remix', 'RemixV2'].includes(contentType) && !justOneTrack && (
                <NavBarButton
                  variant="text"
                  onPointerDown={(event: SyntheticEvent) => {
                    event.preventDefault()
                    event.stopPropagation()
                    switchView(true)
                  }}
                >
                  <NavBarIcon src={VideoSwitch} alt="Main video switching panel" />
                </NavBarButton>
              )}
            </ControlsTopRight>
          )}
        </ControlsTop>

        <ControlsMiddle>
          {isVideoLoading[vidInd] ? (
            <BouncingLoader position="absolute" top={0} zIndex={101} />
          ) : null}
        </ControlsMiddle>

        <Fade in={isVidControlPanelOn || isVideoLoading[vidInd]} timeout={500}>
          <ControlsBottom>
            <ProgressBox>
              <ProgressBar
                videosContainerRef={videosContainerRef}
                showCPanel={showCPanel}
                seekTo={seekTo}
                progressAt={progressAt}
                setProgressAt={setProgressAt}
              />
            </ProgressBox>

            <BottomButtons>
              <LeftBB>
                {' '}
                <PlayPauseBox>
                  <PlayPauseButton pressPlayPause={pressPlayPause} videoId={video.id} />
                </PlayPauseBox>
                <VolumeComponent muteUnmute={muteUnmute} handleVolumeSlide={handleVolumeSlide} />
              </LeftBB>

              <RightBB>
                {fullScreenVideoElem === '' && (
                  <>
                    <PopOverButton
                      aria-describedby={popoverId}
                      variant="outlined"
                      color="inherit"
                      onClick={openSpPicker}
                    >
                      {`${playbackSpeed}x`}
                    </PopOverButton>
                    <PopOverMenu
                      id={popoverId}
                      open={isSpeedPopUp}
                      anchorEl={anchorElem}
                      onClose={closeSpPicker}
                      anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'center',
                      }}
                      transformOrigin={{
                        vertical: 'bottom',
                        horizontal: 'center',
                      }}
                    >
                      <PlaybackSpeedList id="playback-speed-list">
                        {pbSpeeds.map((pbSpeed: number, i: number) => (
                          <PlaybackSpeedListButton
                            key={'pbSpeed_' + i}
                            onPointerDown={handleSpeedPick}
                          >
                            {`${pbSpeed}x`}
                          </PlaybackSpeedListButton>
                        ))}
                      </PlaybackSpeedList>
                    </PopOverMenu>
                  </>
                )}

                {!browser?.name?.includes('Safari') && (
                  <FullScreenButton onTouchEnd={() => toggleFullScreen()}>
                    {fullScreenVideoElem ? (
                      <FullscreenExitIcon fontSize="large" />
                    ) : (
                      <FullscreenIcon fontSize="large" />
                    )}
                  </FullScreenButton>
                )}
              </RightBB>
            </BottomButtons>
          </ControlsBottom>
        </Fade>
      </VideoControls>
    ) : (
      <VideoControls
        style={{
          backgroundColor: isVidControlPanelOn ? 'rgba(0, 0, 0, 0.4)' : 'transparent',
        }}
        onClick={() => video.id === headVideoId && pressPlayPause(!isPlaying, vidInd)}
      >
        <ControlsMiddle>
          {isVideoLoading[vidInd] ? (
            <BouncingLoader position="absolute" top={0} zIndex={101} />
          ) : null}
        </ControlsMiddle>
      </VideoControls>
    )
  }

  return (
    <>
      <VideosFrame
        style={{
          height: isPortraitView ? `calc(100vw / ${videoAspect})` : '100%',
        }}
      >
        <HeadVideoContainer
          ref={headVideoContainerRef}
          onTouchStart={() => {
            if (!isUserSeeking && isPlaying) showCPanel(true)
          }}
          sx={makeHeadVideoContainerStyles()}
        ></HeadVideoContainer>

        <VideosContainer ref={videosContainerRef} sx={makeVideosContainerStyles()}>
          {mediaTracks.map((video: any, vidInd: number) => {
            return (
              <VideoBox
                id={`VideoBox_${video.id}`}
                key={`VideoBox_${video.id}`}
                ref={(element: HTMLDivElement) => {
                  videoBoxRefs.current[vidInd] = element
                }}
                sx={makeVideoBoxStyles(vidInd, video.id, video?.contentTrack)}
                onTouchStart={() => handleTouchStart(video)}
              >
                <Video
                  key={video.id}
                  id={video.id}
                  ref={(element: HTMLVideoElement) => {
                    VideoRefs.current[vidInd] = element
                  }}
                  crossOrigin="anonymous"
                  preload="metadata"
                  poster={getPlayerPlaceholderPoster(video)}
                  playsInline
                  muted={!video.contentTrack}
                  onTimeUpdate={() => {
                    trackPlaybackStateChange(vidInd, 'timeUpdate', false)
                    updateClipPosition()
                  }}
                  onRateChange={() => trackPlaybackStateChange(vidInd, 'speed', true)}
                  onWaiting={() => trackPlaybackStateChange(vidInd, 'waiting', true)}
                  onSeeking={() => trackPlaybackStateChange(vidInd, 'seeking', true)}
                  onSeeked={() => trackPlaybackStateChange(vidInd, 'seeked', false)}
                  onPlay={() => trackPlaybackStateChange(vidInd, 'play', false)}
                  onPlaying={() => trackPlaybackStateChange(vidInd, 'playing', false)}
                  onPause={() => trackPlaybackStateChange(vidInd, 'pause', false)}
                  onError={() => trackPlaybackStateChange(vidInd, 'error', false)}
                  onAbort={() => trackPlaybackStateChange(vidInd, 'aborted', false)}
                  onEnded={() => handleCheckEndEvent(currentlyAtRef.current * 1000)}
                  onLoadedData={handleLoadedData}
                  style={{
                    height: isPortraitView ? `calc(100vw / ${videoAspect})` : '100%',
                  }}
                >
                  {renderSubtitles(video, vidInd)}
                </Video>

                <SubtitleOverlay video={video} vidInd={vidInd} subtitleRefs={subtitleRefs} />

                {handleVideoControls(video, vidInd)}

                {(!isVideoLoading[vidInd] || (isVideoLoading[vidInd] && blackouts[vidInd])) && (
                  <VideoSelectorOverlay
                    onTouchEnd={() => {
                      if (video.id !== headVideoId && isInSwitchMode) setSelectedVideoId(video.id)
                    }}
                    sx={{
                      backgroundColor: blackouts[vidInd] ? 'rgba(0, 0, 0, 1)' : 'rgba(0, 0, 0, 0)',
                      borderRadius: '10px',
                    }}
                  >
                    <TickMark
                      src={Tick}
                      alt="Tick mark icon"
                      sx={{ opacity: `${selectedVideoId === video.id ? 1 : 0}` }}
                    />
                  </VideoSelectorOverlay>
                )}
              </VideoBox>
            )
          })}
        </VideosContainer>
      </VideosFrame>

      <VideoPlayerControl>
        <PlayerControlsMobile
          currentlyAtRef={currentlyAtRef}
          showCPanel={showCPanel}
          seekTo={seekTo}
          progressAt={progressAt}
          setProgressAt={setProgressAt}
          pressPlayPause={pressPlayPause}
          fullScreenVideoElem={fullScreenVideoElem}
          openSpPicker={openSpPicker}
          popoverId={popoverId}
          isSpeedPopUp={isSpeedPopUp}
          anchorElem={anchorElem}
          closeSpPicker={closeSpPicker}
          handleSpeedPick={handleSpeedPick}
        />
      </VideoPlayerControl>
      <AudioSubtSidebar />
    </>
  )
}

export default RemixPortVids

const VideosFrame = styled('div')({
  width: '100%',
  display: 'flex',
  justifyContent: 'space-between',
})
const HeadVideoContainer = styled(Box)({
  margin: 0,
  padding: 0,
})
const VideosContainer = styled('section')({
  display: 'flex',
})
const VideoBox = styled(Box)({
  width: '100%',
  height: '100%',
  overflow: 'hidden',
  position: 'relative',
  minWidth: '100%',
  zIndex: 100,
  '&:last-child': {
    margin: 0,
  },
  padding: 0,
})

const Video = styled('video')({
  width: '100%',
  borderRadius: '10px',
  position: 'absolute',
  zIndex: 200,
  objectFit: 'cover',
})
const VideoSelectorOverlay = styled('div')({
  width: '100%',
  height: '100%',
  position: 'absolute',
  zIndex: 400,
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'flex-end',
  alignItems: 'flex-start',
  cursor: 'pointer',
})
const TickMark = styled('img')({
  opacity: 0,
  transition: 'opacity 0.3s ease',
  margin: '15px 15px 0 0',
})
const VideoControls = styled(Box)({
  width: '100%',
  height: '100%',
  margin: 0,
  padding: '10px',
  position: 'absolute',
  zIndex: 500,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
})
const ControlsTop = styled('section')({
  width: '100%',
  height: '35%',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'flex-start',
})
const TimeBox = styled(Box)({
  width: '50%',
  height: '30px',
  textAlign: 'left',
  padding: 0,
  margin: 0,
})
const ControlsTopRight = styled('div')({
  width: '50%',
  height: '30px',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'flex-end',
  alignItems: 'center',
})
const NavBarButton = styled(Button)({
  padding: 0,
  minWidth: 0,
  color: 'transparent',
})
const NavBarIcon = styled('img')({
  width: '33px',
  height: '33px',
  marginLeft: '6px',
})
const ControlsMiddle = styled('section')({
  width: '100%',
  height: '30%',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  alignItems: 'center',
})
const PlayPauseBox = styled(Box)({
  width: '50px',
  height: '20px',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
  position: 'relative',
  right: '5px',
})
const ControlsBottom = styled('section')({
  width: '100%',
  height: '35%',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'flex-end',
})
const ProgressBox = styled(Box)({
  width: '98%',
})
const BottomButtons = styled(Box)({
  width: '100%',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
})
const LeftBB = styled('div')({
  width: '50%',
  height: '100%',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'flex-start',
  alignItems: 'center',
})
const RightBB = styled('div')({
  width: '50%',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'flex-end',
  alignItems: 'center',
})
const PopOverButton = styled(Button)({
  width: '33px',
  height: '25px',
  minWidth: '38px',
  fontSize: '12px',
  textAlign: 'center',
  marginRight: '5px',
  textTransform: 'lowercase',
})
const PopOverMenu = styled(Popover)({
  backgroundColor: 'transparent',
  fontSize: '12px',
})
const PlaybackSpeedList = styled(List)({
  '&.MuiList-root': {
    backgroundColor: '#333333',
    border: '1px solid #333333',
  },
})
const PlaybackSpeedListButton = styled(ListItemButton)({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  alignItems: 'center',
  backgroundColor: '#333333',
  '&:hover': {
    backgroundColor: '#4d4d4d',
  },
})
const FullScreenButton = styled(Box)({
  width: '30px',
  height: '30px',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  alignItems: 'center',
  cursor: 'pointer',
})

const VideoPlayerControl = styled('div')({
  width: '100%',
  margin: '18px 0 18px 0',
})
