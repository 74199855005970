import en from './gb.svg'
import de from './de.svg'
import ro from './ro.svg'
import hu from './hu.svg'

export interface IuiLangSrcs {
  [key: string]: string
}
const uiLangSrcs: IuiLangSrcs = {
  'hu-HU': hu,
  'en-US': en,
  'de-DE': de,
  'ro-RO': ro,
}

export default uiLangSrcs
