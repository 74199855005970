import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import { IUserData } from 'store/storeTypes'

const initialState: IUserData = {
  id: '',
  name: '',
  email: '',
  role: '',
  token: '',
}

export const userDataSlice = createSlice({
  name: 'userData',
  initialState,
  reducers: {
    storeUserData: (state: IUserData, action: PayloadAction<IUserData>) => {
      state.id = action.payload.id
      state.name = action.payload.name
      state.email = action.payload.email
      state.role = action.payload.role
      state.token = action.payload.token
    },
  },
})

export const { storeUserData } = userDataSlice.actions
export default userDataSlice.reducer
