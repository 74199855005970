import { styled } from '@mui/system'
import { Box } from '@mui/material'
import './index.css'

const LoaderBox = styled(Box)({
  width: '100%',
  height: '100%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  margin: 0,
  padding: 0,
  backgroundColor: 'rgba(0, 0, 0, 0.4)',
  borderRadius: '10px',
  color: '#ffffff',
})

function BouncingLoader(props: any) {
  const { position, top, zIndex } = props

  return (
    <LoaderBox sx={{ position, top, zIndex }}>
      <div className="double-bounce1"></div>
      <div className="double-bounce2"></div>
    </LoaderBox>
  )
}

export default BouncingLoader
