import { useState, useRef, SyntheticEvent } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Box, Button, Tabs, Tab, Typography } from '@mui/material'
import { ArrowBack } from '@mui/icons-material'
import { styled } from '@mui/system'
import { showLabel } from 'services/language'
import { About, Search, Chapters, Notes, Assets, Language } from './PanelTabs'
import { switchAppView, toggleAudioSubtSidebar } from 'store/reducers/componentDisplay'
import RemixPortVids from './PortVideos/RemixPortVids'
import PlaylistPortVids from './PortVideos/PlaylistPortVids'
import AudioSubtSidebar from './AudioSubtSidebar'
import SpentTimeDisplay from 'components/SpentTimeDisplay'
import packageJSON from '../../../../package.json'
import SubAud from 'assets/icons/subAud.svg'
import Close from 'assets/icons/close.svg'
import type { RootState } from 'store/index'
import { usePreviewContext } from 'contexts/PreviewContext'
import { UAParser } from 'ua-parser-js'

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  }
}

const SwitchBar = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: '6px 10px 5px 10px',
})
const CloseButton = styled(Button)({
  padding: 0,
  minWidth: 0,
  color: 'transparent',
})
const CloseIcon = styled('img')({
  width: '27px',
  height: '27px',
})
const TitleBar = styled('div')({
  width: '100%',
  height: '35px',
  marginBottom: '10px',
  padding: '6px 10px 5px 10px',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
})
const TitleBarLeft = styled('div')({
  maxWidth: 'calc(100vw - 150px)',
  height: '100%',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'flex-start',
  zIndex: 9999,
})
const TitleBarRight = styled('div')({
  height: '100%',
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'flex-end',
})
const NavBarButton = styled(Button)({
  padding: 0,
  minWidth: 0,
  color: 'transparent',
})
const NavBarIcon = styled('img')({
  width: '33px',
  height: '33px',
  marginLeft: '6px',
})
const BackButton = styled(Button)({
  width: '75px',
  color: '#000000',
  backgroundColor: '#7bc0da',
  borderRadius: '24px',
  padding: '5px',
  margin: 0,
  ':hover': {
    color: '#ffffff',
    backgroundColor: '#7bc0da',
  },
  ':active': {
    color: '#ffffff',
    backgroundColor: '#7bc0da',
  },
})
const BackButtonText = styled(Typography)({
  fontSize: '12px',
  cursor: 'pointer',
})
const LearnObjTitle = styled(Typography)({
  maxWidth: '100%',
  marginLeft: '10px',
  overflowWrap: 'normal',
  wordBreak: 'normal',
  whiteSpace: 'nowrap',
  overflowX: 'auto',
})
const InterPanelContainer = styled('div')({
  width: '100vw',
  minHeight: 'calc(100vh - 45px)',
  margin: 0,
  padding: 0,
})
const StyledTabs = styled(Tabs)({
  '& .MuiTabs-indicator': {
    backgroundColor: 'transparent',
  },
})
const Footer = styled('footer')({
  color: '#a9a9a9',
})
const FooterBox = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
})

function PortMainPanel(props: any) {
  const {
    VideoRefs,
    selectedVideoId,
    setSelectedVideoId,
    showCPanel,
    currentlyAtRef,
    isShakaInstalledOk,
    shakaInstances,
    maxVideoSizeForVariant,
    subtitleRefs,
    castSubtitles,
    displaySubs,
    pressPlayPause,
    updateClipPosition,
    trackPlaybackStateChange,
    seekTo,
    getReturnURL,
    isPreview,
    initOneTrack,
    plstPlaybackStateChange,
    updateCurrentTime,
  } = props

  const isMobileDevice = new UAParser().getResult().device.type === 'mobile'

  const { setIsPressExitButton } = usePreviewContext()

  const [tabIndex, setTabIndex] = useState<number>(0)

  const learnObjItem = useSelector((state: RootState) => state.learningObject.item)
  const appLanguage = useSelector((state: RootState) => state.appLanguage.selected)
  const localizationDict = useSelector((state: RootState) => state.appLanguage.localizationDict)
  const headVideoId = useSelector((state: RootState) => state.componentDisplay.headVideoId)
  const isPortraitView = useSelector((state: RootState) => state.componentDisplay.isPortraitView)
  const isInSwitchMode = useSelector((state: RootState) => state.componentDisplay.isInSwitchMode)

  const dispatch = useDispatch()

  const headVideoContainerRef = useRef<HTMLDivElement>(null)
  const videosContainerRef = useRef<HTMLDivElement>(null)
  const videoBoxRefs = useRef<HTMLDivElement[]>([])

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabIndex(newValue)
  }

  const switchView = (toOpen: boolean) => {
    dispatch(switchAppView())
    if (toOpen) {
      const newHeadVideoBox = videoBoxRefs.current.find((box: HTMLDivElement) => {
        return box.id.slice(9) === headVideoId
      })
      if (newHeadVideoBox && videosContainerRef.current && headVideoContainerRef.current) {
        const headVideoBoxToMove = videosContainerRef.current.removeChild(newHeadVideoBox)
        headVideoContainerRef.current.appendChild(headVideoBoxToMove)
      }
    } else if (videosContainerRef.current && headVideoContainerRef.current?.firstElementChild) {
      const headVideoBoxToMoveBack = headVideoContainerRef.current.removeChild(
        headVideoContainerRef.current?.firstElementChild,
      )
      videosContainerRef.current.appendChild(headVideoBoxToMoveBack)
    }
  }

  const displayNotesLabel = () => {
    const notesNo = learnObjItem?.bookmarks?.length
    return `${showLabel('lms_menu_notes')} ${notesNo ? '(' + notesNo + ')' : ''}`
  }

  const displayAssetsLabel = () => {
    const assetsNo = learnObjItem?.assets?.length
    return `${showLabel('lms_menu_assets')} (${assetsNo || '0'})`
  }

  const tabs = [
    {
      name: showLabel('lms_menu_about'),
      content: <About />,
    },
    {
      name: showLabel('lms_menu_search'),
      content: <Search seekTo={seekTo} isPreview={isPreview} />,
    },
    // isPreview.current
    //   ? {
    //       name: showLabel('lms_menu_forum'),
    //       content: <Forum seekTo={seekTo} currentlyAtRef={currentlyAtRef} />,
    //     }
    //   : null,
    {
      name: showLabel('lms_menu_chapter'),
      content: <Chapters seekTo={seekTo} />,
    },
    {
      name: displayNotesLabel(),
      content: <Notes seekTo={seekTo} currentlyAtRef={currentlyAtRef} />,
    },
    {
      name: displayAssetsLabel(),
      content: <Assets />,
    },
    {
      name: showLabel('lms_menu_language'),
      content: <Language />,
    },
  ]

  return (
    <section>
      {isInSwitchMode && (
        <SwitchBar sx={{ marginBottom: '15px' }}>
          <Box>
            <Typography variant="h6">{showLabel('lms_switch_screen_title')}</Typography>
            <Typography variant="subtitle2">{showLabel('lms_switch_screen_tooltip')}</Typography>
          </Box>

          <CloseButton
            onPointerDown={(event: SyntheticEvent) => {
              event.preventDefault()
              event.stopPropagation()
              switchView(false)
            }}
          >
            <CloseIcon src={Close} alt="Close the switch mode" />
          </CloseButton>
        </SwitchBar>
      )}

      <TitleBar
        sx={{
          display: (!isInSwitchMode && isPortraitView) || isMobileDevice ? 'flex' : 'none',
        }}
      >
        <TitleBarLeft>
          <BackButton
            variant="text"
            onClick={(event: any) => {
              setIsPressExitButton(true)

              event.preventDefault()

              setTimeout(() => {
                window.location.href = getReturnURL()
              }, 3000)
            }}
          >
            <ArrowBack fontSize="small" />
            <BackButtonText variant="caption">{showLabel('lms_back')}</BackButtonText>
          </BackButton>

          <LearnObjTitle>{localizationDict[appLanguage]?.title}</LearnObjTitle>
        </TitleBarLeft>

        <TitleBarRight>
          <SpentTimeDisplay />

          <NavBarButton
            variant="text"
            onPointerDown={(event: SyntheticEvent) => {
              event.preventDefault()
              event.stopPropagation()
              setTimeout(() => dispatch(toggleAudioSubtSidebar(true)), 200)
            }}
          >
            <NavBarIcon src={SubAud} alt="Subtile + Audio selector modal" />
          </NavBarButton>
        </TitleBarRight>
      </TitleBar>

      <InterPanelContainer>
        {ContentFrame({
          VideoRefs,
          selectedVideoId,
          setSelectedVideoId,
          showCPanel,
          currentlyAtRef,
          isShakaInstalledOk,
          shakaInstances,
          maxVideoSizeForVariant,
          subtitleRefs,
          castSubtitles,
          displaySubs,
          pressPlayPause,
          updateClipPosition,
          trackPlaybackStateChange,
          plstPlaybackStateChange,
          updateCurrentTime,
          headVideoContainerRef,
          videosContainerRef,
          videoBoxRefs,
          switchView,
          initOneTrack,
          seekTo,
        })}

        <Box sx={{ width: '100%' }}>
          <Box sx={{ display: 'flex', borderBottom: 1, borderColor: 'divider' }}>
            <StyledTabs
              value={tabIndex}
              onChange={handleTabChange}
              variant="scrollable"
              scrollButtons="auto"
              aria-label="interaction panel tabs"
            >
              {tabs
                .filter((tab: any) => tab)
                .map((tab, index) => (
                  <Tab
                    key={'iPanelTab' + index}
                    label={
                      <span style={{ color: `${index === tabIndex ? '#ffffff' : '#808080'}` }}>
                        {tab?.name}
                      </span>
                    }
                    sx={{ textTransform: 'capitalize' }}
                    {...a11yProps(index)}
                  />
                ))}
            </StyledTabs>
          </Box>
          {tabs
            .filter((tab) => tab)
            .map(
              (tab, index) =>
                index === tabIndex && <Box key={'iPanelTabContent' + index}>{tab?.content}</Box>,
            )}
        </Box>
      </InterPanelContainer>

      <Footer>
        <FooterBox>
          <Typography sx={{ fontSize: '12px' }}>
            {`${window.__RUNTIME_CONFIG__.SITE_OWNER}`}
          </Typography>

          <Typography sx={{ fontSize: '10px' }}>
            {`${showLabel('lms_app_version')}: ${
              packageJSON.version
            } | © ${new Date().getFullYear()}`}
          </Typography>
        </FooterBox>
      </Footer>

      <AudioSubtSidebar />
    </section>
  )
}

const ContentFrame = (props: any) => {
  const {
    VideoRefs,
    selectedVideoId,
    setSelectedVideoId,
    showCPanel,
    currentlyAtRef,
    isShakaInstalledOk,
    shakaInstances,
    maxVideoSizeForVariant,
    subtitleRefs,
    castSubtitles,
    displaySubs,
    pressPlayPause,
    updateClipPosition,
    trackPlaybackStateChange,
    plstPlaybackStateChange,
    updateCurrentTime,
    headVideoContainerRef,
    videosContainerRef,
    videoBoxRefs,
    switchView,
    initOneTrack,
    seekTo,
  } = props

  const contentType = useSelector((state: RootState) => state.learningObject.type)

  if (contentType) {
    return ['Remix', 'RemixV2'].includes(contentType) ? (
      <RemixPortVids
        VideoRefs={VideoRefs}
        selectedVideoId={selectedVideoId}
        setSelectedVideoId={setSelectedVideoId}
        headVideoContainerRef={headVideoContainerRef}
        videosContainerRef={videosContainerRef}
        videoBoxRefs={videoBoxRefs}
        showCPanel={showCPanel}
        currentlyAtRef={currentlyAtRef}
        isShakaInstalledOk={isShakaInstalledOk}
        shakaInstances={shakaInstances}
        maxVideoSizeForVariant={maxVideoSizeForVariant}
        subtitleRefs={subtitleRefs}
        castSubtitles={castSubtitles}
        displaySubs={displaySubs}
        pressPlayPause={pressPlayPause}
        updateClipPosition={updateClipPosition}
        trackPlaybackStateChange={trackPlaybackStateChange}
        switchView={switchView}
        seekTo={seekTo}
      />
    ) : (
      <PlaylistPortVids
        VideoRefs={VideoRefs}
        videosContainerRef={videosContainerRef}
        videoBoxRefs={videoBoxRefs}
        showCPanel={showCPanel}
        currentlyAtRef={currentlyAtRef}
        isShakaInstalledOk={isShakaInstalledOk}
        shakaInstances={shakaInstances}
        subtitleRefs={subtitleRefs}
        castSubtitles={castSubtitles}
        displaySubs={displaySubs}
        pressPlayPause={pressPlayPause}
        seekTo={seekTo}
        initOneTrack={initOneTrack}
        plstPlaybackStateChange={plstPlaybackStateChange}
        updateCurrentTime={updateCurrentTime}
      />
    )
  } else {
    return null
  }
}

export default PortMainPanel
