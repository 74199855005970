import { useCallback } from 'react'
import { useSelector } from 'react-redux'
import { styled } from '@mui/system'
import { Box } from '@mui/material'
import hexToRGB from 'utils/hexRgb'
import type { RootState } from 'store/index'

const SubtitleFrame = styled(Box)({
  width: '100%',
  height: '100%',
  position: 'absolute',
  zIndex: 300,
  flexDirection: 'row',
  justifyContent: 'center',
  alignItems: 'flex-end',
  backgroundColor: 'transparent',
  borderRadius: '10px',
})
const SubtitleText = styled('p')({
  width: '85%',
  maxWidth: '85%',
  color: '#ffffff',
  backgroundColor: 'transparent',
  border: 'none',
  borderRadius: '3px',
  textAlign: 'center',
  overflowWrap: 'break-word',
  wordBreak: 'break-word',
  whiteSpace: 'pre-line',
  transition: 'margin-bottom 0.3s linear',
})

function SubtitleOverlay(props: any) {
  const { video, vidInd, subtitleRefs } = props

  const subtitleLang = useSelector((state: RootState) => state.playback.subtitleLang)
  const headVideoId = useSelector((state: RootState) => state.componentDisplay.headVideoId)
  const fullScreenVideoElem = useSelector(
    (state: RootState) => state.componentDisplay.fullScreenVideoElem,
  )
  const contentType = useSelector((state: RootState) => state.learningObject.type)
  const isPortableDevice = useSelector(
    (state: RootState) => state.componentDisplay.isPortableDevice,
  )
  const isInSwitchMode = useSelector((state: RootState) => state.componentDisplay.isInSwitchMode)
  const subTextColor = useSelector((state: RootState) => state.playback.subTextColor)
  const subBckgColor = useSelector((state: RootState) => state.playback.subBckgColor)
  const subOpacity = useSelector((state: RootState) => state.playback.subOpacity)

  const compFontColor = useCallback(() => {
    return `rgba(${hexToRGB(subTextColor)}, 1)`
  }, [subTextColor])

  const compBckgColor = useCallback(() => {
    const opacity = typeof subOpacity === 'number' ? subOpacity / 100 : 1
    return `rgba(${hexToRGB(subBckgColor)}, ${opacity})`
  }, [subBckgColor, subOpacity])

  const showSubtFrame = () => {
    if (subtitleLang === 'Off') {
      return 'none'
    } else if (isPortableDevice && ['Remix', 'RemixV2'].includes(contentType) && !isInSwitchMode) {
      return 'flex'
    } else {
      return video.id === headVideoId ? 'flex' : 'none'
    }
  }

  const calcFontSize = () => {
    if (isPortableDevice) {
      return '16px'
    } else {
      return fullScreenVideoElem ? '26px' : '20px'
    }
  }

  return (
    <SubtitleFrame sx={{ display: showSubtFrame() }}>
      <SubtitleText
        ref={(element: HTMLParagraphElement) => {
          subtitleRefs.current[vidInd] = element
        }}
        sx={{
          fontSize: calcFontSize(),
          color: compFontColor(),
          backgroundColor: compBckgColor(),
          marginBottom: !isPortableDevice ? '66px' : '16px',
        }}
      />
    </SubtitleFrame>
  )
}

export default SubtitleOverlay
