import { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { AxiosResponse, AxiosError } from 'axios'
import { Box, Grid } from '@mui/material'
import Markers from 'services/api/markers'
import AlertMsg from 'services/alertMsg'
import { styled } from '@mui/system'
import { formatStringToReadableTime, HHMMSSToSec } from 'utils/dateTimeConfig'
import type { RootState } from 'store/index'

const ChaptersGridContainer = styled(Grid)({
  width: '57.5vw',
})
const ChapterLine = styled(Grid)({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  alignItems: 'flex-start',
  marginBottom: '5px',
})
const TitleBox = styled(Box)({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'flex-start',
  alignItems: 'center',
  cursor: 'pointer',
})
const ChapterAt = styled('span')({
  backgroundColor: '#212223',
  color: '#6C6C6C',
  textAlign: 'center',
  fontSize: '16px',
  fontWeight: 700,
  lineHeight: '22px',
  letterSpacing: '0.46px',
  minWidth: '89px',
  padding: '10px 10px',
  borderRadius: '6px',
  marginRight: '21px'
})
const DescriptionBox = styled(Box)({
  width: '100%',
})
const ChapterTitle = styled('h4')({
  margin: '6px 10px',
  padding: 0,
  fontSize: '16px',
})
const ChapterText = styled('p')({
  margin: '0 0 6px 0',
  padding: 0,
  fontSize: '16px',
})

function Chapters(props: any) {
  const { seekTo } = props

  const [chapters, setChapters] = useState<any[]>([])
  const contentType = useSelector((state: RootState) => state.learningObject.type)
  const headVideoId = useSelector((state: RootState) => state.componentDisplay.headVideoId)
  const storedChapters = useSelector((state: RootState) => state.learningObject.chapters)
  const isPlaying = useSelector((state: RootState) => state.playback.isPlaying)
  const appLanguage = useSelector((state: RootState) => state.appLanguage.selected)

  useEffect(() => {
    if (['Remix', 'RemixV2'].includes(contentType) && chapters.length < 1) {
      setChapters(storedChapters.filter((chapter) => chapter.locale === appLanguage))
    } else if (contentType === 'Playlist') {
      Markers.search(headVideoId)
        .then((response: AxiosResponse) => {
          const chapters = response?.data?.pageContent

          const chapterData = chapters?.map((ch: any) => ({
            name: ch.title,
            from: ch.position,
            to: ch.end,
          }))

          setChapters(() => chapterData)
        })
        .catch((error: AxiosError) => {
          AlertMsg.show({
            type: 'error',
            message: error,
            context: "Can't retrieve the chapters → ",
          })
        })
    }
  }, [headVideoId])

  useEffect(() => {
    if (['Remix', 'RemixV2'].includes(contentType)) {
      setChapters(storedChapters.filter((chapter) => chapter.locale === appLanguage))
    }
  }, [appLanguage])

  return (
    <ChaptersGridContainer container direction="column">
      {chapters.map((chapter: any, index: number) => (
        <div key={`${chapter.name}_${index}`}>
          <TitleBox onPointerDown={() => seekTo(HHMMSSToSec(chapter.from), isPlaying)}>
            <ChapterAt>{formatStringToReadableTime(chapter.from)}</ChapterAt>
            <ChapterTitle style={{ paddingLeft: chapter.parentId ? '10px' : 0, fontWeight: chapter.parentId ? 'normal' : 700 }}>
              {chapter.name}
            </ChapterTitle>
          </TitleBox>
          <DescriptionBox>
            <ChapterText>{chapter.description}</ChapterText>
          </DescriptionBox>
        </div>
      ))}
    </ChaptersGridContainer>
  )
}

export default Chapters
