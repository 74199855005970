import { styled } from '@mui/system'
import { Box, Skeleton } from '@mui/material'

const LoaderFrame = styled('div')({
  width: '100%',
  height: '100vh',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  padding: '20px 5% 10px 5%',
  margin: 0,
})
const UpperLoader = styled('div')({
  width: '100%',
  height: '60%',
  margin: 0,
  padding: '0 10px',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  alignItems: 'center',
})
const HeaderBox = styled(Box)({
  width: '100%',
  height: '50px',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
})
const LeftHeaderBox = styled(Box)({
  width: '250px',
  height: '100%',
  margin: 0,
  padding: 0,
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
})
const BackSkel = styled(Skeleton)({
  width: '35px',
  height: '100%',
})
const TitleSkel = styled(Skeleton)({
  width: '200px',
  height: '100%',
})
const RightHeaderBox = styled(Box)({
  width: '100px',
  height: '100%',
  margin: 0,
  padding: 0,
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'flex-end',
})
const SubtitlSkel = styled(Skeleton)({
  width: '35px',
  height: '100%',
})
const VidSkelFrame = styled('div')({
  width: '100%',
  height: '90%',
  marginTop: '20px',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
})
const LeftVidBox = styled(Box)({
  width: '64.5%',
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
})
const MainVideoSkel = styled(Skeleton)({
  width: '100%',
  height: '95%',
  margin: 0,
  padding: 0,
  borderRadius: '10px',
})
const VidTextSkel = styled(Skeleton)({
  width: '100%',
  height: '5%',
})
const RightVidBox = styled(Box)({
  width: '34.5%',
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  alignItems: 'center',
})
const SideVidSkel = styled(Skeleton)({
  width: '100%',
  height: '49%',
  borderRadius: '10px',
})
const LowerLoader = styled('div')({
  width: '100%',
  height: '40%',
  margin: 0,
  padding: '20px 0 0 0',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
})
const TabBar = styled('div')({
  width: '100%',
  height: '10%',
  margin: 0,
  padding: 0,
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'flex-start',
  alignItems: 'center',
})
const TabBarIconSkel = styled(Skeleton)({
  width: '90px',
  height: '100%',
  marginRight: '25px',
})
const AboutTextBox = styled('div')({
  width: '100%',
  height: '90%',
  paddingTop: '15px',
})
const AboutTitleSkel = styled(Skeleton)({
  width: '30%',
  height: '30px',
  marginTop: '10px',
  marginLeft: '10px',
})
const AboutTextSkel = styled(Skeleton)({
  width: '70%',
  height: '20px',
  marginTop: '10px',
  marginLeft: '10px',
})

function SkeletonLoader() {
  return (
    <LoaderFrame>
      <UpperLoader>
        <HeaderBox>
          <LeftHeaderBox>
            <BackSkel variant="text" animation="wave" />
            <TitleSkel variant="text" animation="wave" />
          </LeftHeaderBox>
          <RightHeaderBox>
            <SubtitlSkel variant="text" animation="wave" />
          </RightHeaderBox>
        </HeaderBox>
        <VidSkelFrame>
          <LeftVidBox>
            <MainVideoSkel variant="rectangular" animation="pulse" />
            <VidTextSkel variant="text" animation="pulse" />
          </LeftVidBox>
          <RightVidBox>
            <SideVidSkel variant="rectangular" animation="pulse" />
            <SideVidSkel variant="rectangular" animation="pulse" />
          </RightVidBox>
        </VidSkelFrame>
      </UpperLoader>

      <LowerLoader>
        <TabBar>
          <TabBarIconSkel variant="circular" animation="wave" />
          <TabBarIconSkel variant="circular" animation="wave" />
          <TabBarIconSkel variant="circular" animation="wave" />
          <TabBarIconSkel variant="circular" animation="wave" />
          <TabBarIconSkel variant="circular" animation="wave" />
          <TabBarIconSkel variant="circular" animation="wave" />
          <TabBarIconSkel variant="circular" animation="wave" />
        </TabBar>
        <AboutTextBox>
          <AboutTitleSkel variant="text" animation="wave" />
          <AboutTextSkel variant="text" animation="wave" />
          <AboutTextSkel variant="text" animation="wave" />
          <AboutTextSkel variant="text" animation="wave" />
          <AboutTextSkel variant="text" animation="wave" />
        </AboutTextBox>
      </LowerLoader>
    </LoaderFrame>
  )
}

export default SkeletonLoader
