import { createSlice } from '@reduxjs/toolkit'
import { IPlaybackState, IAtoms } from 'store/storeTypes'
import type { PayloadAction } from '@reduxjs/toolkit'

const initialState: IPlaybackState = {
  isPlaying: false,
  isFirstPlayPressDone: false,
  currentlyAt: 0,
  spentUserTime: 0,
  volumeLevel: 70,
  playbackSpeed: 1,
  mediaDuration: 0,
  audioLang: 'hu-HU',
  subtitleLang: 'Off',
  subTextColor: '#ffffff',
  subBckgColor: '#000000',
  subOpacity: 100,
  isUserSeeking: false,
  atomStarts: [],
  atomMatrix: {},
  hasContentEnded: false,
  pbSpeeds: [0.5, 1, 1.25, 1.5, 2],
  isBuffering: false,
}

export const playbackSlice = createSlice({
  name: 'playback',
  initialState,
  reducers: {
    togglePlayback: (state: IPlaybackState, action: PayloadAction<boolean>) => {
      state.isPlaying = action.payload
    },
    storeMediaDuration: (state: IPlaybackState, action: PayloadAction<number>) => {
      state.mediaDuration = action.payload
    },
    setCurrentlyAt: (state: IPlaybackState, action: PayloadAction<number>) => {
      state.currentlyAt = action.payload
    },
    pressPlayFirstTime: (state: IPlaybackState, action: PayloadAction<boolean>) => {
      state.isFirstPlayPressDone = action.payload
    },
    chooseAudioLang: (state: IPlaybackState, action: PayloadAction<string>) => {
      state.audioLang = action.payload
    },
    chooseSubtitle: (state: IPlaybackState, action: PayloadAction<string>) => {
      state.subtitleLang = action.payload
    },
    setSubTextColor: (state: IPlaybackState, action: PayloadAction<string>) => {
      state.subTextColor = action.payload
    },
    setSubBckgColor: (state: IPlaybackState, action: PayloadAction<string>) => {
      state.subBckgColor = action.payload
    },
    setSubOpacity: (state: IPlaybackState, action: PayloadAction<number | string>) => {
      state.subOpacity = action.payload
    },
    userSeek: (state: IPlaybackState, action: PayloadAction<boolean>) => {
      state.isUserSeeking = action.payload
    },
    trackUserTime: (state: IPlaybackState, action: PayloadAction<number>) => {
      state.spentUserTime += action.payload
    },
    setVolumeLevel: (state: IPlaybackState, action: PayloadAction<number>) => {
      state.volumeLevel = action.payload
    },
    changePlaybackSpeed: (state: IPlaybackState, action: PayloadAction<number>) => {
      state.playbackSpeed = action.payload
    },
    storeAtomStarts: (state: IPlaybackState, action: PayloadAction<number[][]>) => {
      state.atomStarts = action.payload
    },
    storeAtoms: (state: IPlaybackState, action: PayloadAction<IAtoms>) => {
      state.atomMatrix = action.payload
    },
    signalContentEnded: (state: IPlaybackState, action: PayloadAction<boolean>) => {
      state.hasContentEnded = action.payload
    },
    setIsBuffering: (state: IPlaybackState, action: PayloadAction<boolean>) => {
      state.isBuffering = action.payload
    },
  },
})

export const {
  togglePlayback,
  pressPlayFirstTime,
  storeMediaDuration,
  setCurrentlyAt,
  chooseSubtitle,
  setSubTextColor,
  setSubBckgColor,
  setSubOpacity,
  chooseAudioLang,
  userSeek,
  trackUserTime,
  setVolumeLevel,
  changePlaybackSpeed,
  storeAtomStarts,
  storeAtoms,
  signalContentEnded,
  setIsBuffering,
} = playbackSlice.actions
export default playbackSlice.reducer