import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import { IAlertMsg, IAlertMsgState } from 'store/storeTypes'

const initialState: IAlertMsgState = {
  messages: [],
}

export const alertMsgSlice = createSlice({
  name: 'alertMsg',
  initialState,
  reducers: {
    display: (state: IAlertMsgState, action: PayloadAction<IAlertMsg>) => {
      state.messages = [...state.messages, action.payload]
    },
    remove: (state: IAlertMsgState, action: PayloadAction<IAlertMsg>) => {
      state.messages = state.messages.filter((msg) => msg.id !== action.payload.id)
    },
  },
})

export const { display, remove } = alertMsgSlice.actions
export default alertMsgSlice.reducer
