import { useEffect, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Alert, AlertTitle, Stack } from '@mui/material'
import { remove } from 'store/reducers/alertMsg'
import './index.css'
import { IAlertMsg, TAlertType } from 'store/storeTypes'
import type { RootState } from 'store/index'

function AlertMsgFrame() {
  const alertMessages = useSelector((state: RootState) => state.alertMsg.messages)
  const dispatch = useDispatch()

  const delAlert = (alert: IAlertMsg, alertRef: any) => {
    if (alertRef?.current) {
      alertRef.current.classList.remove('alert-fade-in')
      alertRef.current.classList.add('alert-fade-out')
    }
    setTimeout(() => dispatch(remove(alert)), 200)
  }

  return (
    <Stack className="alert-msg-frame" direction="column">
      {alertMessages.length > 0 &&
        alertMessages.map((alertMsg) => (
          <AlertMsgBox
            key={alertMsg.id}
            type={alertMsg.type}
            message={alertMsg.message}
            id={alertMsg.id as string}
            title={alertMsg.title as string}
            duration={alertMsg.duration as number}
            delAlert={delAlert}
          />
        ))}
    </Stack>
  )
}

type PropTypes = {
  type: TAlertType
  message: string
  id: string
  title: string
  duration: number
  delAlert: any
}

type ReadonlyPropTypes = Readonly<PropTypes>

function AlertMsgBox({ type, message, id, title, duration, delAlert }: ReadonlyPropTypes) {
  const alertRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    const timeout = setTimeout(() => delAlert({ id, type, message }, alertRef), duration)
    return () => clearTimeout(timeout)
  }, [])

  return (
    <Alert
      id={id}
      ref={alertRef}
      severity={type}
      style={{ fontWeight: 600, letterSpacing: '0.44px', borderRadius: '6px', padding: '2px 12px' }}
      className="alert-fade-in alert-msg-box"
      onClose={() => delAlert({ id, type, message }, alertRef)}
    >
      {title && <AlertTitle>{title}</AlertTitle>}
      {message}
    </Alert>
  )
}

export default AlertMsgFrame
