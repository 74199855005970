import { useState, useEffect, useRef } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { AxiosError, AxiosResponse } from 'axios'
import parse from 'html-react-parser'
import sanitizeHtml from 'sanitize-html'
import { styled } from '@mui/system'
import { setSearchResultsPerTracks, setSearchTerm, storeTagCloud } from 'store/reducers/search'
import {
  Box,
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableRow,
  TextField,
  Typography,
} from '@mui/material'
import { showLabel } from 'services/language'
import { HHMMSSToSec, secToHHMMSS } from 'utils/dateTimeConfig'
import downloadFile from 'services/file'
import AlertMsg from 'services/alertMsg'
import Find from 'services/api/find'
import DownloadSrc from 'assets/icons/download.svg'
import {
  ISearchPerTrack,
  ISearchGroup,
  TCloudTag,
  ITagMap,
  IContentIdNameMapping,
} from 'store/storeTypes'
import type { RootState } from 'store/index'

const SearchSection = styled('section')({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
})
const SearchBox = styled(Box)({
  width: '90%',
  marginTop: '15px',
})
const SearchField = styled(TextField)({
  backgroundColor: 'rgba(255, 255, 255, 0.2)',
  borderRadius: '14px',
  '& .Mui-focused .MuiOutlinedInput-notchedOutline': {
    border: 'none',
  },
  '&:hover .MuiOutlinedInput-notchedOutline': {
    border: 'none',
  },
})
const SearchResultsContainer = styled('div')({
  width: '90%',
  padding: '20px 0 0 0',
})
const SearchResultPerCategory = styled('div')({
  marginBottom: '20px',
})
const SearchData = styled(Box)({
  margin: '0 0 10px 10px',
  fontSize: '14px',
})
const CategoryTitle = styled(Typography)({
  color: '#737373',
  marginBottom: '3px',
  fontSize: '12px',
})
const TagName = styled('div')({
  display: 'inline-block',
  marginRight: '10px',
})
const NoteContent = styled('div')({
  display: 'inline-block',
  marginRight: '10px',
})
const AtCapsule = styled('div')({
  minWidth: 75,
  display: 'inline-block',
  padding: '1px 0',
  marginRight: '10px',
  backgroundColor: 'rgba(255, 255, 255, 0.7)',
  color: 'rgba(48, 47, 47, 0.84)',
  borderRadius: '6px',
  fontSize: '14px',
  textAlign: 'center',
  cursor: 'pointer',
  fontWeight: 500,
  lineHeight: '22px',
  letterSpacing: '0.46px',
})
const FileTable = styled(Table)({
  '&.MuiTable-root': {
    padding: 0,
  },
  width: '100%',
})
const TCell = styled(TableCell)({
  border: 'none',
  padding: '0 0 15px 0',
})
const DownloadBox = styled(Box)({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'flex-end',
  alignItems: 'center',
  fontSize: '10px',
})
const DownloadIcon = styled('img')({
  width: '16px',
  height: '16px',
  cursor: 'pointer',
})
const AssetName = styled('div')({
  display: 'inline-block',
  textIndent: '10px',
  fontSize: '14px',
})
const SkelFrame = styled(Box)({
  width: '90%',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
})
const TitleSkel = styled(Skeleton)({
  width: '50%',
  height: '33px',
  margin: '15px 0 0 0',
  padding: 0,
  borderRadius: '6px',
})
const TypeSkel = styled(Skeleton)({
  width: '20%',
  height: '20px',
  margin: '3px 0 0 0',
  padding: 0,
  borderRadius: '6px',
})
const DetailsSkel = styled(Skeleton)({
  width: '80%',
  height: '25px',
  margin: '3px 0 0 0',
  padding: 0,
  borderRadius: '6px',
})

function Search(props: any) {
  const { seekTo, isPreview } = props

  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [isEmptyResult, setIsEmptyResult] = useState<boolean>(false)
  const [contentIdNameMap, setContentIdNameMap] = useState<IContentIdNameMapping>({})
  const learnObjItem = useSelector((state: RootState) => state.learningObject.item)
  const tagCloud = useSelector((state: RootState) => state.search.tagCloud)
  const searchTerm = useSelector((state: RootState) => state.search.searchTerm)
  const searchResultsPerTracks = useSelector(
    (state: RootState) => state.search.searchResultsPerTracks,
  )
  const localizationDict = useSelector((state: RootState) => state.appLanguage.localizationDict)
  const isPlaying = useSelector((state: RootState) => state.playback.isPlaying)
  const appLanguage = useSelector((state: RootState) => state.appLanguage.selected)

  const dispatch = useDispatch()

  const searchDelay = useRef<number>(0)

  const getFile = (downloadUrl: string) => {
    downloadFile(downloadUrl).catch((error: AxiosError) => {
      AlertMsg.show({
        type: 'error',
        message: error,
        context: 'Could not download the file → ',
      })
    })
  }

  const transformTagsToCloudStruct = (tagMap: ITagMap) => {
    if (Object.keys(tagMap).length === 0) return []

    return Object.keys(tagMap).reduce((tagCnt: TCloudTag[], tagKey: string, index: number) => {
      if (tagMap[tagKey]?.tag) {
        tagCnt.push({
          id: `${tagKey}_${index}`,
          value: tagMap[tagKey].keyword,
          count: tagMap[tagKey]?.frequency || 1,
        })
      }
      return tagCnt
    }, [])
  }

  const filterGroups = (searchIdGroups: any) => {
    return searchIdGroups?.results?.filter((group: any) => group.count > 0)
  }

  const processSearchData = (sR: ISearchPerTrack, searchIdGroups: any, idToNames: any) => {
    const groups = filterGroups(searchIdGroups)
    idToNames[searchIdGroups.contentId] = searchIdGroups?.contentTitle
    if (groups.length) {
      sR[searchIdGroups.contentId] = groups
    }

    return sR
  }

  const calculateResultsPerTracks = (response: any, idToNames: any) => {
    return response?.data?.reduce(
      (sR: ISearchPerTrack, searchIdGroups: any) =>
        processSearchData(sR, searchIdGroups, idToNames),
      {},
    )
  }

  const performSearch = (keyword: string) => {
    dispatch(setSearchTerm(keyword))
    clearTimeout(searchDelay.current)
    if (keyword) {
      searchDelay.current = window.setTimeout(() => {
        setIsLoading(true)
        Find.search(learnObjItem?.id, keyword, appLanguage, isPreview.current)
          .then((response: AxiosResponse) => {
            const idToNames: IContentIdNameMapping = {}
            const resultsPerTracks: ISearchPerTrack = calculateResultsPerTracks(response, idToNames)
            const isEmpty = Object.keys(resultsPerTracks).length < 1
            setIsEmptyResult(isEmpty)
            setContentIdNameMap(idToNames)
            dispatch(setSearchResultsPerTracks(resultsPerTracks))
          })
          .catch((error: AxiosError) => {
            AlertMsg.show({
              type: 'error',
              message: error,
              context: "Couldn't perform search → ",
            })
            setIsEmptyResult(false)
          })
          .finally(() => setIsLoading(false))
      }, 1000)
    } else {
      dispatch(setSearchResultsPerTracks({}))
      setIsEmptyResult(false)
    }
  }

  const getTrackTitle = (trackId: string) => {
    if (learnObjItem?.id === trackId) {
      return localizationDict[appLanguage]?.title || trackId
    } else {
      return contentIdNameMap[trackId] || trackId
    }
  }

  const displaySearchResults = (elem: ISearchGroup, ind: number, trackId: string) => {
    const groupName = elem.group?.toLowerCase()

    switch (groupName) {
      case 'discussion':
        return (
          <SearchResultPerCategory key={`${groupName}_${ind}`}>
            <CategoryTitle variant="subtitle2">
              {`${showLabel('lms_menu_forum')} (${elem.count})`}
            </CategoryTitle>
            {elem.results?.map((comment: any, index: number) => {
              return (
                <SearchData key={`${comment.id}_${index}`}>
                  <AtCapsule
                    sx={{ cursor: 'default' }}
                    onPointerDown={() =>
                      seekTo(Number.parseFloat(comment.parameters), isPlaying, trackId)
                    }
                  >
                    {comment.parameters !== undefined ? secToHHMMSS(comment.parameters) : ''}
                  </AtCapsule>
                  <span>{comment.description}</span>
                </SearchData>
              )
            })}
          </SearchResultPerCategory>
        )
      case 'chapters':
        return (
          <SearchResultPerCategory key={`${groupName}_${ind}`}>
            <CategoryTitle variant="subtitle2">
              {`${showLabel('lms_menu_chapter')} (${elem.count})`}
            </CategoryTitle>
            {elem.results?.map((chapter: any, index: number) => {
              return (
                <SearchData key={`${chapter.name}_${index}`}>
                  <AtCapsule
                    onPointerDown={() =>
                      seekTo(HHMMSSToSec(chapter.parameters), isPlaying, trackId)
                    }
                  >
                    {chapter.parameters.slice(0, 8)}
                  </AtCapsule>
                  <span>{chapter.name}</span>
                </SearchData>
              )
            })}
          </SearchResultPerCategory>
        )
      case 'notes':
        return (
          <SearchResultPerCategory key={`${groupName}_${ind}`}>
            <CategoryTitle variant="subtitle2">
              {`${showLabel('lms_menu_notes')} (${elem.count})`}
            </CategoryTitle>
            {elem.results?.map((note: any, index: number) => {
              return (
                <SearchData key={`${note.name}_${index}`}>
                  <AtCapsule
                    onPointerDown={() =>
                      seekTo(Number.parseFloat(note.parameters), isPlaying, trackId)
                    }
                  >
                    {note.parameters !== undefined ? secToHHMMSS(note.parameters) : ''}
                  </AtCapsule>
                  <TagName>{note.name} — </TagName>
                  <NoteContent>{note.description}</NoteContent>
                </SearchData>
              )
            })}
          </SearchResultPerCategory>
        )
      case 'assets':
        return (
          <SearchResultPerCategory key={`${groupName}_${ind}`}>
            <CategoryTitle variant="subtitle2">
              {`${showLabel('lms_menu_assets')} (${elem.count})`}
            </CategoryTitle>

            <FileTable>
              <TableBody>
                {elem.results?.map((asset: any, index: number) => {
                  return (
                    <TableRow key={`${asset.name}_${index}`}>
                      <TCell align="left">
                        <AssetName>{asset.name}</AssetName>
                      </TCell>

                      <TCell align="right">
                        <DownloadBox>
                          <DownloadIcon
                            src={DownloadSrc}
                            onPointerDown={() => getFile(asset.parameters)}
                          />
                        </DownloadBox>
                      </TCell>
                    </TableRow>
                  )
                })}
              </TableBody>
            </FileTable>
          </SearchResultPerCategory>
        )
      case 'markers':
        return (
          <SearchResultPerCategory key={`${groupName}_${ind}`}>
            <CategoryTitle variant="subtitle2">
              {`${showLabel('lms_menu_markers')} (${elem.count})`}
            </CategoryTitle>
            {elem.results?.map((marker: any, index: number) => {
              return (
                <SearchData key={`${marker.name}_${index}`}>
                  <AtCapsule
                    onPointerDown={() => {
                      if (marker.parameters)
                        seekTo(HHMMSSToSec(marker.parameters), isPlaying, trackId)
                    }}
                  >
                    {marker.parameters !== undefined ? marker.parameters?.slice(0, 8) : ''}
                  </AtCapsule>
                  <TagName>{marker.name}</TagName>
                </SearchData>
              )
            })}
          </SearchResultPerCategory>
        )
      case 'subtitles':
        return (
          <SearchResultPerCategory key={`${groupName}_${ind}`}>
            <CategoryTitle variant="subtitle2">
              {`${showLabel('lms_menu_subtitles')} (${elem.count})`}
            </CategoryTitle>
            {elem.results?.map((line: any, index: number) => {
              return (
                <SearchData key={`${line.id}_${index}`}>
                  <AtCapsule
                    onPointerDown={() => {
                      if (line.parameters) seekTo(HHMMSSToSec(line.parameters), isPlaying, trackId)
                    }}
                  >
                    {line.parameters !== undefined ? line.parameters?.slice(0, 8) : ''}
                  </AtCapsule>
                  <TagName>{parse(sanitizeHtml(line.name || ''))}</TagName>
                </SearchData>
              )
            })}
          </SearchResultPerCategory>
        )
      default:
        return null
    }
  }

  useEffect(() => {
    if (learnObjItem?.tagCloud && tagCloud.length === 0) {
      dispatch(storeTagCloud(transformTagsToCloudStruct(learnObjItem.tagCloud)))
    }
  }, [learnObjItem])

  const handleEmptyResult = () => {
    return isEmptyResult ? (
      <CategoryTitle sx={{ marginTop: '15px' }}>{showLabel('lms_no_result')}</CategoryTitle>
    ) : (
      Object.keys(searchResultsPerTracks).map((trackId: any) => (
        <SearchResultsContainer key={trackId}>
          <Typography variant="h6">{getTrackTitle(trackId)}</Typography>
          {searchResultsPerTracks[trackId]?.map((elem: ISearchGroup, ind: number) =>
            displaySearchResults(elem, ind, trackId),
          )}
        </SearchResultsContainer>
      ))
    )
  }

  return (
    <SearchSection>
      <SearchBox>
        <SearchField
          autoFocus
          placeholder={showLabel('lms_search_input')}
          value={searchTerm}
          variant="outlined"
          type="text"
          margin="none"
          size="small"
          fullWidth
          onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
            performSearch(event?.target?.value || '')
          }
        />
      </SearchBox>

      {isLoading ? (
        <SkelFrame>
          {new Array(3).fill(null).map(() => (
            <>
              <TitleSkel variant="text" animation="wave" />
              <TypeSkel variant="text" animation="pulse" />
              <DetailsSkel variant="text" animation="wave" />
            </>
          ))}
        </SkelFrame>
      ) : (
        handleEmptyResult()
      )}
    </SearchSection>
  )
}

export default Search
