import styled from '@emotion/styled'
import { VolumeDown, VolumeMute, VolumeOff, VolumeUp } from '@mui/icons-material'
import { Box, Slider } from '@mui/material'
import { useSelector } from 'react-redux'
import { RootState } from 'store'

const VolumeBox = styled(Box)({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  alignItems: 'center',
  backgroundColor: '#333333',
  gap: '4px',
})
const VolumeBar = styled(Slider)({
  width: '82px',
  margin: 0,
  padding: 0,
  cursor: 'pointer',
  '& .MuiSlider-thumb::after': {
    width: '15px',
    height: '15px',
  },
  '& .MuiSlider-thumb.Mui-active': {
    boxShadow: 'none',
  },
})

const VolumeComponent = ({
  muteUnmute,
  handleVolumeSlide,
  isFullScreen,
  isMobile,
}: {
  muteUnmute: () => void
  handleVolumeSlide: (event: Event | null, newLevel: number | number[]) => void
  isFullScreen?: boolean
  isMobile?: boolean
}) => {
  const volumeLevel = useSelector((state: RootState) => state.playback.volumeLevel)

  const displayVolume = () => {
    if (volumeLevel < 1) {
      return <VolumeOff onPointerDown={muteUnmute} sx={{ cursor: 'pointer' }} />
    } else if (volumeLevel <= 34) {
      return (
        <VolumeMute
          onPointerDown={muteUnmute}
          sx={{ position: 'relative', right: '4px', cursor: 'pointer' }}
        />
      )
    } else if (volumeLevel <= 67) {
      return (
        <VolumeDown
          onPointerDown={muteUnmute}
          sx={{ position: 'relative', right: '2px', cursor: 'pointer' }}
        />
      )
    } else if (volumeLevel <= 100) {
      return <VolumeUp onPointerDown={muteUnmute} sx={{ cursor: 'pointer' }} />
    }
  }

  return (
    <VolumeBox
      style={{
        padding: isMobile ? '0 15px' : '15px',
      }}
    >
      {isFullScreen && displayVolume()}
      <VolumeBar
        aria-label="Volume"
        value={volumeLevel}
        onChange={handleVolumeSlide}
        size="small"
        valueLabelDisplay="off"
        style={{
          color: '#ffffff',
          backgroundColor: '#333333',
        }}
      />
    </VolumeBox>
  )
}

export default VolumeComponent
