import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import { IForumState, ICommentPosition } from 'store/storeTypes'

const initialState: IForumState = {
  comments: [],
  commentBeingEdited: null,
  customerMap: {},
  isRestricted: false,
}

export const dialogSlice = createSlice({
  name: 'dialog',
  initialState,
  reducers: {
    storeComments: (state: IForumState, action: PayloadAction<any[]>) => {
      state.comments = action.payload
    },
    newComment: (state: IForumState, action: PayloadAction<ICommentPosition>) => {
      const { commentIndex, parentIndex, parentId } = action.payload
      state.commentBeingEdited = {
        id: null,
        commentIndex,
        targetType: 'Content',
        payload: '',
        customerId: '',
        providerId: '',
        contentId: '',
        deleted: '',
        createdDate: '',
        updatedDate: '',
        parentId,
        parentIndex,
      }
    },
    editComment: (state: IForumState, action: PayloadAction<ICommentPosition>) => {
      const { commentIndex, parentIndex, parentId } = action.payload
      if (parentIndex !== undefined) {
        state.commentBeingEdited = state.comments[parentIndex].children[commentIndex]
      } else {
        state.commentBeingEdited = state.comments[commentIndex]
      }

      state.commentBeingEdited.commentIndex = commentIndex
      state.commentBeingEdited.parentIndex = parentIndex
      state.commentBeingEdited.parentId = parentId
    },
    writeComment: (state: IForumState, action: PayloadAction<null | string>) => {
      if (action.payload === null) {
        state.commentBeingEdited = null
      } else {
        state.commentBeingEdited.payload = action.payload
      }
    },
    addCustomerToMap: (state: IForumState, action: PayloadAction<any>) => {
      state.customerMap[action.payload.id] = action.payload.name
    },
    updateCustomerMap: (state: IForumState, action: PayloadAction<any>) => {
      state.customerMap = { ...state.customerMap, ...action.payload }
    },
    setIsRestricted: (state: IForumState, action: PayloadAction<boolean>) => {
      state.isRestricted = action.payload
    },
  },
})

export const {
  storeComments,
  newComment,
  editComment,
  writeComment,
  addCustomerToMap,
  updateCustomerMap,
  setIsRestricted,
} = dialogSlice.actions
export default dialogSlice.reducer
