import { useState, useEffect, useRef, Dispatch, SetStateAction, FC } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { AxiosError, AxiosResponse } from 'axios'
import { styled } from '@mui/system'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Dialog,
  DialogTitle,
  Fade,
  List,
  ListItem,
  TextField,
  Typography,
} from '@mui/material'
import { Delete, ExpandMore } from '@mui/icons-material'
import { useMediaQuery } from 'react-responsive'
import {
  storeComments,
  newComment,
  editComment,
  writeComment,
  addCustomerToMap,
  updateCustomerMap,
  setIsRestricted,
} from 'store/reducers/forum'
import { msToHours, secToHHMMSS } from 'utils/dateTimeConfig'
import AlertMsg from 'services/alertMsg'
import Discussion from 'services/api/discussion'
import { showLabel } from 'services/language'
import DotDotDotSrc from 'assets/icons/dotDotDot.svg'
import PencilSrc from 'assets/icons/pencil.svg'
import Close from 'assets/icons/close.svg'
import XSrc from 'assets/icons/x.svg'
import ReplySrc from 'assets/icons/reply.svg'
import type { RootState } from 'store/index'
import { ICommentPosition } from 'store/storeTypes'

const ForumSection = styled('section')({
  width: '100%',
  margin: 0,
  padding: '12px',
})
const DotsAndReplyButtons = styled('div')({
  height: '30px',
  color: '#ffffff',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'flex-end',
  alignItems: 'center',
})
const DotDotDot = styled('div')({
  height: '30px',
  margin: 0,
  padding: 0,
})
const DotDotDotIcon = styled('img')({
  width: '16px',
  height: '24px',
})
const ReplyIcon = styled('img')({
  width: '16px',
  height: '24px',
  marginLeft: '15px',
})
const AddCommentButton = styled(Button)({
  height: '100%',
  border: '2px solid #0497BB',
  backgroundColor: 'transparent',
  borderRadius: '10px',
  padding: '10px',
  color: '#0497BB',
  fontSize: '13px',
  textTransform: 'initial',
  letterSpacing: '1.5px',
  textAlign: 'center',
  verticalAlign: 'middle',
  '&:hover': {
    border: '2px solid #0497BB',
  },
})
const CommentTop = styled('div')({
  width: '100%',
})
const CommentBottom = styled('div')({
  width: '100%',
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'flex-end',
  padding: '20px 15px',
})
const ClearButton = styled(Button)({
  height: '44px',
  width: '150px',
  border: 'none',
  borderRadius: '8px',
  color: 'inherit',
  fontSize: '13px',
  letterSpacing: '1px',
  textTransform: 'initial',
  textAlign: 'center',
  verticalAlign: 'middle',
  marginBottom: '50px',
})
const SaveButton = styled(Button)({
  height: '44px',
  width: '150px',
  backgroundColor: '#1090B1',
  border: 'none',
  borderRadius: '8px',
  color: 'inherit',
  fontSize: '13px',
  letterSpacing: '1px',
  textTransform: 'initial',
  textAlign: 'center',
  verticalAlign: 'middle',
  marginBottom: '50px',
  '&:hover': {
    backgroundColor: '#1090B1',
  },
})
const ListItemAuthor = styled('div')({
  width: '100%',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  fontSize: '14px',
})
const Ago = styled('span')({
  color: '#737373',
  fontSize: '11px',
})
const CommentLine = styled('div')({
  width: '100%',
  margin: '3px 0',
})
const CommentAt = styled('button')({
  border: 'none',
  display: 'inline-block',
  minWidth: '75px',
  maxWidth: '100px',
  padding: '1px 0',
  backgroundColor: 'rgba(255, 255, 255, 0.7)',
  color: 'rgba(48, 47, 47, 0.84)',
  borderRadius: '6px',
  textAlign: 'center',
  fontSize: '14px',
  fontWeight: 500,
  lineHeight: '22px',
  letterSpacing: '0.46px',
  cursor: 'pointer',
})
const CommentText = styled('span')({
  color: '#d9d9d9',
  fontSize: '14px',
  marginLeft: '6px',
})
const DeletedComment = styled('span')({
  color: '#999999',
  fontStyle: 'oblique',
  fontSize: '14px',
})
const CommentReplyBox = styled(Accordion)({
  width: '',
  margin: 0,
  color: '#737373',
  fontSize: '12px',
})
const CommentReplyHead = styled(AccordionSummary)({
  margin: 0,
  padding: 0,
  minHeight: 0,
  flexDirection: 'row-reverse',
  '& .MuiAccordionSummary-content': {
    margin: 0,
    padding: 0,
  },
})
const ReplyCaret = styled(ExpandMore)({
  color: '#737373',
  fontSize: '14px',
})
const CommentReply = styled(AccordionDetails)({})
const ListElem = styled(ListItem)({
  width: '100%',
  padding: '5px 0',
})
const ListBox = styled(Box)({
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
})
const ReplyElem = styled('div')({
  marginTop: '8px',
  '&:first-of-type': {
    marginTop: 0,
  },
})
const EditButtons = styled('div')({
  height: '30px',
  color: '#ffffff',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'flex-end',
  alignItems: 'center',
})
const EditCommentButton = styled(Button)({
  maxWidth: '42px',
  minWidth: 0,
  height: '100%',
  margin: '0 6px 0 0',
  padding: 0,
  border: 'none',
  color: 'inherit',
  fontSize: '12px',
  textTransform: 'initial',
})
const PencilIcon = styled('img')({
  width: '20px',
  height: '20px',
})
const RemoveCommentButton = styled(Button)({
  maxWidth: '66px',
  minWidth: 0,
  height: '100%',
  margin: '0 6px 0 0',
  padding: 0,
  border: 'none',
  color: 'inherit',
  fontSize: '12px',
  textTransform: 'initial',
})
const TrashIcon = styled(Delete)({
  width: '15px',
  height: '15px',
  marginLeft: '2px',
})
const CancelCommentButton = styled(Button)({
  maxWidth: '65px',
  minWidth: 0,
  height: '100%',
  margin: 0,
  padding: 0,
  border: 'none',
  color: 'inherit',
  fontSize: '12px',
  textTransform: 'initial',
})
const XIcon = styled('img')({
  width: '13px',
  height: '13px',
  marginLeft: '3px',
})
const CommentDialog = styled(Dialog)({
  '& .MuiDialog-paper': {
    backdropFilter: 'blur(15px)',
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
  },
})
const CommentDialogTitle = styled(DialogTitle)({
  margin: 0,
  padding: '15px 15px',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
})
const TitleText = styled(Typography)({
  fontSize: '18px',
  marginTop: '30px',
})
const TitleBarIcon = styled('img')({
  width: '26px',
  height: '26px',
  marginLeft: '5px',
})
const CommentDialogBody = styled('div')({
  margin: 0,
  padding: '10px 15px',
})
const AddCommentSection = styled('section')({
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
})
const CommentField = styled(TextField)({
  backgroundColor: 'rgba(255, 255, 255, 0.2)',
  borderRadius: '8px',
  '& .Mui-focused .MuiOutlinedInput-notchedOutline': {
    border: 'none',
  },
  '& :hover .MuiOutlinedInput-notchedOutline': {
    border: 'none',
  },
})
const LetterCounter = styled('span')({
  width: '100%',
  fontSize: '11px',
  color: '#808080',
  textAlign: 'right',
})

function Forum(props: any) {
  const { seekTo, currentlyAtRef } = props

  const [mainDotsOpenAt, setMainDotsOpenAt] = useState<number | null>(null)
  const [replyDotsOpenAt, setReplyDotsOpenAt] = useState<string | null>(null)
  const [isCommentDialogOpen, setIsCommentDialogOpen] = useState<boolean>(false)
  const [isNewComment, setIsNewComment] = useState<boolean>(false)

  const learnObjItem = useSelector((state: RootState) => state.learningObject.item)
  const isPlaying = useSelector((state: RootState) => state.playback.isPlaying)
  const userId = useSelector((state: RootState) => state.userData.id)
  const comments = useSelector((state: RootState) => state.forum.comments)
  const customerMap = useSelector((state: RootState) => state.forum.customerMap)
  const isRestricted = useSelector((state: RootState) => state.forum.isRestricted)

  const dispatch = useDispatch()

  const isNarrowWidth = useMediaQuery({ query: '(max-width: 450px)' })

  const proceed = useRef<boolean>(true)
  const forumGET = useRef<boolean>(false)

  const commentAgo = (commentDate: string): string => {
    const time = msToHours(new Date().valueOf() - new Date(commentDate).valueOf())
    const hour = Math.floor(time)
    if (hour > 23) {
      return new Date(commentDate).toLocaleString()
    }
    const min = ((time - hour) * 60).toFixed(0)
    return hour ? `${hour} h and ${min} min ago` : `${min} min ago`
  }

  const calcCommentAt = (noteAt: number) => {
    const time = secToHHMMSS(noteAt)
    return time.startsWith('00') ? time.slice(3) : time
  }

  const removeComment = ({ commentIndex, parentIndex }: ICommentPosition) => {
    const commentId =
      parentIndex === undefined
        ? comments[commentIndex].id
        : comments[parentIndex].children[commentIndex].id

    Discussion.deleteComment(commentId)
      .then(() => {
        dispatch(
          storeComments(
            comments.map((comment: any, i: number) => {
              if (parentIndex === undefined) {
                if (commentIndex === i) {
                  const deletedComment = { ...comment }
                  deletedComment.deleted = 'Deleted'
                  return deletedComment
                }
              } else if (parentIndex === i) {
                const deletedParentComment = { ...comment }
                const deletedChildren = [...deletedParentComment.children]
                const deletedComment = { ...deletedChildren[commentIndex] }
                deletedComment.deleted = 'Deleted'
                deletedChildren[commentIndex] = deletedComment
                deletedParentComment.children = deletedChildren
                return deletedParentComment
              }
              return comment
            }),
          ),
        )
        AlertMsg.show({
          type: 'success',
          message: 'Your comment is deleted!',
        })
      })
      .catch((error: AxiosError) => {
        AlertMsg.show({
          type: 'error',
          message: error,
          context: "Can't delete your comment → ",
        })
      })
      .finally(() =>
        parentIndex === undefined ? setMainDotsOpenAt(null) : setReplyDotsOpenAt(null),
      )
  }

  const addNewComment = (commentPosition: ICommentPosition) => {
    setIsNewComment(true)
    dispatch(newComment(commentPosition))
    setIsCommentDialogOpen(true)
  }

  const openComment = (commentPosition: ICommentPosition) => {
    setIsNewComment(false)
    dispatch(editComment(commentPosition))
    setIsCommentDialogOpen(true)
  }

  const closeComment = () => {
    setIsCommentDialogOpen(false)
    dispatch(writeComment(null))
  }

  const displayEditButtons = (commentPosition: ICommentPosition) => (
    <EditButtons>
      <EditCommentButton variant="text" onPointerDown={() => openComment(commentPosition)}>
        {showLabel('lms_edit')}
        <PencilIcon src={PencilSrc} />
      </EditCommentButton>

      <RemoveCommentButton variant="text" onPointerDown={() => removeComment(commentPosition)}>
        {showLabel('lms_remove')}
        <TrashIcon />
      </RemoveCommentButton>

      <CancelCommentButton
        variant="text"
        onPointerDown={() => {
          commentPosition.parentIndex === undefined
            ? setMainDotsOpenAt(null)
            : setReplyDotsOpenAt(null)
        }}
      >
        {showLabel('lms_cancel')}
        <XIcon src={XSrc} />
      </CancelCommentButton>
    </EditButtons>
  )

  const displayDotDotAndReply = (index: number, authorId: string) => {
    const notDeleted =
      comments[index].deleted !== 'Deleted' && comments[index].deleted !== 'Moderated'
    const toDisplayDots = authorId === userId && notDeleted
    return (
      <DotsAndReplyButtons>
        {!isRestricted && toDisplayDots && (
          <DotDotDotIcon src={DotDotDotSrc} onPointerDown={() => setMainDotsOpenAt(index)} />
        )}
        {!isRestricted && notDeleted && (
          <ReplyIcon
            src={ReplySrc}
            onPointerDown={() => {
              addNewComment({
                commentIndex: 0,
                parentIndex: index,
                parentId: comments[index].id as string,
              })
            }}
          />
        )}
      </DotsAndReplyButtons>
    )
  }

  const displayReplyDotDot = (
    parentId: string,
    parentIndex: number,
    replyIndex: number,
    authorId: string,
  ) => {
    const toDisplayDots =
      !isRestricted &&
      authorId === userId &&
      comments[parentIndex] &&
      comments[parentIndex]?.deleted !== 'Deleted' &&
      comments[parentIndex].children?.[replyIndex]?.deleted !== 'Deleted'

    return (
      <DotDotDot>
        {toDisplayDots && (
          <DotDotDotIcon
            src={DotDotDotSrc}
            onPointerDown={() => setReplyDotsOpenAt(`${parentId}_${replyIndex}`)}
          />
        )}
      </DotDotDot>
    )
  }

  const handleComment = (comment: any) => {
    interface ICustomerIds {
      [key: string]: boolean
    }
    const customerIds: ICustomerIds = {}

    if (
      customerIds[comment.customerId] === undefined &&
      customerMap[comment.customerId] === undefined
    ) {
      customerIds[comment.customerId] = true
      return Discussion.getCustomerData(comment.customerId)
    } else {
      return Promise.resolve(null)
    }
  }

  const handleCommenterList = (commenterList: any) => {
    interface ICommenterNames {
      [key: string]: string | null
    }

    dispatch(
      updateCustomerMap(
        commenterList.reduce((commenterNames: ICommenterNames, commenter: any) => {
          if (commenter.status === 'rejected') {
            const commenterId = commenter.reason.config.url.replaceAll('/', '')
            commenterNames[commenterId] = null
          } else if (commenter.value?.data) {
            commenterNames[commenter.value.data.customerId] = commenter.value.data.username
          }
          return commenterNames
        }, {}),
      ),
    )
  }

  const handleCommentsData = (data: any) => {
    Promise.allSettled(data.pageContent.map((comment: any) => handleComment(comment)))
      .then((commenterList) => handleCommenterList(commenterList))
      .catch((error) => {
        AlertMsg.show({
          type: 'warning',
          message: error,
          context: "Can't load the commenter name → ",
        })
      })
      .finally(() => dispatch(storeComments(data.pageContent)))
  }

  useEffect(() => {
    if (!isRestricted && learnObjItem && !forumGET.current) {
      forumGET.current = true

      Discussion.getIsBanned()
        .then(() => {
          // if the user is banned the 'BanDiscussion' relation exists
          // response -> 200 OK
          dispatch(setIsRestricted(true))
          AlertMsg.show({
            type: 'warning',
            message: showLabel('lms_restricted_discussion'),
          })
        })
        .catch((error) => {
          if (error.response?.status !== 404) {
            // if the user is not banned the 'BanDiscussion' relation does not exist
            // response -> 404 Not Found
            proceed.current = false
            AlertMsg.show({
              type: 'error',
              message: error,
              context: "Can't load the related discussions → ",
            })
          }
        })
        .finally(() => {
          if (proceed.current) {
            Discussion.listComments(learnObjItem.id)
              .then((response: AxiosResponse) => {
                const data = response.data
                if (data.finished) {
                  if (data.pageContent) handleCommentsData(data)
                }
              })
              .catch((error: AxiosError) => {
                AlertMsg.show({
                  type: 'error',
                  message: error,
                  context: "Can't load the related discussions → ",
                })
              })
          }
        })
    }
  }, [])

  return (
    <ForumSection>
      <AddCommentButton
        variant="text"
        onPointerDown={() => !isRestricted && addNewComment({ commentIndex: 0 })}
        sx={{
          width: `${isNarrowWidth ? '100%' : '250px'}`,
          opacity: isRestricted ? 0.4 : 1,
        }}
      >
        {showLabel('lms_leave_comment')}
      </AddCommentButton>

      <List sx={{ width: '100%' }}>
        {comments.map((comment: any, commentIndex: number) => {
          let replyLabel = showLabel('lms_reply')
          const replyNumber = comment.children?.length
          if (replyNumber) {
            const replyNumberStr = replyNumber === 1 ? '(1)' : ''
            replyLabel =
              replyNumber < 2
                ? `${showLabel('lms_reply')} ${replyNumberStr}`
                : `${showLabel('lms_replies')} (${replyNumber})`
          }

          return (
            <ListElem key={`${comment.createdDate}_${commentIndex}`}>
              <ListBox>
                <ListItemAuthor>
                  <span>{customerMap[comment.customerId] || 'Unknown'}</span>
                  {mainDotsOpenAt === commentIndex ? (
                    <Fade in={mainDotsOpenAt === commentIndex} timeout={500}>
                      {displayEditButtons({ commentIndex })}
                    </Fade>
                  ) : (
                    displayDotDotAndReply(commentIndex, comment.customerId)
                  )}
                </ListItemAuthor>

                <Ago>{commentAgo(comment.createdDate)}</Ago>

                {comment.deleted === 'Live' ? (
                  <CommentLine>
                    <CommentAt onTouchStart={() => seekTo(comment.position, isPlaying)}>
                      {calcCommentAt(comment.position)}
                    </CommentAt>
                    <CommentText>{comment.payload}</CommentText>
                  </CommentLine>
                ) : (
                  <DeletedComment>This comment has been deleted!</DeletedComment>
                )}

                <CommentReplyBox
                  elevation={0}
                  square
                  disableGutters
                  style={{ backgroundColor: 'transparent' }}
                  disabled={comment.children === null || comment.children?.length < 1}
                >
                  <CommentReplyHead expandIcon={<ReplyCaret />}>
                    <span>{replyLabel}</span>
                  </CommentReplyHead>
                  <CommentReply>
                    {comment?.children
                      ? comment?.children?.map((reply: any, replyIndex: number) => {
                          return (
                            <ReplyElem key={`${reply.createdDate}_${replyIndex}`}>
                              <ListBox>
                                <ListItemAuthor>
                                  <span>{customerMap[reply.customerId] || 'Unknown'}</span>
                                  {replyDotsOpenAt === `${comment.id}_${replyIndex}` ? (
                                    <Fade
                                      in={replyDotsOpenAt === `${comment.id}_${replyIndex}`}
                                      timeout={500}
                                    >
                                      {displayEditButtons({
                                        commentIndex: replyIndex,
                                        parentIndex: commentIndex,
                                        parentId: reply.parentId,
                                      })}
                                    </Fade>
                                  ) : (
                                    displayReplyDotDot(
                                      comment.id,
                                      commentIndex,
                                      replyIndex,
                                      reply.customerId,
                                    )
                                  )}
                                </ListItemAuthor>

                                <Ago>{commentAgo(reply.createdDate)}</Ago>
                                {reply.deleted === 'Live' ? (
                                  <CommentLine>
                                    <CommentAt
                                      onTouchStart={() => seekTo(reply.position, isPlaying)}
                                    >
                                      {calcCommentAt(reply.position)}
                                    </CommentAt>
                                    <CommentText>{reply.payload}</CommentText>
                                  </CommentLine>
                                ) : (
                                  <DeletedComment>This comment has been deleted!</DeletedComment>
                                )}
                              </ListBox>
                            </ReplyElem>
                          )
                        })
                      : null}
                  </CommentReply>
                </CommentReplyBox>
              </ListBox>
            </ListElem>
          )
        })}
      </List>

      <CommentDialog fullScreen fullWidth open={isCommentDialogOpen} onClose={() => closeComment()}>
        <ForumForm
          isNarrowWidth={isNarrowWidth}
          isNewComment={isNewComment}
          closeComment={closeComment}
          setMainDotsOpenAt={setMainDotsOpenAt}
          currentlyAt={currentlyAtRef.current}
        />
      </CommentDialog>
    </ForumSection>
  )
}

interface ForumFormPropTypes {
  isNarrowWidth: boolean
  isNewComment: boolean
  closeComment(): void
  setMainDotsOpenAt: Dispatch<SetStateAction<number | null>>
  currentlyAt: number
}

const ForumForm: FC<ForumFormPropTypes> = (props: ForumFormPropTypes) => {
  const { isNarrowWidth, isNewComment, closeComment, setMainDotsOpenAt, currentlyAt } = props

  const [formComment, setFormComment] = useState({
    id: '',
    commentIndex: 0,
    targetType: 'Content',
    payload: '',
    customerId: '',
    providerId: '',
    contentId: '',
    deleted: '',
    createdDate: '',
    updatedDate: '',
    parentId: '',
    parentIndex: undefined,
  })

  const learnObjItem = useSelector((state: RootState) => state.learningObject.item)
  const customerMap = useSelector((state: RootState) => state.forum.customerMap)
  const comments = useSelector((state: RootState) => state.forum.comments)
  const commentBeingEdited = useSelector((state: RootState) => state.forum.commentBeingEdited)

  const dispatch = useDispatch()

  const calcLettersLeft = (): string => {
    const lettersLeftNumb = 420 - (formComment?.payload?.length || 0)
    const lettersLeftText =
      lettersLeftNumb > 1
        ? showLabel('lms_characters_remaining')
        : showLabel('lms_character_remaining')
    return `${lettersLeftNumb} ${lettersLeftText}`
  }

  const clearComment = () => {
    setFormComment(() => ({ ...formComment, payload: '' }))
  }

  const saveComment = () => {
    if (formComment.id === '') {
      Discussion.addComment({
        payload: formComment.payload,
        targetId: learnObjItem!.id,
        position: Math.round(currentlyAt),
        providerId: formComment.providerId,
        parentId: formComment.parentId,
      })
        .then(async (response: AxiosResponse) => {
          const { parentIndex } = formComment
          if (parentIndex === undefined) {
            dispatch(storeComments([response.data, ...comments]))
          } else {
            dispatch(
              storeComments(
                comments.map((comment: any, i: number) => {
                  if (i === parentIndex) {
                    const newComment = { ...comment }
                    if (!newComment.children) {
                      newComment.children = []
                    }
                    const newChildren = [response.data, ...newComment.children]
                    newComment.children = newChildren
                    return newComment
                  }
                  return comment
                }),
              ),
            )
          }
          AlertMsg.show({
            type: 'success',
            message: 'Your comment is submitted!',
          })
          if (response?.data?.customerId && !customerMap[response.data.customerId]) {
            try {
              const customerResponse: any = await Discussion.getCustomerData(
                response.data.customerId,
              )
              dispatch(
                addCustomerToMap({
                  id: customerResponse.data.customerId,
                  name: customerResponse.data.username,
                }),
              )
            } catch (error) {
              AlertMsg.show({
                type: 'error',
                message: error,
                context: "Can't retrieve commenter's name → ",
              })
            }
          }
        })
        .catch((error: AxiosError) => {
          AlertMsg.show({
            type: 'error',
            message: error,
            context: "Can't save your comment → ",
          })
        })
        .finally(() => {
          closeComment()
          setMainDotsOpenAt(null)
        })
    } else {
      Discussion.updateComment(formComment.id, formComment.payload)
        .then((response: AxiosResponse) => {
          const { commentIndex, parentIndex } = formComment
          dispatch(
            storeComments(
              comments.map((comment: any, i: number) => {
                if (parentIndex === undefined) {
                  if (commentIndex === i) return response.data
                } else if (parentIndex === i) {
                  const newComment = { ...comment }
                  const newChildren = [...newComment.children]
                  newChildren[commentIndex] = response.data
                  newComment.children = newChildren
                  return newComment
                }
                return comment
              }),
            ),
          )
          AlertMsg.show({
            type: 'success',
            message: 'Your comment is updated!',
          })
        })
        .catch((error: AxiosError) => {
          AlertMsg.show({
            type: 'error',
            message: error,
            context: "Can't update your comment → ",
          })
        })
        .finally(() => {
          closeComment()
          setMainDotsOpenAt(null)
        })
    }
  }

  useEffect(() => {
    if (commentBeingEdited) {
      setFormComment(() => ({
        ...commentBeingEdited,
        id: commentBeingEdited.id === null ? '' : commentBeingEdited.id,
      }))
    } else {
      setFormComment({
        id: '',
        commentIndex: 0,
        targetType: 'Content',
        payload: '',
        customerId: '',
        providerId: '',
        contentId: '',
        deleted: '',
        createdDate: '',
        updatedDate: '',
        parentId: '',
        parentIndex: undefined,
      })
    }
  }, [commentBeingEdited])

  return (
    <>
      <CommentTop>
        <CommentDialogTitle>
          <TitleText>
            {' '}
            {isNewComment ? showLabel('lms_leave_comment') : showLabel('lms_edit_comment')}{' '}
          </TitleText>
          <TitleBarIcon
            src={Close}
            alt="Close icon of the Comment modal"
            onPointerDown={() => closeComment()}
          />
        </CommentDialogTitle>

        <CommentDialogBody>
          <AddCommentSection>
            <CommentField
              placeholder={showLabel('lms_comment_tooltip')}
              value={formComment.payload}
              variant="outlined"
              type="text"
              margin="normal"
              multiline
              rows={10}
              inputProps={{ maxLength: 420 }}
              fullWidth
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                setFormComment((formComment) => {
                  return { ...formComment, payload: (event.target as HTMLInputElement).value }
                })
              }}
            />
            <LetterCounter>{calcLettersLeft()}</LetterCounter>
          </AddCommentSection>
        </CommentDialogBody>
      </CommentTop>

      <CommentBottom sx={{ justifyContent: `${isNarrowWidth ? 'space-between' : 'center'}` }}>
        <ClearButton variant="text" onPointerDown={clearComment}>
          {showLabel('lms_clear')}
        </ClearButton>

        <SaveButton variant="contained" onPointerDown={saveComment}>
          {showLabel('lms_save')}
        </SaveButton>
      </CommentBottom>
    </>
  )
}

export default Forum
