import axios, { AxiosResponse, AxiosPromise } from 'axios'
import { store } from 'store'

interface IFind {
  baseURL: string
  search(
    contentId: string,
    keyWord: string,
    appLanguage: string,
    isPreview: boolean,
  ): AxiosPromise<AxiosResponse>
}

const Find: IFind = {
  baseURL: store.getState().apiURLs.appBase,

  search(contentId, keyWord, appLanguage, isPreview) {
    return axios({
      baseURL: this.baseURL,
      url: isPreview
        ? `/App/Content/no-cache/Search/${contentId}?keyword=${keyWord}&lang=${appLanguage}`
        : `/App/Content/Search/${contentId}?keyword=${keyWord}&lang=${appLanguage}`,
      method: 'get',
    })
  },
}

export default Find
