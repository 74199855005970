import { useCallback, MouseEvent, SyntheticEvent } from 'react'
import styled from '@emotion/styled'
import { Box, List, ListItemButton, Popover } from '@mui/material'
import { useSelector } from 'react-redux'

import PlayPauseButton from 'components/PlayPauseButton'
import ProgressBar from '../../DeskProgBar'
import PlaybackSpeed from '../../components/PlaybackSpeed'

import { secToHHMMSS } from 'utils/dateTimeConfig'

import type { RootState } from 'store/index'
import { IMediaTrack } from 'store/storeTypes'

import RewindIcon from 'assets/icons/rewing.svg'
import ForwardIcon from 'assets/icons/forward.svg'

type PlayerControlsProps = {
  currentlyAtRef: React.MutableRefObject<number>
  showCPanel: (
    isTemporary: boolean,
    keyEvent?: SyntheticEvent<HTMLDivElement, Event> | undefined,
  ) => void
  seekTo: (
    toWhere: number,
    isPlaying?: boolean | undefined,
    fromContentId?: string | undefined,
  ) => void
  progressAt: any
  setProgressAt: any
  pressPlayPause: (toPlay: boolean, vidInd?: number | undefined) => any
  fullScreenVideoElem: any
  openSpPicker: (event: MouseEvent<HTMLButtonElement>) => void
  popoverId: string | undefined
  isSpeedPopUp: boolean
  anchorElem: any
  closeSpPicker: () => void
  handleSpeedPick: (event: SyntheticEvent<HTMLDivElement>) => void
}

const PlayerControlsMobile = ({
  currentlyAtRef,
  showCPanel,
  seekTo,
  progressAt,
  setProgressAt,
  pressPlayPause,
  fullScreenVideoElem,
  openSpPicker,
  popoverId,
  isSpeedPopUp,
  anchorElem,
  closeSpPicker,
  handleSpeedPick,
}: PlayerControlsProps) => {
  const pbSpeeds = useSelector((state: RootState) => state.playback.pbSpeeds)
  const mediaTracks: IMediaTrack[] = useSelector(
    (state: RootState) => state.learningObject.mediaTracks,
  )
  const currentlyAt = useSelector((state: RootState) => state.playback.currentlyAt)
  const mediaDuration = useSelector((state: RootState) => state.playback.mediaDuration)

  const video =
    mediaTracks?.find((track) => track.contentTrack) || mediaTracks?.[mediaTracks?.length - 1]

  const displayTimers = useCallback(
    (isFirst: boolean) => {
      currentlyAtRef.current = currentlyAt

      let durationTime

      if (!currentlyAt) {
        durationTime = mediaDuration
      } else if (currentlyAt >= mediaDuration) {
        durationTime = 0
      } else {
        durationTime = mediaDuration - currentlyAt
      }

      const HHMMSScurrentlyAt = secToHHMMSS(currentlyAt)
      const HHMMSSMediaDuration = secToHHMMSS(durationTime)

      if (HHMMSSMediaDuration.startsWith('00')) {
        return isFirst ? HHMMSScurrentlyAt.slice(3) : `-${HHMMSSMediaDuration.slice(3)}`
      }

      return isFirst ? HHMMSScurrentlyAt : `-${HHMMSSMediaDuration}`
    },
    [currentlyAt, mediaDuration],
  )

  return (
    <VideoControls>
      <BoxSeekButton>
        <PPButton
          key={video?.id}
          src={RewindIcon}
          alt=""
          onPointerDown={(event: SyntheticEvent) => {
            event.stopPropagation()
            if (currentlyAt - 10 > 0) seekTo(currentlyAt - 10)
            else seekTo(0)
          }}
        />
      </BoxSeekButton>

      <BoxSeekButton>
        <PPButton
          key={video?.id}
          src={ForwardIcon}
          alt=""
          onPointerDown={(event: SyntheticEvent) => {
            event.stopPropagation()
            seekTo(currentlyAt + 10)
          }}
        />
      </BoxSeekButton>

      <PlayPauseBox>
        <PlayPauseButton pressPlayPause={pressPlayPause} videoId={video?.id} isMobile />
      </PlayPauseBox>

      <TimersBlock style={{ marginRight: '4px' }}>{displayTimers(true)}</TimersBlock>

      <ProgressBar
        showCPanel={showCPanel}
        seekTo={seekTo}
        progressAt={progressAt}
        setProgressAt={setProgressAt}
        isMobile
      />

      <TimersBlock>{displayTimers(false)}</TimersBlock>

      {fullScreenVideoElem === '' && (
        <>
          <PopOverMenu
            id={popoverId}
            open={isSpeedPopUp}
            anchorEl={anchorElem}
            onClose={closeSpPicker}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'center',
            }}
            transformOrigin={{
              vertical: 'bottom',
              horizontal: 'center',
            }}
          >
            <PlaybackSpeedList id="playback-speed-list">
              {pbSpeeds?.map((pbSpeed: number, i: number) => (
                <PlaybackSpeedListButton key={'pbSpeed_' + i} onPointerDown={handleSpeedPick}>
                  {`${pbSpeed}x`}
                </PlaybackSpeedListButton>
              ))}
            </PlaybackSpeedList>
          </PopOverMenu>
          <PlaybackSpeed openSpPicker={openSpPicker} popoverId={popoverId} isMobile />
        </>
      )}
    </VideoControls>
  )
}

export default PlayerControlsMobile

const PlayPauseBox = styled(Box)({
  width: '24px',
  height: '24px',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
})
const PopOverMenu = styled(Popover)({
  backgroundColor: 'transparent',
  fontSize: '12px',
  '& .MuiPaper-root': {
    backgroundColor: 'transparent !important',
  },
})
const PlaybackSpeedList = styled(List)({
  '&.MuiList-root': {
    backgroundColor: '#333333',
  },
})
const PlaybackSpeedListButton = styled(ListItemButton)({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  alignItems: 'center',
  backgroundColor: '#333333',
  '&:hover': {
    backgroundColor: '#4d4d4d',
  },
})

const BoxSeekButton = styled(Box)({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
})

const PPButton = styled('img')({
  width: '20px',
  height: '20px',
  cursor: 'pointer',
})

const TimersBlock = styled(Box)({
  fontSize: '12px',
})

const VideoControls = styled(Box)({
  width: '100%',
  height: '100%',
  zIndex: 500,
  margin: 0,
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  flexDirection: 'row',
  padding: '8px 10px 8px 10px',
  backgroundColor: '#353535',
  borderRadius: '10px',
  gap: '5px',
})
