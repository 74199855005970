import { useState, useEffect, useCallback, MouseEvent, SyntheticEvent } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import screenfull from 'screenfull'
import { styled } from '@mui/system'
import { Box, Button, Fade, List, ListItemButton, Popover } from '@mui/material'
import {
  Fullscreen as FullscreenIcon,
  FullscreenExit as FullscreenExitIcon,
} from '@mui/icons-material'
import AudioSubtSidebar from '../AudioSubtSidebar'
import BouncingLoader from 'components/BouncingLoader'
import PositionDuration from 'components/PositionDuration'
import SpentTimeDisplay from 'components/SpentTimeDisplay'
import PlayPauseButton from 'components/PlayPauseButton'
import {
  setFullScreenVideoElem,
  showAbortModal,
  toggleAudioSubtSidebar,
  toggleVideoLoader,
} from 'store/reducers/componentDisplay'
import SubtitleOverlay from 'components/SubtitleOverlay'
import { changePlaybackSpeed, signalContentEnded } from 'store/reducers/playback'
import ProgressBar from '../PortProgBar'
import AlertMsg from 'services/alertMsg'
import SubAud from 'assets/icons/subAud.svg'
import type { RootState } from 'store/index'
import { IMediaTrack } from 'store/storeTypes'

const VideosFrame = styled('div')({
  height: '100%',
  width: '100%',
  display: 'flex',
  justifyContent: 'space-between',
})
const VideosContainer = styled('section')({
  display: 'flex',
})
const VideoBox = styled(Box)({
  width: '100%',
  height: '100%',
  minWidth: '100%',
  position: 'relative',
  zIndex: 100,
  margin: '0 0 2% 0',
  '&:last-child': {
    margin: 0,
  },
  padding: 0,
})
const Video = styled('video')({
  width: '100%',
  height: '100%',
  position: 'absolute',
  zIndex: 200,
  borderRadius: '10px',
  objectFit: 'cover',
})
const VideoControls = styled(Box)({
  width: '100%',
  height: '100%',
  margin: 0,
  padding: '10px',
  position: 'absolute',
  zIndex: 500,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  borderRadius: '10px',
  backgroundColor: 'rgba(0, 0, 0, 0.4)',
})
const ControlsTop = styled('section')({
  width: '100%',
  height: '35%',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'flex-start',
})
const TimeBox = styled(Box)({
  width: '50%',
  height: '30px',
  textAlign: 'left',
  padding: 0,
  margin: 0,
})
const ControlsTopRight = styled('div')({
  width: '50%',
  height: '30px',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'flex-end',
  alignItems: 'center',
})
const NavBarButton = styled(Button)({
  padding: 0,
  minWidth: 0,
  color: 'transparent',
})
const NavBarIcon = styled('img')({
  width: '33px',
  height: '33px',
  marginLeft: '6px',
})
const ControlsMiddle = styled('section')({
  width: '100%',
  height: '30%',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  alignItems: 'center',
})
const PlayPauseBox = styled(Box)({
  width: '15%',
  maxWidth: '45px',
  height: '100%',
  maxHeight: '45px',
  padding: 0,
  margin: 0,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
})
const ControlsBottom = styled('section')({
  width: '100%',
  height: '35%',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'flex-end',
})
const ProgressBox = styled(Box)({
  width: '98%',
})
const BottomButtons = styled(Box)({
  width: '100%',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
})
const LeftBB = styled('div')({
  width: '50%',
  height: '100%',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'flex-start',
  alignItems: 'center',
})
const RightBB = styled('div')({
  width: '50%',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'flex-end',
  alignItems: 'center',
})
const PopOverButton = styled(Button)({
  width: '33px',
  height: '25px',
  minWidth: '38px',
  fontSize: '12px',
  textAlign: 'center',
  marginRight: '5px',
  textTransform: 'lowercase',
})
const PopOverMenu = styled(Popover)({
  backgroundColor: 'transparent',
  fontSize: '12px',
})
const PlaybackSpeedList = styled(List)({
  '&.MuiList-root': {
    backgroundColor: '#333333',
    border: '1px solid #333333',
  },
})
const PlaybackSpeedListButton = styled(ListItemButton)({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  alignItems: 'center',
  backgroundColor: '#333333',
  '&:hover': {
    backgroundColor: '#4d4d4d',
  },
})
const FullScreenButton = styled(Box)({
  width: '30px',
  height: '30px',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  alignItems: 'center',
  cursor: 'pointer',
})

function PlaylistPortVids(props: any) {
  const {
    VideoRefs,
    videosContainerRef,
    videoBoxRefs,
    showCPanel,
    currentlyAtRef,
    isShakaInstalledOk,
    shakaInstances,
    subtitleRefs,
    displaySubs,
    pressPlayPause,
    seekTo,
    initOneTrack,
    plstPlaybackStateChange,
    updateCurrentTime,
  } = props

  const [anchorElem, setAnchorElem] = useState<null | HTMLElement>(null)

  const mediaTracks: IMediaTrack[] = useSelector(
    (state: RootState) => state.learningObject.mediaTracks,
  )
  const headVideoId = useSelector((state: RootState) => state.componentDisplay.headVideoId)
  const headVideoIndex = useSelector((state: RootState) => state.componentDisplay.headVideoIndex)
  const isVidControlPanelOn = useSelector(
    (state: RootState) => state.componentDisplay.isVidControlPanelOn,
  )
  const isPlaying = useSelector((state: RootState) => state.playback.isPlaying)
  const isUserSeeking = useSelector((state: RootState) => state.playback.isUserSeeking)
  const isVideoLoading = useSelector((state: RootState) => state.componentDisplay.isVideoLoading)
  const subtitleLang = useSelector((state: RootState) => state.playback.subtitleLang)
  const playbackSpeed = useSelector((state: RootState) => state.playback.playbackSpeed)
  const pbSpeeds = useSelector((state: RootState) => state.playback.pbSpeeds)
  const fullScreenVideoElem = useSelector(
    (state: RootState) => state.componentDisplay.fullScreenVideoElem,
  )
  const isPortraitView = useSelector((state: RootState) => state.componentDisplay.isPortraitView)
  const screenHeight = useSelector((state: RootState) => state.componentDisplay.screenHeight)
  const hasContentEnded = useSelector((state: RootState) => state.playback.hasContentEnded)
  const browser = useSelector((state: RootState) => state.deviceInfo.browser)
  const isAbortModal = useSelector((state: RootState) => state.componentDisplay.isAbortModal)

  const dispatch = useDispatch()

  const makeVideosContainerStyles = useCallback(() => {
    return {
      width: '100%',
      height: isPortraitView ? '35vh' : `${screenHeight}px`,
      overflowX: 'auto',
      overflowY: 'hidden',
      scrollSnapType: 'x mandatory',
      scrollSnapStop: 'always',
      whiteSpace: 'nowrap',
      flexDirection: 'row',
    }
  }, [isPortraitView, screenHeight])

  const makeVideoBoxStyles = useCallback(() => {
    return {
      display: 'inline-block',
      height: '100%',
      minHeight: '100%',
      scrollSnapAlign: 'center',
    }
  }, [])

  const handleShakaError = (event: any): void => {
    AlertMsg.show({
      type: 'error',
      message: event?.detail?.code,
      context: 'Shaka Player error → ',
    })
  }

  const openSpPicker = (event: MouseEvent<HTMLButtonElement>): void => {
    setAnchorElem(event.currentTarget)
  }
  const closeSpPicker = () => setAnchorElem(null)
  const isSpeedPopUp = !!anchorElem
  const popoverId = isSpeedPopUp ? 'playback-speed-selector-popover' : undefined

  const handleSpeedPick = (event: SyntheticEvent<HTMLDivElement>): void => {
    const pbSpeed = parseFloat((event.target as HTMLDivElement).textContent as string)
    dispatch(changePlaybackSpeed(pbSpeed))

    VideoRefs.current.forEach((video: HTMLVideoElement) => {
      video.playbackRate = pbSpeed
    })
  }

  const toggleFullScreen = (vidInd: number) => {
    if (screenfull.isEnabled && videoBoxRefs.current[vidInd]) {
      if (screenfull.element === undefined) {
        screenfull
          .request(videoBoxRefs.current[vidInd])
          .then(() => dispatch(setFullScreenVideoElem(mediaTracks[vidInd].id)))
          .catch(() => {
            AlertMsg.show({
              type: 'warning',
              message: "Can't enter fullscreen mode!",
            })
          })
      } else {
        screenfull
          ?.exit()
          ?.then(() => {
            VideoRefs.current[vidInd]?.scrollIntoView({
              behavior: 'instant',
              block: 'nearest',
              inline: 'nearest',
            })
            dispatch(setFullScreenVideoElem(''))
          })
          .catch(() => {
            AlertMsg.show({
              type: 'warning',
              message: "Can't exit fullscreen mode!",
            })
          })
      }
    }
  }

  useEffect(() => {
    let shakaRefs: any[] = []

    if (mediaTracks.length && isShakaInstalledOk.current && !shakaInstances.current.length) {
      shakaInstances.current = new Array(mediaTracks.length).fill(null)

      dispatch(toggleVideoLoader({ vidInd: -1, toLoad: true }))
      initOneTrack(mediaTracks, 0, VideoRefs.current[0])
        .then(() => {
          window.shakaPlayers = shakaInstances.current
          shakaRefs = shakaInstances.current
        })
        .catch((error: any) => {
          AlertMsg.show({ type: 'error', message: error })
          if (!isAbortModal.on) dispatch(showAbortModal('playabort'))
        })
        .finally(() => dispatch(toggleVideoLoader({ vidInd: -1, toLoad: false })))
    }

    return () => {
      shakaRefs.forEach((shaka) => shaka.removeEventListener('error', handleShakaError))
    }
  }, [mediaTracks])

  useEffect(() => {
    const forceFsButtonChange = () => {
      if (!screenfull.isFullscreen && fullScreenVideoElem) {
        dispatch(setFullScreenVideoElem(''))
      }
    }

    if (screenfull.isEnabled) {
      screenfull.on && screenfull.on('change', forceFsButtonChange)
    }
    return () => {
      screenfull.off && screenfull.off('change', forceFsButtonChange)
    }
  }, [fullScreenVideoElem, headVideoIndex])

  useEffect(() => {
    displaySubs(subtitleLang)
  }, [subtitleLang])

  useEffect(() => {
    if (hasContentEnded) {
      const nextInPlaylistIndex = headVideoIndex + 1
      if (nextInPlaylistIndex < mediaTracks.length && isPlaying) {
        pressPlayPause(!isPlaying, nextInPlaylistIndex)
        VideoRefs.current[headVideoIndex].currentTime = 0
        VideoRefs.current[nextInPlaylistIndex].scrollIntoView({
          behavior: 'smooth',
          block: 'nearest',
          inline: 'nearest',
        })
      }
    }
    dispatch(signalContentEnded(false))
  }, [hasContentEnded])

  return (
    <>
      <VideosFrame>
        <VideosContainer ref={videosContainerRef} sx={makeVideosContainerStyles()}>
          {mediaTracks.map((video: any, vidInd: number) => (
            <VideoBox
              id={`VideoBox_${video.id}`}
              key={`VideoBox_${video.id}`}
              ref={(element: HTMLDivElement) => {
                videoBoxRefs.current[vidInd] = element
              }}
              sx={makeVideoBoxStyles()}
              onTouchStart={() => {
                if (
                  (video.id === headVideoId && !isUserSeeking && isPlaying) ||
                  (!isUserSeeking && isPlaying)
                ) {
                  showCPanel(true)
                }
              }}
            >
              <Video
                id={video.id}
                ref={(element: HTMLVideoElement) => {
                  VideoRefs.current[vidInd] = element
                }}
                crossOrigin="anonymous"
                preload="metadata"
                poster={video.poster}
                playsInline
                onTimeUpdate={() => updateCurrentTime(vidInd)}
                onRateChange={() => plstPlaybackStateChange(vidInd, 'speed', true)}
                onWaiting={() => plstPlaybackStateChange(vidInd, 'waiting', true)}
                onSeeking={() => plstPlaybackStateChange(vidInd, 'seeking', true)}
                onSeeked={() => plstPlaybackStateChange(vidInd, 'seeked', false)}
                onPlay={() => plstPlaybackStateChange(vidInd, 'play', false)}
                onPlaying={() => plstPlaybackStateChange(vidInd, 'playing', false)}
                onPause={() => plstPlaybackStateChange(vidInd, 'pause', false)}
                onError={() => plstPlaybackStateChange(vidInd, 'error', false)}
                onAbort={() => plstPlaybackStateChange(vidInd, 'aborted', false)}
                onEnded={() => {
                  plstPlaybackStateChange(vidInd, 'ended', false)
                  dispatch(signalContentEnded(true))
                }}
              >
                {video.subtitles?.length > 1 &&
                  video.subtitles
                    .slice(1)
                    .map((subtitle: any) => (
                      <track
                        key={`${video.id}-textTrack-${subtitle.locale}`}
                        id={`${subtitle.locale}`}
                        kind="subtitles"
                        src={subtitle.url}
                        srcLang={subtitle.locale.slice(0, 2)}
                        label={subtitle.label}
                      />
                    ))}
              </Video>

              <SubtitleOverlay video={video} vidInd={vidInd} subtitleRefs={subtitleRefs} />

              <Fade in={isVidControlPanelOn || isVideoLoading[vidInd]} timeout={500}>
                <VideoControls>
                  <ControlsTop>
                    <TimeBox>
                      {headVideoIndex === vidInd ? (
                        <PositionDuration currentlyAtRef={currentlyAtRef} />
                      ) : null}
                    </TimeBox>

                    {!isPortraitView && (
                      <ControlsTopRight>
                        <SpentTimeDisplay />

                        <NavBarButton
                          variant="text"
                          onPointerDown={(event: SyntheticEvent) => {
                            event.preventDefault()
                            event.stopPropagation()
                            dispatch(toggleAudioSubtSidebar(true))
                          }}
                        >
                          <NavBarIcon src={SubAud} alt="Subtile + Audio selector modal" />
                        </NavBarButton>
                      </ControlsTopRight>
                    )}
                  </ControlsTop>

                  <ControlsMiddle>
                    {isVideoLoading[vidInd] ? (
                      <BouncingLoader position="absolute" top={0} zIndex={101} />
                    ) : (
                      <PlayPauseBox>
                        <PlayPauseButton
                          pressPlayPause={pressPlayPause}
                          vidInd={vidInd}
                          videoId={video.id}
                        />
                      </PlayPauseBox>
                    )}
                  </ControlsMiddle>

                  <ControlsBottom>
                    <ProgressBox>
                      {video.id === headVideoId ? (
                        <ProgressBar
                          videosContainerRef={videosContainerRef}
                          showCPanel={showCPanel}
                          seekTo={seekTo}
                        />
                      ) : null}
                    </ProgressBox>

                    <BottomButtons>
                      <LeftBB></LeftBB>

                      <RightBB>
                        {fullScreenVideoElem === '' && (
                          <>
                            <PopOverButton
                              aria-describedby={popoverId}
                              variant="outlined"
                              color="inherit"
                              onClick={openSpPicker}
                            >
                              {`${playbackSpeed}x`}
                            </PopOverButton>
                            <PopOverMenu
                              id={popoverId}
                              open={isSpeedPopUp}
                              anchorEl={anchorElem}
                              onClose={closeSpPicker}
                              anchorOrigin={{
                                vertical: 'top',
                                horizontal: 'center',
                              }}
                              transformOrigin={{
                                vertical: 'bottom',
                                horizontal: 'center',
                              }}
                            >
                              <PlaybackSpeedList id="playback-speed-list">
                                {pbSpeeds.map((pbSpeed: number, i: number) => (
                                  <PlaybackSpeedListButton
                                    key={'pbSpeed_' + i}
                                    onPointerDown={handleSpeedPick}
                                  >
                                    {`${pbSpeed}x`}
                                  </PlaybackSpeedListButton>
                                ))}
                              </PlaybackSpeedList>
                            </PopOverMenu>
                          </>
                        )}

                        {!browser?.name?.includes('Safari') && (
                          <FullScreenButton
                            onTouchEnd={() => {
                              toggleFullScreen(vidInd)
                            }}
                          >
                            {fullScreenVideoElem ? (
                              <FullscreenExitIcon fontSize="large" />
                            ) : (
                              <FullscreenIcon fontSize="large" />
                            )}
                          </FullScreenButton>
                        )}
                      </RightBB>
                    </BottomButtons>
                  </ControlsBottom>
                </VideoControls>
              </Fade>
            </VideoBox>
          ))}
        </VideosContainer>
      </VideosFrame>

      <AudioSubtSidebar />
    </>
  )
}

export default PlaylistPortVids
