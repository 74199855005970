const hexToRGB = (colorHex: string): string => {
  if (/^#[abcdef0-9]{6}$/.test(colorHex)) {
    const r = `${parseInt(colorHex.slice(1, 3), 16)}`
    const g = `${parseInt(colorHex.slice(3, 5), 16)}`
    const b = `${parseInt(colorHex.slice(5, 7), 16)}`
    return `${r}, ${g}, ${b}`
  } else {
    return '0, 0, 0'
  }
}

export default hexToRGB
