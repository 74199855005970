import axios, { AxiosResponse, AxiosPromise } from 'axios'
import { store } from 'store'

type TAddCommentParams = {
  payload: string
  targetId: string
  position: number
  parentId?: string
  providerId?: string
}

interface IDiscussion {
  baseURL: string
  customerURL: string
  discussionURL: string
  getOneComment(entryId: string): AxiosPromise<AxiosResponse>
  listComments(targetId: string, providerId?: string): AxiosPromise<AxiosResponse>
  addComment(addCommentParams: TAddCommentParams): AxiosPromise<AxiosResponse>
  updateComment(payload: string, entryId: string): AxiosPromise<AxiosResponse>
  deleteComment(entryId: string): AxiosPromise<AxiosResponse>
  getCustomerData(customerId: string): AxiosPromise<AxiosResponse>
  getCustomerEnums(): AxiosPromise<AxiosResponse>
  getIsBanned(targetId?: string): AxiosPromise<AxiosResponse>
}

const Discussion: IDiscussion = {
  baseURL: store.getState().apiURLs.discussion,
  customerURL: store.getState().apiURLs.customer,
  discussionURL: `${store.getState().apiURLs.discussion}/Discussion`,

  getOneComment(entryId) {
    return axios({
      baseURL: this.discussionURL,
      url: `/${entryId}`,
      method: 'get',
    })
  },

  listComments(targetId, providerId) {
    return axios({
      baseURL: this.discussionURL,
      url: `/List/Content/${targetId}${
        providerId ? '/' + providerId : ''
      }?itemLimit=1000&descending=true`,
      method: 'get',
    })
  },

  addComment({ payload, targetId, parentId, providerId, position }) {
    return axios({
      baseURL: this.discussionURL,
      url: '',
      method: 'post',
      data: {
        targetType: 'Content',
        targetId,
        providerId,
        payload,
        parentId,
        position,
      },
    })
  },

  updateComment(entryId, payload) {
    return axios({
      baseURL: this.discussionURL,
      url: `/${entryId}`,
      method: 'put',
      data: { payload },
    })
  },

  deleteComment(entryId) {
    return axios({
      baseURL: this.discussionURL,
      url: `/${entryId}`,
      method: 'delete',
    })
  },

  getCustomerData(customerId) {
    return axios({
      baseURL: this.customerURL,
      url: `/${customerId}`,
      method: 'get',
    })
  },

  getCustomerEnums() {
    return axios({
      baseURL: this.baseURL,
      url: '/api/v2.0/Enums/Customer',
      method: 'get',
    })
  },

  getIsBanned(targetId) {
    return axios({
      baseURL: this.baseURL,
      url: `/api/v2.0/Relation/BanDiscussion/${targetId ?? '00000000-0000-0000-0000-000000000000'}`,
      method: 'get',
    })
  },
}

export default Discussion
