import axios, { AxiosResponse, AxiosPromise } from 'axios'
import { store } from 'store'

interface IMarkers {
  markersURL: string
  search(contentId: string): AxiosPromise<AxiosResponse>
}

const Markers: IMarkers = {
  markersURL: `${store.getState().apiURLs.markers}/Marker`,

  search(contentId) {
    return axios({
      baseURL: this.markersURL,
      url: `/Search/${contentId}?type=Chapter&QuerySearchFilter.itemLimit=1000`,
      method: 'get',
    })
  },
}

export default Markers
