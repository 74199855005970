import { useCallback, MouseEvent, SyntheticEvent } from 'react'
import styled from '@emotion/styled'
import { Box, List, ListItemButton, Popover, Popper } from '@mui/material'
import { VolumeDown, VolumeMute, VolumeOff, VolumeUp } from '@mui/icons-material'
import { useSelector } from 'react-redux'

import PlayPauseButton from 'components/PlayPauseButton'
import ProgressBar from '../../DeskProgBar'
import VolumeComponent from '../../components/VolumeComponent'
import PlaybackSpeed from '../../components/PlaybackSpeed'
import FullscreenComponent from '../../components/FullscreenComponent'

import { secToHHMMSS } from 'utils/dateTimeConfig'

import type { RootState } from 'store/index'
import { IMediaTrack } from 'store/storeTypes'

import RewindIcon from 'assets/icons/rewing.svg'
import ForwardIcon from 'assets/icons/forward.svg'

type PlayerControlsProps = {
  currentlyAtRef: React.MutableRefObject<number>
  showCPanel: (
    isTemporary: boolean,
    keyEvent?: SyntheticEvent<HTMLDivElement, Event> | undefined,
  ) => void
  seekTo: (
    toWhere: number,
    isPlaying?: boolean | undefined,
    fromContentId?: string | undefined,
  ) => void
  progressAt: any
  setProgressAt: any
  pressPlayPause: (toPlay: boolean, vidInd?: number | undefined) => any
  muteUnmute: () => void
  handleVolumeSlide: (event: Event | null, newLevel: number | number[]) => void
  fullScreenVideoElem: any
  openSpPicker: (event: MouseEvent<HTMLButtonElement>) => void
  popoverId: string | undefined
  isSpeedPopUp: boolean
  anchorElem: any
  closeSpPicker: () => void
  handleSpeedPick: (event: SyntheticEvent<HTMLDivElement>) => void
  toggleFullScreen: () => void
  popoverVolumeId: string | undefined
  isVolumePopUp: boolean
  anchorElemVolume: HTMLElement | null
  openSpVolumePicker: (event: MouseEvent<HTMLButtonElement> | MouseEvent<HTMLDivElement>) => void
  closeSpVolumePicker: () => void
}

const PlayerControls = ({
  currentlyAtRef,
  showCPanel,
  seekTo,
  progressAt,
  setProgressAt,
  pressPlayPause,
  muteUnmute,
  handleVolumeSlide,
  fullScreenVideoElem,
  openSpPicker,
  popoverId,
  isSpeedPopUp,
  anchorElem,
  closeSpPicker,
  handleSpeedPick,
  toggleFullScreen,
  popoverVolumeId,
  isVolumePopUp,
  anchorElemVolume,
  openSpVolumePicker,
  closeSpVolumePicker,
}: PlayerControlsProps) => {
  const pbSpeeds = useSelector((state: RootState) => state.playback.pbSpeeds)
  const mediaTracks: IMediaTrack[] = useSelector(
    (state: RootState) => state.learningObject.mediaTracks,
  )
  const currentlyAt = useSelector((state: RootState) => state.playback.currentlyAt)
  const mediaDuration = useSelector((state: RootState) => state.playback.mediaDuration)
  const volumeLevel = useSelector((state: RootState) => state.playback.volumeLevel)

  const video =
    mediaTracks?.find((track) => track.contentTrack) || mediaTracks?.[mediaTracks?.length - 1]

  const displayTimers = useCallback(
    (isFirst: boolean) => {
      currentlyAtRef.current = currentlyAt

      let durationTime

      if (!currentlyAt) {
        durationTime = mediaDuration
      } else if (currentlyAt >= mediaDuration) {
        durationTime = 0
      } else {
        durationTime = mediaDuration - currentlyAt
      }

      const HHMMSScurrentlyAt = secToHHMMSS(currentlyAt)
      const HHMMSSMediaDuration = secToHHMMSS(durationTime)

      if (HHMMSSMediaDuration.startsWith('00')) {
        return isFirst ? HHMMSScurrentlyAt.slice(3) : `-${HHMMSSMediaDuration.slice(3)}`
      }

      return isFirst ? HHMMSScurrentlyAt : `-${HHMMSSMediaDuration}`
    },
    [currentlyAt, mediaDuration],
  )

  const displayVolume = () => {
    if (volumeLevel < 1) {
      return <VolumeOff fontSize="large" onPointerDown={muteUnmute} sx={{ cursor: 'pointer' }} />
    } else if (volumeLevel <= 34) {
      return (
        <VolumeMute
          onPointerDown={muteUnmute}
          fontSize="large"
          sx={{ position: 'relative', right: '4px', cursor: 'pointer' }}
        />
      )
    } else if (volumeLevel <= 67) {
      return (
        <VolumeDown
          onPointerDown={muteUnmute}
          fontSize="large"
          sx={{ position: 'relative', right: '2px', cursor: 'pointer' }}
        />
      )
    } else if (volumeLevel <= 100) {
      return <VolumeUp fontSize="large" onPointerDown={muteUnmute} sx={{ cursor: 'pointer' }} />
    }
  }

  return (
    <VideoControls>
      <BoxSeekButton>
        <PPButton
          key={video?.id}
          src={RewindIcon}
          alt=""
          onPointerDown={(event: SyntheticEvent) => {
            event.stopPropagation()
            if (currentlyAt - 10 > 0) seekTo(currentlyAt - 10)
            else seekTo(0)
          }}
        />
      </BoxSeekButton>

      <BoxSeekButton>
        <PPButton
          key={video?.id}
          src={ForwardIcon}
          alt=""
          onPointerDown={(event: SyntheticEvent) => {
            event.stopPropagation()
            seekTo(currentlyAt + 10)
          }}
        />
      </BoxSeekButton>

      <VolumeButtonBox
        onMouseOver={(event: any) => openSpVolumePicker(event)}
        onMouseLeave={closeSpVolumePicker}
      >
        <VolumeButtonBox onClick={muteUnmute}>{displayVolume()}</VolumeButtonBox>

        <Popper
          id={popoverVolumeId}
          placement="top"
          open={isVolumePopUp}
          anchorEl={anchorElemVolume}
          style={{ zIndex: 1000 }}
        >
          <VolumeComponent muteUnmute={muteUnmute} handleVolumeSlide={handleVolumeSlide} />
        </Popper>
      </VolumeButtonBox>

      <PlayPauseBox>
        <PlayPauseButton pressPlayPause={pressPlayPause} videoId={video?.id} />
      </PlayPauseBox>

      <Box>{displayTimers(true)}</Box>

      <ProgressBar
        showCPanel={showCPanel}
        seekTo={seekTo}
        progressAt={progressAt}
        setProgressAt={setProgressAt}
      />

      <Box>{displayTimers(false)}</Box>

      <FullscreenComponent toggleFullScreen={toggleFullScreen} />

      {fullScreenVideoElem === '' && (
        <>
          <PopOverMenu
            id={popoverId}
            open={isSpeedPopUp}
            anchorEl={anchorElem}
            onClose={closeSpPicker}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'center',
            }}
            transformOrigin={{
              vertical: 'bottom',
              horizontal: 'center',
            }}
          >
            <PlaybackSpeedList id="playback-speed-list">
              {pbSpeeds?.map((pbSpeed: number, i: number) => (
                <PlaybackSpeedListButton key={'pbSpeed_' + i} onPointerDown={handleSpeedPick}>
                  {`${pbSpeed}x`}
                </PlaybackSpeedListButton>
              ))}
            </PlaybackSpeedList>
          </PopOverMenu>
          <PlaybackSpeed openSpPicker={openSpPicker} popoverId={popoverId} />
        </>
      )}
    </VideoControls>
  )
}

export default PlayerControls

const PlayPauseBox = styled(Box)({
  width: '48px',
  height: '35px',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
  position: 'relative',
  right: '5px',
})
const VolumeButtonBox = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  cursor: 'pointer',
})
const PopOverMenu = styled(Popover)({
  backgroundColor: 'transparent',
  fontSize: '12px',
  '& .MuiPaper-root': {
    backgroundColor: 'transparent !important',
  },
})
const PlaybackSpeedList = styled(List)({
  '&.MuiList-root': {
    backgroundColor: '#333333',
  },
})
const PlaybackSpeedListButton = styled(ListItemButton)({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  alignItems: 'center',
  backgroundColor: '#333333',
  '&:hover': {
    backgroundColor: '#4d4d4d',
  },
})

const BoxSeekButton = styled(Box)({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
})

const PPButton = styled('img')({
  width: '32px',
  height: '36px',
  cursor: 'pointer',
})

const VideoControls = styled(Box)({
  width: '100%',
  height: '100%',
  zIndex: 500,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  flexDirection: 'row',
  margin: 0,
  padding: '11px 35px 11px 35px',
  backgroundColor: '#353535',
  borderRadius: '10px',
  gap: '25px',
})
