import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import { IssoQParamsState, IssoQParams, TssoQParam } from 'store/storeTypes'

const parseReturnURL = (returnurl: TssoQParam): TssoQParam => {
  let value = ''
  try {
    value = window.decodeURIComponent(returnurl.value)
  } catch (error) {
    value = 'http://www.nexius.hu'
  }
  return { ...returnurl, value }
}

const initialState: IssoQParamsState = {
  toSend: true,
  qParams: {
    contentid: { value: '', isNecessary: true, gotIt: false },
    provider: { value: '', isNecessary: true, gotIt: false },
    token: { value: '', isNecessary: true, gotIt: false },
    userid: { value: '', isNecessary: true, gotIt: false },
    serviceresultid: { value: '', isNecessary: true, gotIt: false },
    remainingtime: { value: '', isNecessary: false, gotIt: false },
    ispreview: { value: '', isNecessary: false, gotIt: false },
    returnurl: { value: '', isNecessary: false, gotIt: false },
  },
}

export const ssoQParamsSlice = createSlice({
  name: 'ssoqParams',
  initialState,
  reducers: {
    toMakeAetherUsageDataSend: (state: IssoQParamsState, action: PayloadAction<boolean>) => {
      state.toSend = action.payload
    },
    storeQParams: (state: IssoQParamsState, action: PayloadAction<IssoQParams>) => {
      const ssoQparams = action.payload
      ssoQparams.returnurl = parseReturnURL(ssoQparams.returnurl)
      state.qParams = ssoQparams
    },
  },
})

export const { storeQParams, toMakeAetherUsageDataSend } = ssoQParamsSlice.actions
export default ssoQParamsSlice.reducer
