import { useRef, useMemo, useCallback, TouchEvent } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Box, Slider } from '@mui/material'
import { styled } from '@mui/system'
import { userSeek } from 'store/reducers/playback'
import { secToHHMMSS } from 'utils/dateTimeConfig'
import type { RootState } from 'store/index'

const BottomButtonBox = styled(Box)({
  width: '100%',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
})
const ProgressTime = styled('span')({
  width: '60px',
  position: 'relative',
  top: '6px',
  display: 'inline-block',
  fontSize: '12px',
  color: '#000000',
  padding: '5px',
  backgroundColor: 'rgba(255, 255, 255, 0.7)',
  transition: 'opacity 0.3s ease-out',
  borderRadius: '10px',
  textAlign: 'center',
})
const ProgressRange = styled(Slider)({
  color: '#ffffff',
  height: '6px',
  '& .MuiSlider-thumb.Mui-focusVisible, .MuiSlider-thumb:hover': {
    boxShadow: 'none!important',
  },
  '&.MuiSlider-root': {
    padding: 0,
  },
  '& .MuiSlider-rail': {
    backgroundColor: '#595959',
    opacity: 1,
  },
  '& .MuiSlider-thumb': {
    width: '14px',
    height: '14px',
  },
})

function ProgressBar(props: any) {
  const { videosContainerRef, showCPanel, seekTo, progressAt, setProgressAt } = props

  const currentlyAt = useSelector((state: RootState) => state.playback.currentlyAt)
  const mediaDuration = useSelector((state: RootState) => state.playback.mediaDuration)
  const isPlaying = useSelector((state: RootState) => state.playback.isPlaying)
  const isUserSeeking = useSelector((state: RootState) => state.playback.isUserSeeking)
  const dispatch = useDispatch()

  const progressTimeRef = useRef<HTMLSpanElement>(null)

  const startMoveProgress = (event: TouchEvent<HTMLSpanElement>) => {
    event.stopPropagation()
    showCPanel(false)
    if (videosContainerRef?.current) {
      videosContainerRef.current.style.overflowX = 'hidden'
    }
    if (progressTimeRef?.current?.style) {
      progressTimeRef.current.style.opacity = '1'
    }
    dispatch(userSeek(true))
  }

  const moveProgress = (event: Event, value: number | number[]) => {
    event.stopPropagation()
    if (typeof value === 'number') {
      if (progressTimeRef?.current?.style) {
        progressTimeRef.current.style.left = `clamp(
          -5px,
          calc(${(value / mediaDuration) * 100}% - 30px),
          ${progressTimeRef.current.parentElement!.getBoundingClientRect().width - 55}px
        )`
      }
      setProgressAt(value)
    }
  }

  const stopMoveProgress = () => {
    dispatch(userSeek(false))
    seekTo(progressAt, isPlaying)
    if (progressTimeRef?.current?.style) {
      progressTimeRef.current.style.opacity = '0'
    }
    if (videosContainerRef?.current) {
      videosContainerRef.current.style.overflowX = 'auto'
    }
    if (isPlaying) showCPanel(true)
  }

  const displayUserSeeksToTime = useCallback(() => {
    const HHMMSSprogressAt = secToHHMMSS(progressAt)
    if (HHMMSSprogressAt.startsWith('00')) {
      return HHMMSSprogressAt.slice(3)
    }
    return HHMMSSprogressAt
  }, [progressAt])

  const actualProgress = useMemo(() => {
    return isUserSeeking ? progressAt : currentlyAt
  }, [isUserSeeking, progressAt, currentlyAt])

  return (
    <>
      <BottomButtonBox>
        <ProgressTime sx={{ opacity: 0 }} ref={progressTimeRef}>
          {displayUserSeeksToTime()}
        </ProgressTime>
      </BottomButtonBox>

      <ProgressRange
        size="small"
        defaultValue={70}
        aria-label="Small"
        min={0}
        max={mediaDuration || 0}
        step={1}
        value={actualProgress}
        valueLabelDisplay="off"
        onTouchStart={startMoveProgress}
        onChange={moveProgress}
        onChangeCommitted={stopMoveProgress}
      />
    </>
  )
}

export default ProgressBar
