import dayjs from 'dayjs'

export const getDuration = (duration: string): string =>
  dayjs(parseInt(duration, 10)).toISOString().slice(11, 19)

export const monhtAndDay = (date: string): string => dayjs(date).format('MMM D')

export const formatDate = (date: string): string => dayjs(date).format('MMM D, YYYY')

export const msToHHMMSS = (ms: number = 0) => dayjs(ms).toISOString().slice(11, -5)

export const msToHours = (ms: number) => ms / 1000 / 60 / 60

export const secToHHMMSS = (sec: number = 0) => msToHHMMSS(sec * 1000)

export const HHMMSSToSec = (HHMMSS: string) =>
  parseInt(HHMMSS.slice(0, 2), 10) * 3600 +
  parseInt(HHMMSS.slice(3, 5), 10) * 60 +
  parseInt(HHMMSS.slice(6, 8), 10)

export const formatStringToReadableTime = (timeString: string) => {
  const splittedString = timeString.split(':')
  const hours = Number(splittedString[0])
  const minutes = Number(splittedString[1])
  const seconds = Math.floor(Number(splittedString[2]))

  if (hours === 0) {
    return `${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`
  } else {
    return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`
  }
}
