import { useSelector } from 'react-redux'
import { styled } from '@mui/system'
import { Typography, Grid } from '@mui/material'
import parse from 'html-react-parser'
import sanitizeHtml from 'sanitize-html'
import type { RootState } from 'store/index'

const AboutGrid = styled(Grid)({
  width: '100%',
  margin: 0,
  padding: '12px',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'flex-start',
})
const ContentTitleGrid = styled(Grid)({
  width: '100%',
  marginBottom: '6px',
})

function About() {
  const appLanguage = useSelector((state: RootState) => state.appLanguage.selected)
  const localizationDict = useSelector((state: RootState) => state.appLanguage.localizationDict)
  const locs = localizationDict[appLanguage]

  return (
    <AboutGrid container>
      <ContentTitleGrid item>
        <Typography variant="subtitle1">{locs?.title}</Typography>
      </ContentTitleGrid>

      <Grid item>
        <Typography variant="body2" color="text.secondary">
          {parse(sanitizeHtml(locs?.description || ''))}
        </Typography>
      </Grid>
    </AboutGrid>
  )
}

export default About
