import { useCallback } from 'react'
import { useSelector } from 'react-redux'
import { styled } from '@mui/system'
import { secToHHMMSS } from 'utils/dateTimeConfig'
import type { RootState } from 'store/index'

const UserTimeTracker = styled('span')({
  marginRight: '5px',
})

function SpentTimeDisplay() {
  const currentlyAt = useSelector((state: RootState) => state.playback.currentlyAt)
  const isPortableDevice = useSelector(
    (state: RootState) => state.componentDisplay.isPortableDevice,
  )

  const displaySpentUserTime = useCallback(() => {
    const HHMMSUserTime = secToHHMMSS(currentlyAt)
    if (HHMMSUserTime.startsWith('00')) {
      return HHMMSUserTime.slice(3)
    }
    return HHMMSUserTime
  }, [currentlyAt])

  return (
    <UserTimeTracker sx={{ fontSize: isPortableDevice ? '11px' : '13px' }}>
      {displaySpentUserTime()}
    </UserTimeTracker>
  )
}

export default SpentTimeDisplay
