import { useState, useEffect, useRef } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useSearchParams, useNavigate } from 'react-router-dom'
import { useMediaQuery } from 'react-responsive'
import axios, { AxiosError, AxiosResponse, AxiosRequestConfig } from 'axios'
import { UAParser } from 'ua-parser-js'
import Cookies from 'js-cookie'
import { v4 as uuidv4 } from 'uuid'
import { deviceIsPortable, showAbortModal } from 'store/reducers/componentDisplay'
import { storeQParams, toMakeAetherUsageDataSend } from 'store/reducers/ssoQParams'
import { storeDeviceInfo } from 'store/reducers/deviceInfo'
import AbortModal from 'components/AbortScreen'
import PortableSkeleton from 'pages/Portable/SkeletonLoader'
import DesktopSkeleton from 'pages/Desktop/SkeletonLoader'
import type { RootState } from 'store/index'
import { TssoQParam, IssoQParams } from 'store/storeTypes'

function SSO() {
  const [isLoading, setIsLoading] = useState<boolean>(true)

  const [queryParams] = useSearchParams()

  const isAbortModal = useSelector((state: RootState) => state.componentDisplay.isAbortModal)
  const authApi = useSelector((state: RootState) => state.apiURLs.auth)
  const isPortableDevice = useSelector(
    (state: RootState) => state.componentDisplay.isPortableDevice,
  )

  const dispatch = useDispatch()

  const navigate = useNavigate()

  const isPortraitView = useMediaQuery({ query: '(orientation: portrait)' })

  const oneAuthFetch = useRef<boolean>(false)

  const makeReqConf = (qParams: IssoQParams): AxiosRequestConfig => {
    let deviceId = Cookies.get('deviceId')
    if (!deviceId) {
      deviceId = uuidv4()
      Cookies.set('deviceId', deviceId, { expires: 365 })
    }
    const deviceInfo = new UAParser().getResult()
    dispatch(storeDeviceInfo(deviceInfo))

    return {
      baseURL: authApi,
      url: '/authenticate',
      method: 'post',
      data: {
        network: {
          type: decodeURIComponent(qParams.provider.value),
          userId: qParams.userid.value,
          accessToken: qParams.token.value,
        },
        device: {
          deviceId,
          deviceName: deviceInfo.device?.model ?? deviceInfo.os?.name,
          deviceType: deviceInfo.device?.type,
          deviceBrand: deviceInfo.device?.vendor,
          deviceLocale: navigator.language,
        },
      },
    }
  }

  useEffect(() => {
    const area = window.screen.width * window.screen.height
    const isPortable = window.screen[isPortraitView ? 'width' : 'height'] < 700 && area < 490000
    dispatch(deviceIsPortable(isPortable))

    const qParams: IssoQParams = Array.from(queryParams.keys()).reduce(
      (qP: IssoQParams, key: string) => {
        if (Object.keys(qP).includes(key.toLowerCase())) {
          qP[key.toLowerCase()].value = queryParams.get(key) as string
          qP[key.toLowerCase()].gotIt = true
        }
        return qP
      },
      {
        contentid: { value: '', isNecessary: true, gotIt: false },
        provider: { value: '', isNecessary: true, gotIt: false },
        token: { value: '', isNecessary: true, gotIt: false },
        userid: { value: '', isNecessary: true, gotIt: false },
        serviceresultid: { value: '', isNecessary: true, gotIt: false },
        remainingtime: { value: '', isNecessary: false, gotIt: false },
        ispreview: { value: '', isNecessary: false, gotIt: false },
        returnurl: { value: '', isNecessary: false, gotIt: false },
      },
    )

    if (!oneAuthFetch.current) {
      oneAuthFetch.current = true
      if (
        Object.values(qParams).every((qPVal: TssoQParam) =>
          qPVal.isNecessary ? qPVal.gotIt : true,
        )
      ) {
        dispatch(storeQParams(qParams))
        dispatch(toMakeAetherUsageDataSend(true))
        axios(makeReqConf(qParams))
          .then((response: AxiosResponse) => {
            const contentId = qParams.contentid.value
            const userToken = `token=${response.data?.accessToken}`
            const isPrev =
              qParams.ispreview.gotIt && qParams.ispreview.value?.toLowerCase() === 'true'
                ? '&isPreview=true'
                : ''
            const returnUrl = qParams.returnurl.gotIt ? `&returnUrl=${qParams.returnurl.value}` : ''
            navigate(`/${contentId}?${userToken}${isPrev}${returnUrl}`)

            // It is recommended to use `redirect()` instead of `navigate()` but it doesn't work
            // https://reactrouter.com/en/main/fetch/redirect
            // return redirect(`/${contentId}?${userToken}${isPrev}${returnUrl}`);
          })
          .catch((error: AxiosError) => {
            console.error(error)
            if (!isAbortModal.on) {
              setTimeout(() => dispatch(showAbortModal('wrongparams')), 200)
            }
          })
          .finally(() => setIsLoading(false))
      } else {
        setIsLoading(false)
        if (!isAbortModal.on) dispatch(showAbortModal('wrongparams'))
      }
    }
  }, [])

  const handleSkeleton = () => {
    return isPortableDevice ? <PortableSkeleton /> : <DesktopSkeleton />
  }

  return (
    <>
      {isLoading
        ? handleSkeleton()
        : isAbortModal.on && <AbortModal VideoRefs={[]} shakaInstances={[]} socket={null} />}
    </>
  )
}

export default SSO
