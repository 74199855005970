import { useSelector } from 'react-redux'
import { AxiosError } from 'axios'
import { Box, Table, TableBody, TableCell, TableRow } from '@mui/material'
import { styled } from '@mui/system'
import AlertMsg from 'services/alertMsg'
import downloadFile from 'services/file'
import { WebAssetOutlined } from '@mui/icons-material'
import Excel from 'assets/icons/iconExcel.svg'
import Pdf from 'assets/icons/iconPdf.svg'
import Powerpoint from 'assets/icons/iconPowerpoint.svg'
import Video from 'assets/icons/iconVideo.svg'
import Word from 'assets/icons/iconWord.svg'
import Image from 'assets/icons/iconImg.svg'
import Text from 'assets/icons/iconText.svg'
import Audio from 'assets/icons/iconAudio.svg'
import DownloadSrc from 'assets/icons/download.svg'
import { formatFileSize } from 'utils/formatFileSize'
import type { RootState } from 'store/index'

const DesktopTable = styled(Table)({
  '&.MuiTable-root': {
    padding: 0,
  },
})
const DescBox = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  overflowWrap: 'break-word',
  wordBreak: 'break-word',
  whiteSpace: 'pre-line',
})
const AssetInfo = styled('span')({
  textIndent: '2px',
  fontSize: '16px',
})
const DownloadBox = styled(Box)({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'flex-end',
  alignItems: 'center',
  fontSize: '14px',
})
const TCell = styled(TableCell)({
  border: 'none',
  padding: '0 2px 15px 2px',
})
const DownloadIcon = styled('img')({
  width: '25px',
  height: '25px',
  cursor: 'pointer',
  zIndex: 100,
})
const WebAssetIcon = styled(WebAssetOutlined)({
  width: '30px',
  height: '30px',
})

function Assets() {
  const learnObjItem = useSelector((state: RootState) => state.learningObject.item)
  const screenWidth = useSelector((state: RootState) => state.componentDisplay.screenWidth)

  const getFile = (downloadUrl: string) => {
    downloadFile(downloadUrl).catch((error: AxiosError) => {
      AlertMsg.show({
        type: 'error',
        message: error,
        context: 'Could not download the file → ',
      })
    })
  }

  const renderIcon = (name: string) => {
    const extensionDotIndex = name.lastIndexOf('.')
    const fileExtension = name.slice(extensionDotIndex + 1)
    switch (fileExtension) {
      case 'bmp':
      case 'jpg':
      case 'jpeg':
      case 'png':
      case 'svg':
        return <img src={Image} alt="depiction extension icon" />
      case 'csv':
      case 'txt':
        return <img src={Text} alt="text extension icon" />
      case 'doc':
      case 'docx':
        return <img src={Word} alt="word extension icon" />
      case 'mp4':
      case 'mpd':
      case 'm3u8':
      case 'ism':
        return <img src={Video} alt="video extension icon" />
      case 'wav':
      case 'mp3':
      case 'ogg':
        return <img src={Audio} alt="audio extension icon" />
      case 'xls':
      case 'xlsx':
        return <img src={Excel} alt="excel extension icon" />
      case 'ppt':
      case 'pptx':
        return <img src={Powerpoint} alt="powerpoint extension icon" />
      case 'pdf':
        return <img src={Pdf} alt="pdf extension icon" />
      default:
        return <WebAssetIcon />
    }
  }

  return (
    <DesktopTable sx={{ width: screenWidth > 1000 ? '64.5%' : '100%' }}>
      <TableBody>
        {learnObjItem?.assets.map((asset: any) => {
          const { name, locale, downloadUrl, fileSize } = asset
          return (
            <TableRow key={`${name}_index`}>
              <TCell align="left">{renderIcon(name)}</TCell>

              <TCell align="left">
                <DescBox>
                  <AssetInfo>
                    {name}
                    {locale && locale !== '--' ? ` — ${locale}` : ''}
                  </AssetInfo>
                </DescBox>
              </TCell>

              <TCell align="right">
                <AssetInfo>{formatFileSize(fileSize)}</AssetInfo>
              </TCell>

              <TCell align="right">
                <DownloadBox>
                  <DownloadIcon src={DownloadSrc} onPointerDown={() => getFile(downloadUrl)} />
                </DownloadBox>
              </TCell>
            </TableRow>
          )
        })}
      </TableBody>
    </DesktopTable>
  )
}

export default Assets
