import axios, { AxiosResponse } from 'axios'

function pump(
  reader: ReadableStreamDefaultReader<Uint8Array>,
  controller: ReadableStreamDefaultController<any>,
): any {
  return reader.read().then(({ done, value }) => {
    if (done) {
      controller.close()
      return
    }
    controller.enqueue(value)
    return pump(reader, controller)
  })
}

export default function downloadFile(url: string) {
  let filename: string

  return axios({ url, method: 'get' })
    .then((response: AxiosResponse) => window.fetch(response.data))
    .then((response) => {
      const { url } = response
      if (response?.body && url) {
        filename = url.split('%3B%20filename%3D')[1]?.split('&')[0]
        const reader = response.body.getReader()
        return new ReadableStream({
          start(controller) {
            return pump(reader, controller)
          },
        })
      } else {
        return Promise.reject(new Error('wrong URL!'))
      }
    })
    .then((stream) => new Response(stream))
    .then((response) => response.blob())
    .then((blob) => {
      const file = window.URL.createObjectURL(blob)
      const link = document.createElement('a')
      link.href = file
      link.setAttribute('download', filename)
      document.body.appendChild(link)
      link.click()
      window.URL.revokeObjectURL(url)
      document.body.removeChild(link)
    })
}
