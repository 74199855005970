import { useState, useEffect, useRef } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import lifecycle from 'page-lifecycle'
import { signalContentEnded, setCurrentlyAt } from 'store/reducers/playback'
import { IMediaTrack } from 'store/storeTypes'
import type { RootState } from 'store/index'
import Cookies from 'js-cookie'

interface IPostData {
  [key: string]: string | number
  type: string
  contentId: string
  userId: string
  sessionId: string
  contentToken: string
  userToken: string
  organizationId: string
  genre: string
  category: string
  mediaPosition: number
  date: string
}

function SpentTimeTracker(props: any) {
  const { isPreview, currentlyAtRef, pressPlayPause, seekTo, isPressExitButton } = props

  const learnObjItem = useSelector((state: RootState) => state.learningObject.item)
  const mediaTracks: IMediaTrack[] = useSelector(
    (state: RootState) => state.learningObject.mediaTracks,
  )
  const isFirstPlayPressDone = useSelector(
    (state: RootState) => state.playback.isFirstPlayPressDone,
  )
  const currentlyAt = useSelector((state: RootState) => state.playback.currentlyAt)
  const spentUserTime = useSelector((state: RootState) => state.playback.spentUserTime)
  const hasContentEnded = useSelector((state: RootState) => state.playback.hasContentEnded)
  const userData = useSelector((state: RootState) => state.userData)
  const reportingUrl = useSelector((state: RootState) => state.apiURLs.reporting)
  const ssoQParams = useSelector((state: RootState) => state.ssoQParams.qParams)
  const mediaDuration = useSelector((state: RootState) => state.playback.mediaDuration)
  const playbackSpeedState = useSelector((state: RootState) => state.playback.playbackSpeed)
  const isPlaying = useSelector((state: RootState) => state.playback.isPlaying)
  const [contentId, setContentId] = useState<undefined | string>(undefined)
  const [userId, setUserId] = useState<undefined | string>(undefined)
  const [externalId, setExternalId] = useState<undefined | string>(undefined)
  const [serviceResultId, setServiceResultId] = useState<undefined | string>(undefined)
  const [isExistAllIds, setIsExistAllIds] = useState<boolean>(false)

  const dispatch = useDispatch()

  const playbackSpeedRef = useRef(playbackSpeedState)
  const heartbeatInterval = useRef<ReturnType<typeof setInterval>>()
  const bookedSpentTime = useRef<number>(0)
  const resetCounterAfterSendReport = useRef<number>(0)
  const currentUserSpentTime = useRef<number>(0)
  const storeCurrentTimeSpent = useRef<number>(0)
  const storeCurrentProgress = useRef<number>(0)
  const storeCurrentTotalTime = useRef<number>(0)
  const contentDuration = useRef<number>(1)
  const postData = useRef<IPostData>({
    type: 'ContentConsumptionContract',
    contentId: '',
    userId: '',
    sessionId: '',
    contentToken: '',
    userToken: '',
    organizationId: '',
    genre: '',
    category: '',
    mediaPosition: 0,
    date: '1970-01-01T00:00:00.000Z',
  })
  const sendingDataIn = useRef<boolean>(false)
  const nexiusProgress = useRef<number>(0)

  const prepareConsReqBody = (timeSpentFromLastDataPost: number) => {
    return {
      Properties: [
        {
          Name: 'Type',
          Value: postData.current.type,
        },
        {
          Name: 'ContentRef',
          Value: postData.current.contentId,
        },
        {
          Name: 'UserRef',
          Value: postData.current.userId,
        },
        {
          Name: 'SessionId',
          Value: postData.current.sessionId,
        },
        {
          Name: 'ContentToken',
          Value: postData.current.contentToken,
        },
        {
          Name: 'UserToken',
          Value: postData.current.userToken,
        },
        {
          Name: 'OrganizationRef',
          Value: postData.current.organizationId,
        },
        {
          Name: 'Genre',
          Value: postData.current.genre,
        },
        {
          Name: 'Category',
          Value: postData.current.category,
        },
        {
          Name: 'Position',
          Value: hasContentEnded ? 0 : Math.round(postData.current.mediaPosition),
        },
        {
          Name: 'TimeSpent',
          Value: timeSpentFromLastDataPost,
        },
        {
          Name: 'Duration',
          Value: contentDuration.current,
        },
        {
          Name: 'Speed',
          Value: playbackSpeedRef.current,
        },
        {
          Name: 'TimeSpentPercentage',
          Value: nexiusProgress.current * 100,
        },
        {
          Name: 'Date',
          Value: new Date().toISOString(),
        },
      ],
    }
  }

  useEffect(() => {
    contentDuration.current = mediaDuration
  }, [mediaDuration])

  useEffect(() => {
    if (spentUserTime === 0) {
      handleGetStoredProgress()
      handleGetStoredTotalTime()
    } else {
      currentUserSpentTime.current = spentUserTime
      postData.current.mediaPosition = currentlyAt
    }
  }, [spentUserTime, ssoQParams, userData?.id])

  useEffect(() => {
    if (ssoQParams) {
      const paramUserId = ssoQParams?.userid?.value
      const paramExternalId = ssoQParams?.contentid?.value
      const paramServiceResultId = ssoQParams?.serviceresultid?.value

      setUserId(paramUserId)
      setExternalId(paramExternalId)
      setServiceResultId(paramServiceResultId)

      setIsExistAllIds(!!paramUserId && !!paramExternalId && !!paramServiceResultId)

      setContentId(paramExternalId || postData?.current?.contentId || learnObjItem?.id)
    }
  }, [ssoQParams, learnObjItem])

  useEffect(() => {
    postData.current.type = 'ContentConsumptionContract'
    postData.current.contentId = learnObjItem?.id
    postData.current.genre = learnObjItem?.genre
    postData.current.category = learnObjItem?.category
    postData.current.organizationId = learnObjItem?.organizationRef
    postData.current.contentToken = learnObjItem?.contentToken
    postData.current.userId = userData?.id
    postData.current.userToken = userData?.token
    postData.current.sessionId = mediaTracks[0]?.sessionId
  }, [learnObjItem, userData, mediaTracks])

  const handleResetPlayback = () => {
    seekTo(0)
    currentlyAtRef.current = 0
    dispatch(setCurrentlyAt(0))
    pressPlayPause(false)
    handlePostLatestPosition(0)
  }

  const sendNexiusReport = () => {
    // Nexius user time tracking
    sendingDataIn.current = true

    const urlToken = Cookies.get('token') ?? ''
    const allReqDataExist = !!userData?.id && !!contentId && !!urlToken

    if (isExistAllIds && allReqDataExist) {
      handlePostLatestProgress()
      handlePostLatestTotalTime()
      handlePostLatestPosition(postData.current.mediaPosition)
      handleNexiusReport()
      //sendingDataIn.current = false
    }

    handleReportProgress()
    sendingDataIn.current = false
  }

  // play event report send
  useEffect(() => {
    if (isFirstPlayPressDone && isPlaying) {
      setupInterval()
    } else {
      clearInterval(heartbeatInterval.current)
      resetCounterAfterSendReport.current = 0
    }

    if (!isFirstPlayPressDone) {
      document.addEventListener('visibilitychange', function logData() {
        if (document.visibilityState === 'hidden') {
          pressPlayPause(false)
          //handleResetPlayback()
        }
      })
    }
  }, [isPlaying, isExistAllIds])

  // exit page report send
  useEffect(() => {
    if (isPressExitButton) {
      sendNexiusReport()
      if (!isFirstPlayPressDone) handleResetPlayback()
    }
  }, [isPressExitButton, isExistAllIds, isFirstPlayPressDone])

  const setupInterval = () => {
    if (!isPreview && mediaTracks.length && isFirstPlayPressDone) {
      const interval = 10000
      heartbeatInterval.current = setInterval(() => {
        resetCounterAfterSendReport.current = interval / 1000
        const spentTime = currentUserSpentTime.current - bookedSpentTime.current
        const timeSpentFromLastDataPost = spentTime > 0 ? spentTime : 0

        if (timeSpentFromLastDataPost > 0) {
          sendNexiusReport()
        }
      }, interval)
    }

    document.addEventListener('visibilitychange', function logData() {
      if (document.visibilityState === 'hidden') {
        sendNexiusReport()
      }
    })
  }

  useEffect(() => {
    if (hasContentEnded && !isPreview) {
      dispatch(signalContentEnded(false))
      const spentTimeResult = currentUserSpentTime.current - bookedSpentTime.current
      const timeSpentFromLastDataPost =
        spentTimeResult === 0 ? currentUserSpentTime.current : spentTimeResult

      if (timeSpentFromLastDataPost > 0) {
        postData.current.mediaPosition = 0

        sendNexiusReport()
      }
    }
  }, [hasContentEnded])

  useEffect(() => {
    playbackSpeedRef.current = playbackSpeedState

    if (!isPreview) {
      if (bookedSpentTime.current < currentUserSpentTime.current) {
        sendingDataIn.current = true
        sendNexiusReport()
        sendingDataIn.current = false
        //const timeSpentFromLastDataPost = currentUserSpentTime.current - bookedSpentTime.current

        //// Guava user time tracking
        //axios(makeConsumptionUpdateRequestParams(timeSpentFromLastDataPost))
        //  .then(() => {
        //    bookedSpentTime.current += timeSpentFromLastDataPost
        //  })
        //  .catch((error: AxiosError) => {
        //    console.error("Couldn't post spent time data → ", error)
        //  })
        //  .finally(() => {
        //    sendingDataIn.current = false
        //  })
      }
    }
  }, [playbackSpeedState])

  const handleGetStoredTotalTime = async () => {
    try {
      const allReqDataExist = !!userData?.id && !!contentId
      if (allReqDataExist) {
        const tokenFromUrl = Cookies.get('token') ?? ''

        const url = window.__RUNTIME_CONFIG__.PROGRESS_QUERY.replace('{customerId}', userData?.id)
          .replace('{contentId}', contentId)
          .replace('progress', 'totaltime')

        const headers = {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${tokenFromUrl}`,
          // Connection: 'keep-alive',
          //'Keep-Alive': 'timeout=5, max=1000',
        }

        const requestOptions = {
          method: 'GET',
          headers: new Headers(headers),
          // keepalive: true,
        }

        const info = await fetch(url, requestOptions)
        const data = await info.json()

        if (!data.position) return console.log('No totaltime position found')

        storeCurrentTimeSpent.current = typeof data.position === 'number' ? data.position : 0
        storeCurrentTotalTime.current = storeCurrentTimeSpent.current
      }
    } catch (error) {
      console.log('error:', error)
    }
  }

  const handleGetStoredProgress = async () => {
    try {
      const allReqDataExist = !!userData?.id && !!contentId
      if (allReqDataExist) {
        const tokenFromUrl = Cookies.get('token') ?? ''

        const url = window.__RUNTIME_CONFIG__.PROGRESS_QUERY.replace(
          '{customerId}',
          userData?.id,
        ).replace('{contentId}', contentId)

        const headers = {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${tokenFromUrl}`,
          //Connection: 'keep-alive',
          //'Keep-Alive': 'timeout=5, max=1000',
        }

        const requestOptions = {
          method: 'GET',
          headers: new Headers(headers),
          //keepalive: true,
        }

        const info = await fetch(url, requestOptions)
        const data = await info.json()

        if (!data.position) return console.log('No progress position found')

        storeCurrentProgress.current = typeof data.position === 'number' ? data.position : 0
      }
    } catch (error) {
      console.log('error:', error)
    }
  }
  const handlePostLatestPosition = async (position: number) => {
    try {
      const urlToken = Cookies.get('token') ?? ''

      const beaconBody = {
        relation: 'Position',
        sourceId: userData?.id,
        targetId: contentId,
        type: 'Customer',
        token: urlToken,
        position: Math.round(position),
      }

      const blob = new Blob([JSON.stringify(beaconBody)], {
        type: 'application/json; charset=UTF-8',
      })
      navigator.sendBeacon(`${window.__RUNTIME_CONFIG__.PROGRESS_REPORT}`, blob)
    } catch (error) {
      console.log('error:', error)
    }
  }

  const handlePostLatestProgress = async () => {
    try {
      const timeSpentFromLastDataPost = currentUserSpentTime.current - bookedSpentTime.current
      storeCurrentProgress.current += timeSpentFromLastDataPost
      const urlToken = Cookies.get('token') ?? ''

      if (timeSpentFromLastDataPost === 0) return

      const beaconBody = {
        position: hasContentEnded ? 1 : Math.round(timeSpentFromLastDataPost),
        relation: 'Progress',
        sourceId: userData?.id,
        targetId: contentId,
        type: 'Customer',
        token: urlToken,
      }
      const blob = new Blob([JSON.stringify(beaconBody)], {
        type: 'application/json; charset=UTF-8',
      })
      navigator.sendBeacon(`${window.__RUNTIME_CONFIG__.PROGRESS_REPORT}`, blob)
    } catch (error) {
      console.log('error:', error)
    }
  }

  const handlePostLatestTotalTime = async () => {
    try {
      const urlToken = Cookies.get('token') ?? ''

      const timeSpentFromLastDataPost = currentUserSpentTime.current - bookedSpentTime.current

      if (timeSpentFromLastDataPost == 0) {
        return
      }
      const totalTime = timeSpentFromLastDataPost * (1 / playbackSpeedRef.current)
      storeCurrentTotalTime.current += totalTime
      const beaconBody = {
        position: Math.round(totalTime),
        relation: 'TotalTime',
        sourceId: userData?.id,
        targetId: contentId,
        type: 'Customer',
        token: urlToken,
      }
      const blob = new Blob([JSON.stringify(beaconBody)], {
        type: 'application/json; charset=UTF-8',
      })
      navigator.sendBeacon(`${window.__RUNTIME_CONFIG__.PROGRESS_REPORT}`, blob)
    } catch (error) {
      console.log('error:', error)
    }
  }
  const handleNexiusReport = async () => {
    const timeSpentFromLastDataPost = currentUserSpentTime.current - bookedSpentTime.current
    // Nexius user time tracking
    //const totalTime = storeCurrentTimeSpent.current + currentUserSpentTime.current
    const totalTime = storeCurrentTotalTime.current
    const whereAt = parseFloat((storeCurrentProgress.current / contentDuration.current).toFixed(2))
    nexiusProgress.current = whereAt >= 0.99 ? 1 : whereAt

    console.log('Nexius Report:')
    console.log('Date: ' + new Date().toISOString())

    if (timeSpentFromLastDataPost == 0) {
      console.log('handleNexiusReport: no progress')
      return
    }
    const beaconBody = {
      UserId: userId,
      ExternalId: externalId,
      ServiceResultId: serviceResultId,
      Progress: nexiusProgress.current,
      TotalTime: Math.floor(totalTime / 60).toFixed(0),
      FinalResult: false, // Nexius disable
      Token: Cookies.get('token'),
      Timestamp: new Date().toISOString(),
    }

    console.log('TotalTime = ', totalTime)
    console.log('Progress = ', nexiusProgress.current)
    console.log('storeCurrentProgress.current = ', storeCurrentProgress.current)
    console.log('timeSpentFromLastDataPost = ', timeSpentFromLastDataPost)

    const blob = new Blob([JSON.stringify(beaconBody)], {
      type: 'application/json; charset=UTF-8',
    })
    navigator.sendBeacon(`${window.__RUNTIME_CONFIG__.NEXIUS_REPORT}/nexius-report`, blob)
  }

  const handleReportProgress = async () => {
    const timeSpentFromLastDataPost = currentUserSpentTime.current - bookedSpentTime.current

    if (timeSpentFromLastDataPost == 0) {
      return
    }

    const beaconBody = JSON.stringify(prepareConsReqBody(timeSpentFromLastDataPost))

    const blob = new Blob([beaconBody], {
      type: 'application/json; charset=UTF-8',
    })
    navigator.sendBeacon(`${reportingUrl}/clientreport`, blob)

    bookedSpentTime.current += timeSpentFromLastDataPost
  }
  useEffect(() => {
    if (isFirstPlayPressDone && currentlyAt > 0 && !isPreview) {
      const sendDataOnHide = () => {
        const timeSpentFromLastDataPost = currentUserSpentTime.current - bookedSpentTime.current

        if (
          lifecycle.state === 'hidden' &&
          !sendingDataIn.current &&
          timeSpentFromLastDataPost > 0
        ) {
          sendNexiusReport()
        }
      }
      lifecycle.addEventListener('statechange', sendDataOnHide)
      return () => lifecycle.removeEventListener('statechange', sendDataOnHide)
    }
  }, [isFirstPlayPressDone, currentlyAt, reportingUrl, isExistAllIds])

  return null
}

export default SpentTimeTracker
