import { configureStore } from '@reduxjs/toolkit'
import alertMsg from './reducers/alertMsg'
import apiURLs from './reducers/apiURLs'
import appLanguage from './reducers/appLanguage'
import deviceInfo from './reducers/deviceInfo'
import componentDisplay from './reducers/componentDisplay'
import learningObject from './reducers/learningObject'
import forum from './reducers/forum'
import notes from './reducers/notes'
import search from './reducers/search'
import playback from './reducers/playback'
import ssoQParams from './reducers/ssoQParams'
import userData from './reducers/userData'
import { LOapi } from 'services/api'

export const store = configureStore({
  reducer: {
    alertMsg,
    apiURLs,
    appLanguage,
    componentDisplay,
    deviceInfo,
    learningObject,
    forum,
    notes,
    search,
    playback,
    ssoQParams,
    userData,
    [LOapi.reducerPath]: LOapi.reducer,
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(LOapi.middleware),
})

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch
