import React from 'react'
import ReactDOM from 'react-dom/client'
import { BrowserRouter, Routes, Route } from 'react-router-dom'
import { store } from './store'
import { Provider as StoreProvider } from 'react-redux'
import AppFrame from './pages/AppFrame'
import Sso from './pages/SingleSignOn'
import AlertMsgFrame from 'components/AlertMsgFrame'
import reportWebVitals from './utils/reportWebVitals'
import { ThemeProvider } from '@mui/material/styles'
import appTheme from './appTheme'
import CssBaseline from '@mui/material/CssBaseline'

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)

root.render(
  <React.StrictMode>
    <StoreProvider store={store}>
      <BrowserRouter>
        <ThemeProvider theme={appTheme}>
          <CssBaseline />
          <Routes>
            <Route path=":contentId" element={<AppFrame />} />
            <Route path="*" element={<Sso />} />
          </Routes>
        </ThemeProvider>
        <AlertMsgFrame />
      </BrowserRouter>
    </StoreProvider>
  </React.StrictMode>,
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.info))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
