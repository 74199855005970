import { useEffect, useRef } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Button, Dialog, DialogTitle, Typography } from '@mui/material'
import { styled } from '@mui/system'
import { showLabel } from 'services/language'
import { toggleAudioSubtSidebar } from 'store/reducers/componentDisplay'
import type { RootState } from 'store/index'

// https://developer.mozilla.org/en-US/docs/Web/API/WebSocket/readyState
enum WSreadyState {
  CONNECTING,
  OPEN,
  CLOSING,
  CLOSED,
}

const AbortModal = styled(Dialog)({
  '& .MuiDialog-paper': {
    backdropFilter: 'blur(15px)',
    backgroundColor: 'rgba(0, 0, 0, 0.1)',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
})
const AbortModalTitle = styled(DialogTitle)({
  margin: 0,
  padding: '10px 15px',
  textAlign: 'center',
})
const AbortModalBody = styled('div')({
  width: '80%',
  margin: 0,
  padding: '10px 15px',
  textAlign: 'center',
})
const ReloadButton = styled(Button)({
  height: '44px',
  width: '200px',
  backgroundColor: '#1090B1',
  border: 'none',
  borderRadius: '8px',
  color: 'inherit',
  letterSpacing: '1px',
  textTransform: 'capitalize',
  cursor: 'pointer',
  margin: '40px 0',
  '&:hover': {
    backgroundColor: '#1090B1',
  },
})

function AbortScreen(props: any) {
  const { VideoRefs, shakaInstances, socket } = props

  const isAbortModal = useSelector((state: RootState) => state.componentDisplay.isAbortModal)
  const isPortraitView = useSelector((state: RootState) => state.componentDisplay.isPortraitView)
  const isPortableDevice = useSelector(
    (state: RootState) => state.componentDisplay.isPortableDevice,
  )

  const dispatch = useDispatch()

  const destroyedPlayers = useRef<boolean[]>([])

  const displayMissingParamsLabel = (): string => {
    const missParamLabel = showLabel('lms_missing_parameter')
    if (missParamLabel.includes('_')) {
      return 'Currently, your link does not contain the appropriate parameters! Without that the application cannot be loaded.'
    } else {
      return missParamLabel
    }
  }

  useEffect(() => {
    if (isAbortModal.on) {
      if (VideoRefs?.current?.length) {
        VideoRefs.current.forEach((video: HTMLVideoElement) => video.pause())
        shakaInstances?.current?.forEach((player: any, i: number) => {
          if (!destroyedPlayers.current[i] && player !== null) {
            destroyedPlayers.current[i] = true
            player
              .unload()
              .then(() => player.destroy())
              .then(() => {
                shakaInstances.current[i] = null
              })
              .catch(console.error)
          }
        })
      }
      if ([WSreadyState.CONNECTING, WSreadyState.OPEN].includes(socket?.current?.readyState)) {
        socket.current.close()
      }
    }
  }, [isAbortModal.on])

  return (
    <AbortModal
      fullScreen
      disableEscapeKeyDown
      open={isAbortModal.on}
      onClose={() => dispatch(toggleAudioSubtSidebar(false))}
    >
      <AbortModalTitle>
        {isAbortModal.type === 'concurrent' && (
          <Typography variant="button" sx={{ fontSize: isPortableDevice ? '14px' : '16px' }}>
            {showLabel('lms_concurrent_streams')}
          </Typography>
        )}
      </AbortModalTitle>

      <AbortModalBody sx={{ flexDirection: !isPortraitView ? 'row' : 'column' }}>
        {isAbortModal.type === 'concurrent' && (
          <Typography variant={isPortableDevice ? 'h6' : 'h5'}>
            {showLabel('lms_max_concurrent_stream')}
          </Typography>
        )}

        {isAbortModal.type === 'playabort' && (
          <Typography variant={isPortableDevice ? 'h6' : 'h5'}>
            {showLabel('lms_shaka_load_error')}
          </Typography>
        )}

        {isAbortModal.type === 'wrongparams' && (
          <Typography variant={isPortableDevice ? 'h6' : 'h5'}>
            {displayMissingParamsLabel()}
          </Typography>
        )}

        {isAbortModal.type === 'concurrent' && (
          <ReloadButton
            variant="contained"
            onPointerDown={() => window.location.reload()}
            sx={{
              fontSize: isPortableDevice ? '14px' : '16px',
            }}
          >
            {showLabel('lms_reload_the_app')}
          </ReloadButton>
        )}
      </AbortModalBody>
    </AbortModal>
  )
}

export default AbortScreen
