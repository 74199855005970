import { styled } from '@mui/system'
import { Skeleton } from '@mui/material'

const LoaderFrame = styled('div')({
  width: '100%',
  height: '100vh',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  alignItems: 'center',
  padding: 0,
  margin: 0,
})
const Header = styled('div')({
  width: '100%',
  height: '5%',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
})
const Carousel = styled('div')({
  width: '100%',
  height: '35%',
})
const HeadLeftSkel = styled(Skeleton)({
  width: '30%',
  height: '100%',
})
const HeadRightSkel = styled(Skeleton)({
  width: '30%',
  height: '100%',
})
const VidSkelFrame = styled('div')({
  width: '100%',
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  alignItems: 'center',
})
const VideoSkel = styled(Skeleton)({
  width: '95%',
  height: '90%',
  margin: 0,
  padding: 0,
})
const VidTextSkel = styled(Skeleton)({
  width: '90%',
  height: '10%',
})
const InterPanel = styled('div')({
  width: '100%',
  height: '60%',
})
const PanelBar = styled('div')({
  width: '100%',
  height: '10%',
  marginTop: '20px',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-around',
  alignItems: 'center',
})
const PanelBarIconSkel = styled(Skeleton)({
  width: '40px',
  height: '100%',
})
const PanelTextBox = styled('div')({
  width: '100%',
  height: '90%',
  paddingTop: '10px',
})
const PanelTextTitle = styled(Skeleton)({
  width: '60%',
  height: '30px',
  margin: '15px 0 5px 20px',
  marginTop: '15px',
  marginLeft: '20px',
})
const PanelTextSkel = styled(Skeleton)({
  width: '80%',
  height: '20px',
  marginTop: '10px',
  marginLeft: '20px',
})

function SkeletonLoader() {
  return (
    <LoaderFrame>
      <Header>
        <HeadLeftSkel variant="text" animation="wave" />
        <HeadRightSkel variant="text" animation="wave" />
      </Header>

      <Carousel>
        <VidSkelFrame>
          <VideoSkel variant="rectangular" animation="pulse" />
          <VidTextSkel variant="text" animation="pulse" />
        </VidSkelFrame>
      </Carousel>

      <InterPanel>
        <PanelBar>
          <PanelBarIconSkel variant="circular" animation="wave" />
          <PanelBarIconSkel variant="circular" animation="wave" />
          <PanelBarIconSkel variant="circular" animation="wave" />
          <PanelBarIconSkel variant="circular" animation="wave" />
        </PanelBar>
        <PanelTextBox>
          <PanelTextTitle variant="text" animation="wave" />
          <PanelTextSkel variant="text" animation="wave" />
          <PanelTextSkel variant="text" animation="wave" />
          <PanelTextSkel variant="text" animation="wave" />
          <PanelTextSkel variant="text" animation="wave" />
          <PanelTextSkel variant="text" animation="wave" />
        </PanelTextBox>
      </InterPanel>
    </LoaderFrame>
  )
}

export default SkeletonLoader
