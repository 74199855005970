import { useSelector } from 'react-redux'
import { Typography, Grid } from '@mui/material'
import { styled } from '@mui/system'
import parse from 'html-react-parser'
import sanitizeHtml from 'sanitize-html'
import type { RootState } from 'store/index'

const AboutGrid = styled(Grid)({
  width: '100%',
  marginTop: '20px',
  flexDirection: 'column',
})
const ContentTitle = styled(Typography)({
  color: '#808080',
  letterSpacing: '0.5px',
})
const ContentDescription = styled(Typography)({
  fontSize: '18px',
})

function About() {
  const appLanguage = useSelector((state: RootState) => state.appLanguage.selected)
  const localizationDict = useSelector((state: RootState) => state.appLanguage.localizationDict)
  const locs = localizationDict[appLanguage]

  return (
    <AboutGrid container spacing={2}>
      <Grid item>
        <ContentTitle variant="subtitle1">{locs?.title}</ContentTitle>
      </Grid>

      <Grid item>
        <ContentDescription variant="body1">
          {parse(sanitizeHtml(locs?.description || ''))}
        </ContentDescription>
      </Grid>
    </AboutGrid>
  )
}

export default About
