import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import { ILearningObjectState } from 'store/storeTypes'

const initialState: ILearningObjectState = {
  item: null,
  mediaTracks: [],
  chapters: [],
  type: '',
}

export const learningObjectSlice = createSlice({
  name: 'learningObject',
  initialState,
  reducers: {
    storeLearnObj: (state: ILearningObjectState, action: PayloadAction<Array<any>>) => {
      state.item = action.payload
    },
    storeMediaTracks: (state: ILearningObjectState, action: PayloadAction<Array<any>>) => {
      state.mediaTracks = action.payload
    },
    storeChapters: (state: ILearningObjectState, action: PayloadAction<any[]>) => {
      state.chapters = action.payload
    },
    storeContentType: (state: ILearningObjectState, action: PayloadAction<string>) => {
      state.type = action.payload
    },
  },
})

export const { storeLearnObj, storeMediaTracks, storeChapters, storeContentType } =
  learningObjectSlice.actions
export default learningObjectSlice.reducer
