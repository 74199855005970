import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import Cookies from 'js-cookie'

export const LOapi = createApi({
  reducerPath: 'LOapi',
  baseQuery: fetchBaseQuery({
    baseUrl: `${window.__RUNTIME_CONFIG__.BASE_URL}/api/v1.2/`,
    prepareHeaders: (headers) => {
      // since this is a service there is a chance it is created before we save the token from the url, hence we check both
      const token = new URLSearchParams(window.location?.search).get('token') ?? Cookies.get('token') ?? ''
      headers.set('authorization', `Bearer ${token}`)
      return headers
    },
  }),
  endpoints: (builder) => ({
    getLearnObjById: builder.query<any, string>({
      query: (contentId) => `/App/Content/${contentId}`,
    }),
  }),
})

export const { useGetLearnObjByIdQuery } = LOapi
