import { useState, useEffect, useRef, RefObject, FC, SetStateAction, Dispatch } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { AxiosError, AxiosResponse } from 'axios'
import { styled } from '@mui/system'
import { Box, Button, Drawer, Fade, List, ListItem, TextField, Typography } from '@mui/material'
import { Delete } from '@mui/icons-material'
import { newBookmark, editBookmark, writeBookmark, storeBookmarks } from 'store/reducers/notes'
import Bookmarks, { TNote } from 'services/api/bookmarks'
import AlertMsg from 'services/alertMsg'
import { showLabel } from 'services/language'
import { formatStringToReadableTime, secToHHMMSS } from 'utils/dateTimeConfig'
import DotDotDotSrc from 'assets/icons/dotDotDot.svg'
import PencilSrc from 'assets/icons/pencil.svg'
import Close2 from 'assets/icons/close_2.svg'
import XSrc from 'assets/icons/x.svg'
import type { RootState } from 'store/index'

const NotesSection = styled('section')({
  width: '100%',
})
const AddNoteButton = styled(Button)({
  height: '100%',
  width: '250px',
  border: '2px solid #0497BB',
  backgroundColor: 'transparent',
  borderRadius: '10px',
  padding: '10px',
  color: '#0497BB',
  fontSize: '16px',
  letterSpacing: '1.5px',
  textAlign: 'center',
  textTransform: 'initial',
  cursor: 'pointer',
  '&:hover': {
    border: '2px solid #0497BB',
  },
})
const NoteBottom = styled('div')({
  width: '100%',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  alignItems: 'flex-end',
  padding: '0 15px',
})
const ClearButton = styled(Button)({
  height: '44px',
  width: '200px',
  backgroundColor: 'transparent',
  border: 'none',
  borderRadius: '8px',
  color: 'inherit',
  fontSize: '16px',
  letterSpacing: '1px',
  textTransform: 'capitalize',
  cursor: 'pointer',
  marginBottom: '50px',
})
const SaveButton = styled(Button)({
  height: '44px',
  width: '200px',
  backgroundColor: '#1090B1',
  border: 'none',
  borderRadius: '8px',
  color: 'inherit',
  fontSize: '16px',
  letterSpacing: '1px',
  textTransform: 'capitalize',
  cursor: 'pointer',
  marginBottom: '50px',
  '&:hover': {
    backgroundColor: '#1090B1',
  },
})
const ListBox = styled(Box)({})
const ListElem = styled(ListItem)({
  width: '100%',
  marginBottom: '15px',
  padding: '10px 0',
})
const ListItemTitle = styled('div')({
  width: '100%',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  fontWeight: 500,
  fontSize: '18px',
})
const TitleBox = styled(Box)({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'flex-start',
  alignItems: 'center',
  cursor: 'pointer',
})
const NoteTitle = styled('h4')({
  margin: '4px 10px',
  padding: 0,
  fontSize: '16px',
})
const NoteAt = styled('span')({
  cursor: 'pointer',
  backgroundColor: '#212223',
  color: '#6C6C6C',
  textAlign: 'center',
  fontSize: '16px',
  fontWeight: 700,
  lineHeight: '22px',
  letterSpacing: '0.46px',
  minWidth: '89px',
  padding: '10px 10px',
  marginRight: '21px',
  borderRadius: '6px',
})
const ListItemNote = styled('div')({
  fontSize: '16px',
  width: '100%',
})
const EditButtons = styled('div')({
  height: '30px',
  color: '#ffffff',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'flex-end',
  alignItems: 'center',
})
const EditNoteButton = styled(Button)({
  height: '100%',
  border: 'none',
  color: 'inherit',
  fontSize: '16px',
  textTransform: 'initial',
})
const PencilIcon = styled('img')({
  width: '26px',
  height: '26px',
})
const RemoveNoteButton = styled(Button)({
  height: '100%',
  border: 'none',
  color: 'inherit',
  fontSize: '16px',
  textTransform: 'initial',
})
const TrashIcon = styled(Delete)({
  width: '20px',
  height: '20px',
  marginLeft: '2px',
})
const CancelNoteButton = styled(Button)({
  height: '100%',
  border: 'none',
  color: 'inherit',
  fontSize: '16px',
  textTransform: 'initial',
})
const XIcon = styled('img')({
  width: '16px',
  height: '16px',
  marginLeft: '5px',
})
const AddNoteDrawer = styled(Drawer)({
  '& .MuiDrawer-paperAnchorRight': {
    backdropFilter: 'blur(15px)',
    backgroundColor: 'rgba(40, 40, 40, 0.6)',
    borderRadius: '50px 0 0 50px',
  },
})
const AddNoteCont = styled('div')({
  width: '40vw',
  minWidth: '420px',
  height: '100vh',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: '0 20px',
})
const NoteTop = styled('div')({
  width: '100%',
})
const CloseButton = styled(Button)({
  textTransform: 'initial',
  color: 'inherit',
  marginLeft: 0,
  paddingLeft: 0,
})
const CloseIcon = styled('img')({
  width: '25px',
  height: '25px',
  margin: 0,
  padding: 0,
  cursor: 'pointer',
})
const CloseText = styled(Typography)({
  fontSize: '16px',
  cursor: 'pointer',
})
const AddNoteHeader = styled(Box)({
  width: '100%',
  height: '140px',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-evenly',
  alignItems: 'flex-start',
  marginTop: '10px',
  padding: '0 14px',
})
const SidebarTitle = styled(Typography)({
  marginLeft: '3px',
})
const DotDotDotIcon = styled('img')({
  width: '22px',
  height: '30px',
  cursor: 'pointer',
})
const AddNoteSection = styled('section')({
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  padding: '0 15px',
})
const NoteField = styled(TextField)({
  backgroundColor: 'rgba(255, 255, 255, 0.1)',
  borderRadius: '8px',
  '& .Mui-focused .MuiOutlinedInput-notchedOutline': {
    border: 'none',
  },
  '&:hover .MuiOutlinedInput-notchedOutline': {
    border: 'none',
  },
})
const LetterCounter = styled('span')({
  width: '100%',
  fontSize: '12px',
  color: '#808080',
  textAlign: 'right',
})

function Notes(props: any) {
  const { seekTo, currentlyAtRef } = props

  const [dotsOpenAt, setDotsOpenAt] = useState<number | null>(null)
  const [isNoteDrawerOpen, setIsNoteDrawerOpen] = useState<boolean>(false)
  const [isNewNote, setIsNewNote] = useState<boolean>(false)

  const learnObjItem = useSelector((state: RootState) => state.learningObject.item)
  const contentType = useSelector((state: RootState) => state.learningObject.type)
  const headVideoId = useSelector((state: RootState) => state.componentDisplay.headVideoId)
  const isPlaying = useSelector((state: RootState) => state.playback.isPlaying)
  const screenWidth = useSelector((state: RootState) => state.componentDisplay.screenWidth)
  const bookmarks = useSelector((state: RootState) => state.notes.bookmarks)

  const dispatch = useDispatch()

  const noteButtonRef = useRef<HTMLButtonElement>(null)

  const displayEditButtons = (index: number) => (
    <EditButtons>
      <EditNoteButton variant="text" onPointerDown={() => openNote(index)}>
        {showLabel('lms_edit')}
        <PencilIcon src={PencilSrc} />
      </EditNoteButton>

      <RemoveNoteButton variant="text" onPointerDown={() => removeNote(index)}>
        {showLabel('lms_remove')}
        <TrashIcon />
      </RemoveNoteButton>

      <CancelNoteButton variant="text" onPointerDown={() => setDotsOpenAt(null)}>
        {showLabel('lms_cancel')}
        <XIcon src={XSrc} />
      </CancelNoteButton>
    </EditButtons>
  )

  const calcNoteAt = (noteAt: number) => {
    const time = secToHHMMSS(noteAt)
    return time
  }

  const addNewNote = (index: number) => {
    setIsNewNote(true)
    dispatch(newBookmark(index))
    setTimeout(() => {
      setIsNoteDrawerOpen(true)
    }, 200)
  }

  const openNote = (index: number) => {
    setIsNewNote(false)
    dispatch(editBookmark(index))
    setTimeout(() => {
      setIsNoteDrawerOpen(true)
    }, 200)
  }

  const closeNote = () => {
    setIsNoteDrawerOpen(false)
    dispatch(writeBookmark(null))
    if (noteButtonRef.current) {
      setTimeout(() => noteButtonRef?.current?.blur(), 100)
    }
  }

  const removeNote = (index: number) => {
    Bookmarks.deleteNote(bookmarks[index].id)
      //.then(() => Discussion.getOneComment(parentId || commentId))
      .then(() => {
        dispatch(storeBookmarks(bookmarks.filter((bookmark: TNote, i: number) => i !== index)))
        AlertMsg.show({
          type: 'success',
          message: 'Your note is deleted!',
        })
      })
      .catch((error: AxiosError) => {
        AlertMsg.show({
          type: 'error',
          message: error,
          context: "Can't delete your note → ",
        })
      })
      .finally(() => setDotsOpenAt(null))
  }

  useEffect(() => {
    if (learnObjItem) {
      Bookmarks.listNotes(
        ['Remix', 'RemixV2'].includes(contentType) ? learnObjItem.id : headVideoId,
      )
        .then((response: AxiosResponse) => response.data && dispatch(storeBookmarks(response.data)))
        .catch((error: AxiosError) => {
          AlertMsg.show({
            type: 'error',
            message: error,
            context: "Can't load the related discussions → ",
          })
        })
    }
  }, [headVideoId])

  return (
    <NotesSection>
      <AddNoteButton
        ref={noteButtonRef}
        variant="outlined"
        onPointerDown={() => addNewNote(bookmarks.length)}
      >
        {showLabel('lms_leave_note')}
      </AddNoteButton>

      <List sx={{ width: '100%' }}>
        {bookmarks.map((note: any, index: number) => (
          <ListElem key={`${note.title}_${index}`}>
            <ListBox sx={{ width: screenWidth > 1000 ? '64.5%' : '100%' }}>
              <ListItemTitle>
                <TitleBox>
                  <NoteAt onPointerDown={() => seekTo(note.position, isPlaying)}>
                    {formatStringToReadableTime(calcNoteAt(note.position))}
                  </NoteAt>

                  <NoteTitle>
                    <span>{note.title}</span>
                  </NoteTitle>
                </TitleBox>
                {dotsOpenAt === index ? (
                  <Fade in={dotsOpenAt === index} timeout={500}>
                    {displayEditButtons(index)}
                  </Fade>
                ) : (
                  <DotDotDotIcon src={DotDotDotSrc} onPointerDown={() => setDotsOpenAt(index)} />
                )}
              </ListItemTitle>
              <ListItemNote>{note.description}</ListItemNote>
            </ListBox>
          </ListElem>
        ))}
      </List>

      <AddNoteDrawer anchor="right" open={isNoteDrawerOpen} onClose={() => closeNote()}>
        <AddNoteCont>
          <NoteForm
            isNewNote={isNewNote}
            closeNote={closeNote}
            setDotsOpenAt={setDotsOpenAt}
            currentlyAtRef={currentlyAtRef}
            contentType={contentType}
            headVideoId={headVideoId}
          />
        </AddNoteCont>
      </AddNoteDrawer>
    </NotesSection>
  )
}

interface NoteFormPropTypes {
  isNewNote: boolean
  closeNote(): void
  setDotsOpenAt: Dispatch<SetStateAction<number | null>>
  currentlyAtRef: RefObject<number>
  contentType: string
  headVideoId: string
}

const NoteForm: FC<NoteFormPropTypes> = (props: NoteFormPropTypes) => {
  const { isNewNote, closeNote, setDotsOpenAt, currentlyAtRef, contentType, headVideoId } = props

  const [note, setNote] = useState({
    id: '',
    index: null,
    title: '',
    description: '',
    position: 0,
    contentId: '',
    providerId: '',
  })

  const learnObjItem = useSelector((state: RootState) => state.learningObject.item)

  const bookmarks = useSelector((state: RootState) => state.notes.bookmarks)
  const bookmarkBeingEdited = useSelector((state: RootState) => state.notes.bookmarkBeingEdited)

  const dispatch = useDispatch()

  const calcLettersLeft = (): string => {
    const lettersLeftNumb = 420 - (note?.description?.length || 0)
    const lettersLeftText =
      lettersLeftNumb > 1
        ? showLabel('lms_characters_remaining')
        : showLabel('lms_character_remaining')
    return `${lettersLeftNumb} ${lettersLeftText}`
  }

  const clearNote = () => {
    setNote((note) => ({ ...note, title: '', description: '' }))
  }

  const saveNote = () => {
    if (note.id === '') {
      Bookmarks.addNote({
        title: note.title,
        description: note.description,
        position: currentlyAtRef.current as number,
        contentId: ['Remix', 'RemixV2'].includes(contentType) ? learnObjItem!.id : headVideoId,
        providerId: note.providerId,
      })
        .then((response: AxiosResponse) => {
          dispatch(
            storeBookmarks(
              [...bookmarks, response.data].sort(
                (bkmrk1: any, bkmrk2: any) => bkmrk1.position - bkmrk2.position,
              ),
            ),
          )
          AlertMsg.show({
            type: 'success',
            message: 'Your note is submitted!',
          })
        })
        .catch((error: AxiosError) => {
          AlertMsg.show({
            type: 'error',
            message: error,
            context: "Can't save your note → ",
          })
        })
        .finally(() => {
          closeNote()
          setDotsOpenAt(null)
        })
    } else {
      Bookmarks.updateNote(note.id, note)
        .then((response: AxiosResponse) => {
          dispatch(
            storeBookmarks(
              bookmarks
                .map((bookmark: TNote, i: number) => {
                  if (i === note.index) return response.data
                  return bookmark
                })
                .sort((bkmrk1: any, bkmrk2: any) => bkmrk1.position - bkmrk2.position),
            ),
          )
          AlertMsg.show({
            type: 'success',
            message: 'Your note is updated!',
          })
        })
        .catch((error: AxiosError) => {
          AlertMsg.show({
            type: 'error',
            message: error,
            context: "Can't update your note → ",
          })
        })
        .finally(() => {
          closeNote()
          setDotsOpenAt(null)
        })
    }
  }

  useEffect(() => {
    if (!isNewNote) {
      setNote(() => ({ ...bookmarkBeingEdited, index: bookmarkBeingEdited.noteIndex }))
    }
  }, [isNewNote])

  return (
    <>
      <NoteTop>
        <AddNoteHeader>
          <CloseButton variant="text">
            <CloseIcon
              src={Close2}
              alt="Close icon of the Note edit modal"
              onPointerDown={() => closeNote()}
            />
            <CloseText variant="caption" onPointerDown={() => closeNote()}>
              {showLabel('lms_close')}
            </CloseText>
          </CloseButton>

          <SidebarTitle variant="h4">
            {isNewNote ? showLabel('lms_add_note') : showLabel('lms_edit_note')}
          </SidebarTitle>
        </AddNoteHeader>

        <AddNoteSection>
          <NoteField
            placeholder={showLabel('lms_enter_title')}
            value={note?.title}
            variant="outlined"
            type="text"
            margin="normal"
            fullWidth
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              setNote((note) => ({ ...note, title: (event.target as HTMLInputElement).value }))
            }}
          />

          <NoteField
            placeholder={showLabel('lms_note_tooltip')}
            value={note?.description}
            variant="outlined"
            type="text"
            margin="normal"
            multiline
            rows={10}
            inputProps={{ maxLength: 420 }}
            fullWidth
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              setNote((note) => ({
                ...note,
                description: (event.target as HTMLInputElement).value,
              }))
            }}
          />
          <LetterCounter>{calcLettersLeft()}</LetterCounter>
        </AddNoteSection>
      </NoteTop>

      <NoteBottom>
        <ClearButton variant="text" onPointerDown={clearNote}>
          {showLabel('lms_clear')}
        </ClearButton>

        <SaveButton variant="contained" onPointerDown={saveNote}>
          {showLabel('lms_save')}
        </SaveButton>
      </NoteBottom>
    </>
  )
}

export default Notes
