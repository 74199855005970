import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import { IDeviceInfo } from 'store/storeTypes'

const initialState: IDeviceInfo = {
  browser: {},
  cpu: {},
  device: {},
  engine: {},
  os: {},
  ua: {},
}

export const deviceInfoSlice = createSlice({
  name: 'deviceInfo',
  initialState,
  reducers: {
    storeDeviceInfo: (state: IDeviceInfo, action: PayloadAction<IDeviceInfo>) => {
      state.browser = action.payload.browser
      state.cpu = action.payload.cpu
      state.device = action.payload.device
      state.engine = action.payload.engine
      state.os = action.payload.os
      state.ua = action.payload.ua
    },
  },
})

export const { storeDeviceInfo } = deviceInfoSlice.actions
export default deviceInfoSlice.reducer
